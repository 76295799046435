<template>
  <div>
    <h1 class="i6-title-main" v-if="item.title" v-html="item.title"></h1>
    <div class="a-tv__container">
      <div class="d-flex flex-wrap relative h-100 a-tv__inner" :class="{ 'is-active' : activeTv !== null }">
        <div class="a-tv__wrapper" :class="{ 'is-active': activeTv === slItem }" v-for="(slItem, index) in item.slideItems" :key="'tv-icon-' + index">
          <!-- for click finctionality add @click.stop="handleInnerSteps(slItem) on line above -->
          <img class="img-fluid a-tv__wrapper__tv-image" :src="$store.state.slides.baseUrl + slItem.image" alt=""/>
          <div class="a-tv__icon d-flex align-items-center flex-column">
            <span class="text-center" v-html="slItem.title"></span>
            <img :src="$store.state.slides.baseUrl + slItem.icon" alt=""/>
          </div>
        </div>
      </div>
      <button v-if="activeTv && !video" class="centerPlayButton tvCenter" @click.stop.prevent="openVideo(activeTv.url)">
        <img :src="require('@/assets/images/play.svg')" alt="">
      </button>
    </div>
    <div v-if="video" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="video = false" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="video"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      activeTv: null,
      video: false
    }
  },
  methods: {
    handleInnerSteps (data) {
      this.activeTv = data
      this.returnInfoBox(this.activeTv.info_page || null)
    },
    openVideo (url) {
      // console.log(url)
      this.video = (url === undefined) ? '://0' : this.$store.state.slides.baseUrl + url
      // this.video = (url === undefined) ? '://0' : require('@/assets/videos/' + url)
    },
    returnInfoBox (data) {
      this.$emit('handleInfoBox', data)
    }
  },
  created () {
    this.activeTv = null
  },
  mounted () {
    this.activeTv = null
  }
}
</script>
