<template>
  <div class="anschluss-child-slide">
    <div
      class="a-slide-up-cont"
      :class="{
        'main': item.contentPosition === 'center',
        'halfTopSpace': item.contentPosition !== 'center'
      }"
    >
      <h1 v-if="item.title" v-html="item.title"></h1>
    </div>
    <div class="a-slide-down-cont" v-if="item.slides">
      <ul class="tab-headers" v-if="activeTab">
        <li v-for="(slide, i) in item.slides" :key="'sl-key-' + i" :class="{'active': activeTab.id === slide.id}" @click.stop.prevent="handleTabs(slide)">
          <span v-text="slide.name"></span>
        </li>
      </ul>
      <div class="tab-content" v-if="activeTab" :class="{ 'active': activeTab }">

        <div class="inner">
          <div class="inner-bg">
            <div class="pulse-icon" :class="['icon-'+ activeTab.id]">
              <span></span>
            </div>
            <img :src="require('@/assets/images/intro11/bild_strasse.png')" alt="">
          </div>
          <div class="inner-content">
            <div class="inner-content-title">
              <h2 v-html="activeTab.title"></h2>
            </div>
            <div class="inner-content-description">
              <div v-html="activeTab.description"></div>
            </div>
            <div class="inner-content-actions">
              <ul class="inner-content-actions-list">
                <li class="download">
                  <h4 v-html="activeTab.download.title"></h4>
                  <div class="inner-content-actions-list-box"
                  :style="{ 'background-image': 'url('+ require('@/assets/images/anschluss_download' + (activeTab.primary == 0 ? '_orange' : '') + '.png') + ')'}">
                    <span v-text="activeTab.download.uptext"></span>
                    <span class="nr" v-text="activeTab.download.nr.toLocaleString().replace(',', '.')"></span>
                    <span v-text="activeTab.download.downtext"></span>
                  </div>
                </li>
                <li class="upload">
                  <h4 v-html="activeTab.upload.title"></h4>
                  <div class="inner-content-actions-list-box"
                  :style="{ 'background-image': 'url('+ require('@/assets/images/anschluss_upload' + (activeTab.primary == 0 ? '_orange' : '') + '.png') + ')'}">
                    <span v-text="activeTab.upload.uptext"></span>
                    <span class="nr" v-text="activeTab.upload.nr"></span>
                    <span v-text="activeTab.upload.downtext"></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="a-slide-down-cont simple" v-else>
      <div class="simple-image">
        <div class="simple-image-holder">
          <span @click.stop.prevent="handleTip"></span>
          <img v-if="item.sponsImg && item.sponsImg.length > 0" :src="$store.state.slides.baseUrl + item.sponsImg[0].img" alt="">
        </div>
      </div>
      <button class="btn primary-color" v-if="item.nextButton">
        <span class="btn-text" v-text="item.nextButtonText"></span>
        <arrow-down :color="'#E20074'"></arrow-down>
      </button>
    </div>
  </div>
</template>

<script>
import arrowDown from '@/components/icons/arrow-down'
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  components: {
    // plus
    // cycleArrow
    arrowDown
  },
  data () {
    return {
      activeTab: null
    }
  },
  methods: {
    handleTabs (tab) {
      this.activeTab = null
      this.$nextTick(() => {
        this.activeTab = tab
      })
      // this.activeTab = null
      // setTimeout(() => {
      // }, 500)
    },
    handleTip () {
      this.$emit('triggerTipp')
    }
  },
  mounted () {
    this.item?.slides?.length > 0 && this.handleTabs(this.item.slides[0])
  }
}
</script>
