<template>
  <div v-if="item" class="phone-template-wrapper"
    :class="{
      'wrapper-bg-shadow': activeView !== 'slider',
      'isPrint': print.show,
      }">
    <Category
    v-if="activeView === 'category'"
    :title="categoryTitle"
    :categoryItems="categoryItems.length > 4 ? categoryItems.slice(0, 4) : categoryItems"
    @setPhone="handleSetPhone" />

    <SmartphoneSlider
    v-if="activeView === 'slider' && activePhone"
    :phone="activePhone"
    :activeCategory="activeCategory" />

    <SmartphoneComparison
      v-if="activeView === 'comparison'" />

    <!-- <SmartphoneComparisonMulti
      v-if="false && activeView === 'comparison-multi'" /> -->

    <SmartphoneFind v-if="activeView === 'comparison-multi'" />

    <Print v-if="print.show" />
  </div>
</template>

<script>

// import dynamicModule from '@/store/utils/dynamicModule'
import { mapState } from 'vuex'
// import smartphoneComparisonStore from '@/store/modules/smartphoneComparison'
import Category from '@/components/smartphone-comparison/category'
import SmartphoneSlider from '@/components/smartphone-comparison/smartphone-slider.vue'
import SmartphoneComparison from '@/components/smartphone-comparison/smartphone-comparison'
// import SmartphoneComparisonMulti from '@/components/smartphone-comparison/smartphone-comparison-multi'
import Print from '@/components/smartphone-comparison/print'
import SmartphoneFind from '@/components/smartphone-comparison/smartphone-find'

export default {
  // mixins: [
  //   dynamicModule('smartphoneComparison', smartphoneComparisonStore)
  // ],
  components: {
    Category,
    SmartphoneSlider,
    SmartphoneComparison,
    // SmartphoneComparisonMulti,
    Print,
    SmartphoneFind
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    handleSetPhone (data) {
      this.$store.commit('smartphoneComparison/setActivePhone', { ...data.phone, activeColor: data.activeColor })
      this.$store.commit('smartphoneComparison/setActiveView', 'slider')
    }
  },
  computed: {
    ...mapState('smartphoneComparison', ['activeView', 'activePhone', 'activeCategory', 'print']),
    categoryItems () {
      return this.item?.phones_list.filter(phone => phone.category && phone.category?.includes(this.activeCategory.toString()))
    },
    categoryTitle () {
      return this.activeCategory === 1 ? 'Top Smartphones' : this.activeCategory === 2 ? 'Mittelklasse Smartphones' : 'Preisgünstige Smartphones'
    }
  },
  created () {
    this.$store.commit('smartphoneComparison/setPhones', this.item.phones_list)
  }
}
</script>
