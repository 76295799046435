<template>
  <div class="vorteile-sidebar">
    <div class="vorteile-sidebar-header" @click="changeItem(index, true)">
      <arrow-down :color="'#262626'" :direction="'up'"></arrow-down>
      <span class="vorteile-sidebar-header-title">Übersicht</span>
    </div>
    <ul class="vorteile-sidebar-list">
      <li v-for="(inner, i) in item.sponsImg" :key="'test-key-' + i" @click="changeItem(parseInt(i + 1))" :class="{ 'active': active.menu_title == inner.title }">
        <span class="list-icon">
          <img class="sponsBy" :src="$store.state.slides.baseUrl + inner.icon_img" alt="">
        </span>
        <span class="list-title" v-text="inner.title"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import arrowDown from '@/components/icons/arrow-down.vue'
export default {
  components: { arrowDown },
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    },
    index: {
      required: true,
      default: null
    },
    active: {
      type: Object,
      required: true,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    changeItem (i, p = false) {
      this.$emit('changeSlide', !p ? (parseInt(this.index) + i) : parseInt(this.index))
    }
  }
}
</script>
