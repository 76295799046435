<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>
    <div class="top-menu" :class="{'light' : current && ((!current.menuColor || !current.menuColor.length) || (current.menuColor.length && current.menuColor[0] != 'indigo'))}" v-if="currentSlide > 0">
      <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
            <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
               :class="{ 'active' : slides[currentSlide] && slides[currentSlide].menuTitle == item.menuTitle }">{{ item.menuTitle }}</a>
            <div class="top-menu-sub-list"
                 v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
              <div class="d-flex justify-content-end">
                <span class="menu-close-icon" @click="closeMenu()">
                  <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                </span>
              </div>
              <ul>
                <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                  <a @click="handleSubMenu(ditem.realIndex)" v-text="ditem.menu_title"
                     :class="{'active': slides[currentSlide] && parseInt(slides[currentSlide].id) === parseInt(ditem.id)}"></a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="app-inner" v-if="!slideWithPassword">
      <div class="slides-wrapper slides-wrapper-intro6 clip" @click="changeSlides" :class="{ 'onFront' : showExplainer }">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index,
            'previewSlide': index < currentSlide,
            'slideTypeOne': item.type === 1,
            'slideTypeTwo': item.type === 2,
            'downBackground': item.type === 1 && item.switch,
            'a-slide-type-12': item.type === 12,
            'a-slide-type-13': item.type === 13
          }" :style="{ backgroundImage : 'url(' + baseUrl + item.img + ')' }">
            <magenta-tv v-on:changeSlides="changeSlides" v-if="item.type === 1 && currentSlide == index"
                        :item="item"></magenta-tv>
            <inhalt-view v-on:removeWheelEvent="removeWheelEvent" v-on:handleVideoBox="handleVideoBox" v-on:handleInfoBox="handleInfoBox"
                         v-on:changeSlides="changeSlides" v-if="item.type === 3 && currentSlide == index"
                         :item="item"></inhalt-view>
            <magenta-simple-slider v-on:handleInfoBox="handleInfoBox" v-on:handleVideoBox="handleVideoBox" v-on:changeSlides="changeSlides"
                                   v-if="item.type === 10 && currentSlide == index"
                                   :item="item"></magenta-simple-slider>
            <magenta-sport-slider v-on:handleInfoBox="handleInfoBox" v-on:handleVideoBox="handleVideoBox" v-on:changeSlides="changeSlides"
                                  v-if="item.type === 4 && currentSlide == index" :item="item"></magenta-sport-slider>
            <magenta-marathon-slider v-on:changeSlides="changeSlides" v-if="item.type === 5 && currentSlide == index"
                                     :item="item"></magenta-marathon-slider>
            <tv-explanation v-on:handleInfoBox="handleInfoBox" v-on:changeSlides="changeSlides"
                            v-if="currentSlide == index && item.type === 7" :item="item"></tv-explanation>

            <div v-if="item.type === 7" class="rounded-sticker"
                 :class="{ 'is-type-7' : item.type === 7}">
              <p>{{item.rounded_sticker}}</p>
            </div>

            <div v-if="item.type === 6 && currentSlide == index">
              <h1 class="i6-title-main" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
              <!-- <h1 class="i6-title font-weight-light mt-0" v-if="!!item.subtitle" v-html="item.subtitle"></h1> -->
              <div class="single-image-slider">
                <img :src="baseUrl + item.innerImage"/>
              </div>
            </div>

            <div v-if="item && item.type === 8 && currentSlide == index" :key="'a-slide-content' + index">
              <div class="type-8__content">
                <h1 class="i6-title" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
                <h1 class="i6-title text-left font-weight-light mt-0" v-if="item.subtitle" v-html="item.subtitle"></h1>
              </div>
              <img v-if="item.topInnerImage" class="fadeDown-In type-8__top-image" :src="baseUrl + item.topInnerImage"
                   alt="">
              <img v-if="item.bottomInnerImage" class="fadeDown-In type-8__bottom-image"
                   :src="baseUrl + item.bottomInnerImage" alt="">
            </div>
            <div v-if="item && item.type === 9 && currentSlide == index" :key="'a-slide-content' + index">
              <div class="type-9__content">
                <h1 class="i6-title" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
                <h1 class="i6-title sub text-left font-weight-bold mt-0" v-if="item.subtitle"
                    v-html="item.subtitle"></h1>
              </div>
              <div v-if="item.rounded_sticker" class="rounded-sticker type-9__rounded-sticker">
                <p>{{item.rounded_sticker}}</p>
              </div>
            </div>
            <div v-if="item && item.type === 11 && item.url && currentSlide == index" class="h-100 w-100 centerPlay">
              <button class="centerPlayButton" @click.stop.prevent="openVideo(item.url)">
                <img :src="require('@/assets/images/play.svg')" alt="">
              </button>
            </div>
            <div v-if="item.type === 12 || item.type === 14"
              class="a-slide-up-cont"
              :class="{
                'main': item.contentPosition === 'center'
              }"
            >
              <h1 v-if="item.title" :class="{ 'text-primary': item.type === 14 }" v-html="item.title"></h1>
              <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
            </div>
            <div v-if="item.type === 12 || item.type === 14"
              class="singleImageContent a-slide-down-cont"
              :class="[{
              'halfSpace': item.contentPosition !== 'center' && item.subTitle,
              'hasSlides': item.hasSlides
              }]"
            >
              <ul class="listImage" v-if="item.type === 12 && item.sponsImg && item.sponsImg.length > 0">
                <template v-for="(itm, i) in item.sponsImg">
                  <li :key="'spimg' + i">
                    <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                    <span v-if="itm.title" v-text="itm.title"></span>
                  </li>
                </template>
              </ul>
              <SlideVideos
              v-if="item.type === 14 && item.slide_video_box.length"
              :videos="item.slide_video_box"
              :baseUrl="baseUrl"
              @toggleVideo="openSVideo"/>
            </div>
            <marketingSlide @changeSlides="changeSlides" v-if="item.type === 13 && currentSlide == index" :baseUrl="baseUrl" :item="item"></marketingSlide>
            <MainMarketingSlide @changeSlides="changeSlides" v-if="item.type === 20" :baseUrl="baseUrl" :item="item"></MainMarketingSlide>
          </div>
          <div v-if="item.type === 2"
               :class="{
              'animate' : (!showLoader && !animate && currentSlide == index),
              'splashType': item.type === 2,
              'splashActive': currentSlide == index,
              'prevSlideTitle': index < currentSlide
            }"
               :key="'a-slide-content' + index" class="h1"
          >
            <span v-if="item.title" v-html="item.title"></span>
            <!-- <span class="text-uppercase" v-if="item.subtitle" v-html="item.subtitle"></span> -->
          </div>
          <button v-if="item.explainer && currentSlide == index" @click.stop="showExplainer = true" :key="'a-slide-content-button-' + index" class="btn btn-light text-primary btn-lg btn-xl explainer-btn fade-in-bottom">mehr erfahren</button>
          <div v-if="currentSlide == index && item.explainer && showExplainer" :key="'a-slide-content-popup-' + index"  class="i6-explainer">
            <div class="custom-popup-backdrop"></div>
            <div class="custom-popup pl-md-4 pb-md-8 fade-in-bottom">
              <div class="position-relative z-i-5"> <button @click.stop="showExplainer = false" class="close btn btn-link btn-sm"><img :src="require('@/assets/images/cancel.svg')" alt=""></button></div>
              <div class="inner" :style="{ backgroundImage: 'url(' + baseUrl + item.explainer + ')' }">
            </div>
            </div>
          </div>
        </template>
        <div id="i6-splash"
             v-if="(current && current.type === 2) || (animate && current && current.type !== 2 && slides[currentSlide + 1] && slides[currentSlide + 1].type === 2)"
             :class="{
            'initialTypeOne': (animate && current.type !== 2 && slides[currentSlide + 1] && slides[currentSlide + 1].type === 2 )
            }">
          <div class="inner">
            <img :src="require('@/assets/images/intro6/type2bg.svg')" alt="">
          </div>
        </div>

        <a href="#" :class="{ 'visible' : current && current.hasContinueButton }" @click="changeSlides" class="continue-button">weiter</a>
        <!-- ./src because didn't work somehow with built files -->
      </div>
    </div>
    <div v-if="slideVideo && current && current.type !== 14" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="slideVideo = false" class="close btn btn-link btn-sm z-i-1"><img
          src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="slideVideo"></video>
      </div>
    </div>
    <div id="intro" class="intro6-video" v-if="showIntro && intro" :class="{ 'notVisible': currentSlide > 0 }">
      <div v-if="!isVideoPlaying" class="introTitle">
        <span v-if="intro.title" v-text="intro.title"></span>
      </div>
      <video ref="$video"
             :poster="baseUrl + intro.video_cover"
             id="intro-video"
             muted
             @loadedmetadata="$route.params.id !== undefined && handleIntroVideo"
             @ended="changeSlides()"
             autoplay
             @click="handleIntroVideo"
             playsinline
             :src="baseUrl + intro.video"></video>
      <button v-if="!isVideoPlaying" id="play-intro"><img :src="require('@/assets/images/play.svg')" alt=""></button>
      <button v-if="intro.video" @click="hideIntro()" class="btn text-white">Skip Intro <img src="@/assets/images/intro-arrows.svg" alt="">
      </button>
    </div>
    <SlideVideosPlayer
      v-if="slideVideo && current && current.type === 14"
      :slideVideo="slideVideo"
      :baseUrl="baseUrl"
      @toggleVideo="openSVideo" />
    <videoWrapper
      v-if="showVideoBox"
      :isOpened="panels.videoWrapper"
      :videos="videoBoxData"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import MagentaTv from '@/components/magentaTv'
import InhaltView from '@/components/inhaltView'
import MagentaSimpleSlider from '@/components/magentaSimpleSlider'
import TvExplanation from '@/components/tvExplanation'
import MagentaSportSlider from '@/components/magentaSportSlider'
import backToHomeButton from '@/components/backToHomeButton'
import MagentaMarathonSlider from '@/components/magentaMarathonSlider'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'
import marketingSlide from '@/components/intro6/marketingSlide'
import MainMarketingSlide from '@/components/marketingSlide'
import SlideVideos from '@/components/intro16-hybrid/SlideVideos'
import SlideVideosPlayer from '@/components/intro16-hybrid/SlideVideosPlayer'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  watch: {
    current (nv, val) {
      this.initEvents(nv && nv.innerEvents === undefined && nv.type !== 13)
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage || null)
            this.handleVideoBox(null)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            this.handleVideoBox(this.current?.video_box || null)
            break
        }
      }
    }
  },
  data () {
    return {
      slideWithPassword: null,
      showExplainer: false,
      currentSlide: 0,
      animate: true,
      showLoader: false,
      backToHomeButtonText: 'Title',
      current: null,
      showIntro: true,
      showHome: true,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: true,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      videoBoxData: null,
      showVideoBox: false,
      dropdownMenu: {
        index: null,
        list: [],
        show: false
      }
    }
  },
  components: {
    PasswordModal,
    TvExplanation,
    infoWrapper,
    sideMenu,
    bottomVideos,
    MagentaTv,
    InhaltView,
    backToHomeButton,
    MagentaSimpleSlider,
    MagentaSportSlider,
    MagentaMarathonSlider,
    videoWrapper,
    marketingSlide,
    MainMarketingSlide,
    SlideVideos,
    SlideVideosPlayer
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      // debugger
      this.showIntro = true
      this.currentSlide = 0
      // this.current = null
      if (!this.intro?.video) {
        this.skipIntroVideo()
      } else {
        let video = this.$refs.$video
        if (video) {
          let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
          if (playingState) {
            video.pause()
            this.isVideoPlaying = false
          } else {
            video.play()
            this.isVideoPlaying = true
          }
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      // debugger
      if (this.isAnimating || this.showExplainer) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      this.isAnimating = true
      this.showLoader = true
      this.animate = true

      setTimeout(() => {
        this.showLoader = false
        this.showIntro = false
        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
        this.current = this.currentSlide === 0 ? null : this.slides[this.currentSlide]

        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
          if (this.$route.params?.id) { this.addLoading(false) }
        }, 700)
      }, 700)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    openSVideo (video) {
      this.slideVideo = video || false
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleVideoBox (data) {
      this.videoBoxData = data || null
      this.showVideoBox = data?.length > 0
    },
    skipIntroVideo () {
      this.currentSlide = 1
      this.current = this.slides[this.currentSlide]
      this.animate = false
      this.isAnimating = false
      if (this.$route.params?.id) { this.addLoading(false) }
      // this.changeSlides()
      // setTimeout(() => {
      // }, 700)
    },
    handleMenu (navItem, index) {
      if (this.dropdownMenu.show) {
        this.dropdownMenu.show = false
        this.changeSlides(index)
      } else {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        this.dropdownMenu.index = index
        for (let key in this.slides) {
          // console.log(parseInt(this.slides[key].parentId) + '-' + parseInt(navItem.id), this.slides[key]);
          if (this.slides[key] && parseInt(this.slides[key].parentId) === parseInt(navItem.id) && parseInt(this.slides[key].parentId) !== parseInt(this.slides[key].id)) {
            let item = this.slides[key]
            item.realIndex = parseInt(key)
            this.dropdownMenu.list.push(item)
          }
        }
        if (this.dropdownMenu.list.length > 0) {
          this.dropdownMenu.show = true
        } else {
          this.changeSlides(index)
        }
      }
    },
    closeMenu () {
      this.dropdownMenu = {
        index: null,
        list: [],
        show: false
      }
    },
    handleSubMenu (index) {
      this.changeSlides(index)
      this.closeMenu()
    }
  },
  mounted () {
    let self = this
    var routeID = null
    const slideID = parseInt(this.$route.params?.slideId)
    this.searchSlides(6).then(() => {
      self.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = self.slides[key].img
          self.imageCache.push(b)
        })
        // this.$route.params?.id && parseInt(this.$route.params.id)
        if (this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showLoader = true
          this.animate = this.isAnimating = false
          setTimeout(() => {
            this.changeSlides(parseInt(routeID))
            if (slideID) {
              setTimeout(() => {
                if (document.querySelector('.swiper-container')) { document.querySelector('.swiper-container').swiper.slideTo(slideID) }
              }, 1500)
            }
          }, 300)
        }
        this.currentSlide === 0 && this.handleIntroVideo()
        self.initEvents(true)
      })
    })
    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
