<template>
  <div class="speedCheck-slide">
    <!-- <backToHomeButton :title="backToHomeButtonText" /> -->
    <button class="close main-close-btn btn btn-link btn-sm btn-new" @click="$router.push({ name: 'Start' })">
      <img src="@/assets/images/newLogo.svg" alt=""><span v-text="backToHomeButtonText"></span>
    </button>
    <div class="app-inner" v-if="dataLoaded">
      <div class="slides-wrapper slides-wrapper-intro12 clip">
        <div class="a-slide active">
          <package-details :data="activePackage" :customData="detailsData"></package-details>
          <div class="main-content">
            <div class="main-content-left">
              <available-broadband :results="results" :broadbands="broadbands" @handleBroadband="handleBroadband"></available-broadband>
              <topics @handleTooltip="handleTooltip" @handleTopics="handleTopics" :tooltip="activeTooltip" :topics="topics" :results="results"></topics>
            </div>
            <div class="main-content-center">
              <speedometer :results="results" :lastVals="lastStats" :upload="totalUpload" :download="totalDonwload"></speedometer>
            </div>
            <div class="main-content-right">
              <online-activities @handleTooltip="handleTooltip" :tooltip="activeTooltip" :activities="activities" :results="results" :base-url="baseUrl" @handleActivity="handleActivities"></online-activities>
            </div>
          </div>
          <details-modal :data="plans"></details-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import backToHomeButton from '@/components/backToHomeButton'
import availableBroadband from '@/components/intro12-speed-check/available-broadband'
import topics from '@/components/intro12-speed-check/topics'
import onlineActivities from '@/components/intro12-speed-check/online-activities'
import detailsModal from '@/components/intro12-speed-check/details-modal'
import speedometer from '@/components/intro12-speed-check/speedometer'
import packageDetails from '@/components/intro12-speed-check/package-details'
export default {
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  data () {
    return {
      currentSlide: 1,
      animate: true,
      showLoader: false,
      backToHomeButtonText: 'Speed-Rechner',
      current: null,
      showIntro: false,
      showHome: true,
      isAnimating: false,
      dataLoaded: false,
      imageCache: [],
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      detailsData: null,
      packages: [],
      broadbands: [],
      topics: [],
      activities: [],
      planOptions: [],
      results: {
        progress: {
          download: 0,
          upload: 0
        },
        limit: {
          download: 1000,
          upload: 200
        },
        broadband: null,
        topics: {
          d: 0,
          u: 0
        }
      },
      lastStats: {
        p: {
          d: 0,
          u: 0
        },
        t: {
          d: 0,
          u: 0
        }
      },
      activeTooltip: '',
      memo: {
        d: 0,
        u: 0
      }
    }
  },
  computed: {
    ...mapState('slides', ['baseUrl', 'intro', 'introInfoPage', 'slides']),
    totalDonwload () {
      return this.results.topics.d >= this.results.progress.download ? this.results.topics.d : this.results.progress.download
    },
    totalUpload () {
      return (this.results.topics.u >= this.results.progress.upload ? this.results.topics.u : this.results.progress.upload)
    },
    activePackage () {
      let obj = null
      for (let i = 0; i < this.packages.length; i++) {
        if (this.packages[i].download_speed <= this.results.limit.download) {
          if (this.totalDonwload <= this.packages[i].download_speed) {
            if (this.totalUpload <= this.packages[i].upload_speed) {
              obj = this.packages[i]
              break
            }
          }
        } else if(this.packages[i - 1] && this.packages[i - 1].download_speed <= this.results.limit.download) {
          obj = this.packages[i - 1]
          break
        }
      }
      return obj || this.packages[this.packages.length - 1]
    }
  },
  components: {
    backToHomeButton,
    availableBroadband,
    topics,
    onlineActivities,
    detailsModal,
    speedometer,
    packageDetails
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleTopics (key) {
      this.topics[key].active = !this.topics[key].active
      this.lastStats.t = {
        d: this.results.topics.d,
        u: this.results.topics.u
      }
      let dl = 0
      let ul = 0
      for(let i = 0; i < this.topics.length; i++) {
        if (this.topics[i].active) {
          dl += this.topics[i].speed_download
          ul += this.topics[i].speed_upload
        }
      }
      
      if (this.results.topics.d != dl) {
        this.results.topics.d = (dl <= this.results.limit.download) ? dl : this.results.limit.download
      }
      if (this.results.topics.u != ul) {
        this.results.topics.u = ul <= this.results.limit.upload ? ul : this.results.limit.upload
      }
    },
    handleActivities (index, operator) {
      // debugger
      const itm = this.activities[index]
      this.lastStats.p = {
        d: this.memo.d <= this.results.limit.download ? this.memo.d : this.results.limit.download,
        u: this.memo.u <= this.results.limit.upload ? this.memo.u : this.results.limit.upload
      }
      this.activities[index].qty += (operator === '+' && itm.qty < 100 ? 1 : itm.qty > 0 ? -1 : 0)
      this.memo.d += (operator === '+' ? itm.speed_download : -(itm.speed_download)),
      this.memo.u += (operator === '+' ? itm.speed_upload : -(itm.speed_upload))
      this.results.progress.download = this.memo.d <= this.results.limit.download ? this.memo.d : this.results.limit.download
      this.results.progress.upload = this.memo.u <= this.results.limit.upload ? this.memo.u : this.results.limit.upload
    },
    handleBroadband (data) {
      if (!this.results.broadband || this.results.broadband.id !== data.id) {
        this.resetAll()
        this.results.broadband = data
        this.results.limit = {
          download: data.download_speed,
          upload: data.upload_speed
        }
      } else {
        this.resetAll()
        this.results.broadband = null
        this.results.limit = {
          download: 1000,
          upload: 200
        }
      }
    },
    resetAll () {
      this.activities.forEach((item, i) => {
        this.$set(this.activities, i, {...item, qty: 0})
      })
      this.topics.forEach((item, i) => {
        this.$set(this.topics, i, {...item, active: false})
      })
      this.lastStats = {
        t: {
          d: 0,
          u: 0
        },
        p: {
          d: 0,
          u: 0
        }
      }
      this.results = {
        progress: {
          download: 0,
          upload: 0
        },
        limit: {
          download: 1000,
          upload: 0
        },
        topics: {
          d: 0,
          u: 0
        },
        broadband: null
      }
      this.memo = {
        d: 0,
        u: 0
      }
    },
    handleTooltip (data) {
      this.activeTooltip = data !== this.activeTooltip ? data : ''
    }
  },
  mounted () {
    this.searchSlides(12).then(resp => {
      this.packages = resp.data.packages
      this.broadbands = resp.data.broadbands
      this.topics = resp.data.topics
      this.activities = resp.data.activities
      this.plans = resp.data.plans
      this.detailsData = resp.data.custom[0] || null
      setTimeout(() => {
        this.dataLoaded = true
      }, 500)
    })
  },
  created () {},
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
