<template>
  <div class="phone-box">
    <div class="phone-wrapper">
      <div class="phone-wrapper__select">
        <button @click="dropdownMenu = !dropdownMenu">
          Vergleichen
          <span :class="['arrow', 'arrow-' + (dropdownMenu ? 'down' : 'left')]">
            <img :src="require('@/assets/images/intro13/compare-icon-white.svg')" />
          </span>
        </button>
      </div>
      <div class="all-phones__top__browse" v-if="dropdownMenu">
        <div v-if="manufacturers && dropdownMenu">
          <template v-if="!selectedManufacturer" >
            <template v-for="(manufacturer, index) in manufacturers">
              <a v-if="activePhones.includes(manufacturer.id)"
              @click="selectedManufacturer = manufacturer.id"
              :key="'mnf-' + index" v-text="manufacturer.title"></a>
            </template>
          </template>
          <template v-else>
            <span class="back-btn" @click="selectedManufacturer = null">
                <img :src="require('@/assets/images/intro13/compare-icon-white.svg')" />
            </span>
            <a v-for="(model, index) in phoneModels"
            :key="'model-'+  index"
            @click="handleModel(model)"
            v-text="model.name"></a>
          </template>
        </div>
      </div>
      <div class="phone-wrapper__image" @click="handleClick" v-if="phone && !dropdownMenu">
        <transition name="fade-in">
          <img v-if="baseUrl && phone.images_and_colors[activeColor]"
          :src="fullImage(phone.images_and_colors[activeColor])" />
        </transition>
        <div class="phone-wrapper__image__colors">
          <template
          v-for="(color, i) in phone.images_and_colors">
            <span
            v-if="color.image"
            :key="'color-' + i"
            :style="{
                backgroundColor: color.color
            }"
            @click.stop="handleColor(i)"></span>
          </template>
        </div>
      </div>
      <div v-if="phone" class="phone-wrapper__title">
        <h4 v-text="phone.name"></h4>
        <p><b>Speicher</b> {{ phone.storage }}</p>
        <p><b>Farbe</b> {{ phone.images_and_colors[activeColor] ? phone.images_and_colors[activeColor].color_name : '' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: Object,
      default: null
    },
    inSlider: {
      type: Boolean,
      default: false
    },
    showDots: {
      type: Boolean,
      default: true
    },
    manufacturers: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeColor: this.phone?.activeColor > -1 ? this.phone?.activeColor : 0,
      selectedManufacturer: null,
      dropdownMenu: !this.phone,
      phoneOrder: {
        0: 'firstPhone',
        1: 'secondPhone',
        2: 'thirdPhone'
      }
    }
  },
  watch: {
    phone (nv, ov) {
      this.dropdownMenu = !nv
    }
  },
  computed: {
    baseUrl () {
      return this.$store.state?.slides?.baseUrl
    },
    phoneModels () {
      return this.$store.state?.smartphoneComparison?.phones?.filter(
        phone => parseInt(phone.manufacturer) === parseInt(this.selectedManufacturer)
      )
    },
    comparedPhones () {
      return this.$store.state?.smartphoneComparison?.comparedPhones
    },
    activePhones () {
      return Array.from(new Set(this.$store.state?.smartphoneComparison?.phones.map(phone => parseInt(phone.manufacturer))))
    }
  },
  methods: {
    fullImage (data) {
      return this.baseUrl && data.image ? this.baseUrl + data.image : require('@/assets/images/intro13/xioami-14-ultra.png')
    },
    handleColor (i) {
      if (i === this.activeColor) return
      this.activeColor = parseInt(i)
      this.$store.commit('smartphoneComparison/setPhonesCompare', { key: this.phoneOrder[this.index], phone: { ...this.phone, activeColor: this.activeColor } })
    },
    handleClick () {
      if (this.$store.state?.smartphoneComparison?.activeView !== 'comparison') return
      this.$store.commit('smartphoneComparison/setActivePhone', this.phone)
      this.$store.commit('smartphoneComparison/setActiveView', 'slider')
    },
    handleModel (phone) {
    //   let cp = this.comparedPhones
    //   cp[this.index] = phone
    //   this.$store.commit('smartphoneComparison/setComparedPhones', cp)
      this.$store.commit('smartphoneComparison/setPhonesCompare', { key: this.phoneOrder[this.index], phone: { ...phone, activeColor: this.activeColor } })
      this.dropdownMenu = false
    }
  }
}
</script>
