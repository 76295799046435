<template>
  <div @click.stop.prevent="triggerChange">
    <h1 class="i6-title-main" v-if="item.title" v-html="item.title"></h1>
    <div class="cover-bg-box">
      <div class="swiper-button-prev"></div>
      <div  class="service-provider-logo">
        <img :src="$store.state.slides.baseUrl + item.header_image" v-if="item.header_image" />
      </div>
      <swiper class="i6-marathon-slider" :options="sliderOptions">
        <swiper-slide v-for="(slideCover, coverIndex) in item.slideCovers"
          :key="'swiper-image-item' + coverIndex">
          <img :src="$store.state.slides.baseUrl + slideCover.cover" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['item'],
  data () {
    return {
      innerAnimating: false,
      sliderOptions: {
        effect: 'coverflow',
        grabCursor: true,
        slidesPerView: 5,
        centerSlides: true,
        centeredSlidesBounds: true,
        infinite: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        coverflowEffect: {
          rotate: 35,
          stretch: 50,
          depth: 200,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    onSlideChange (item) {
      // console.log(this.$refs.comSlider)
    },
    triggerChange (e) {
      let myTarget = e.target
      if (myTarget.className.includes('i6-title')) {
        this.$emit('changeSlides', 'l')
      }
    }
  },
  computed: {},
  mounted () {
  }
}
</script>
