<template>
    <div class="type-13-marketingSlide d-flex flex-column"
    :style="{ 'background-image': 'url(' + innerImage + ')' }"
    @click.stop.prevent="triggerChange">
        <!-- {{ baseUrl + item.img }} -->
        <div v-if="item.title"
        class="type-13-marketingSlide-title"
        v-html="item.title"></div>
        <div
        v-if="item.subtitle_first || item.subtitle_second"
        class="type-13-marketingSlide-subtitle d-flex flex-column align-items-start">
            <span class="subs" v-if="item.subtitle_first" v-text="item.subtitle_first"></span>
            <span class="subs" v-if="item.subtitle_second" v-text="item.subtitle_second"></span>
        </div>
        <div v-if="item.innerImage" class="type-13-marketingSlide-innerImage">
            <div class="blackSticker">
                <span class="blackSticker-text">Aktion:</span>
                <span class="blackSticker-content" v-text="item.sticker_content"></span>
            </div>
            <img :src="baseUrl + item.innerImage" alt="">
        </div>
        <div v-if="item && item.inner_content"
        class="type-13-marketingSlide-content" v-html="item.inner_content"></div>
        <div class="type-13-marketingSlide-actionButtons d-flex justify-content-between">
            <a class="btn btn-primary" target="_blank" :href="baseUrl + item.file_button" v-text="item.file_button_text"></a>
            <a target="_blank" :href="item.external_link_button" class="btn btn-primary" v-text="item.external_link_button_text"></a>
            <button class="btn btn-primary" @click.stop="$router.push({ name: 'StreamingCalculator' })">Streaming-Rechner</button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'marketingSlide',
  props: {
    item: {
      type: Object
    },
    baseUrl: {
      type: String
    }
  },
  computed: {
    innerImage () {
      return this.baseUrl + this.item.img
    }
  },
  methods: {
    triggerChange (e) {
      if (e.target.nodeName !== 'A') this.$emit('changeSlides')
      else window.open(e.target.href, '_blank')
    }
  }
}
</script>
