<template>
  <!-- new custom slide content inside -->
  <div class="custom-slide-container">
    <div class="customSlideWrapper" @click.self="$emit('triggerChange')">
      <swiper ref="customSliderRef"
      :options="sliderOptions"
      @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(customItem, i) in item.stickerSlides" :key="'swiper-custom-' + i">
          <div class="inner-parent" v-for="(innerItem, j) in customItem.listSlides" :key="'customItem-slide-' + j">
            <div class="relative-holder">
              <h2 v-text="innerItem.title"></h2>
              <span class="desc" v-text="innerItem.subtitle"></span>
              <div class="image-parent">
                <img :src="baseUrl + innerItem.image" alt="">
                <span v-if="innerItem.sticker" :class="{ 'isCircleImg': innerItem.is_circle }" class="custom-slide-sticker">
                  <img :src="baseUrl + innerItem.sticker" alt="">
                </span>
              </div>
            </div>
            <span class="price-item" v-text="innerItem.price"></span>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot=""></div>
      <div class="swiper-button-next" slot=""></div>
    </div>
    <div class="customSlideDetails">
      <a
      href="#"
      class="button-dropdown"
      :class="{ 'isActive': slideDetails }"
      @click.prevent="slideDetails = !slideDetails">
      Produktbestandteile
      </a>
      <ul class="product-infos" v-if="slideDetails">
        <li v-for="(item, i) in slideDetailsObj" :key="'slide-details-' + i">
          <!-- {{ item + i }} -->
          <div class="product-title">
            <img :src="require('@/assets/images/intro2/' + detailKeys[i].icon)" class="product-icon">
            <h4 v-text="detailKeys[i].title"></h4>
          </div>
          <div class="product-result" v-if="item">
            <div class="column" v-for="(check, j) in item" :key="'check-' + i + j">
              <img :src="require('@/assets/images/intro2/' + (check === 'true' ? 'done': 'not-done') +'.svg')" class="result-icon" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    },
    baseUrl: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    customSwiper () {
      return this.$refs.customSliderRef.$swiper
    }
  },
  data () {
    return {
      sliderOptions: {
        slidesToShow: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      slideDetails: null,
      slideDetailsObj: null,
      detailKeys: {
        'welcome-call': {
          title: 'Welcome-Call',
          icon: 'service-employee_solid.svg'
        },
        'vor-ort-service': {
          title: 'Vor-Ort-Service',
          icon: 'technician_solid.svg'
        },
        'heimnetz-service': {
          title: 'Heimnetz-Service',
          icon: 'wlan_solid.svg'
        },
        'anwendungsuntertutzung': {
          title: 'Anwendungsunterstutzung',
          icon: 'computer_solid.svg'
        },
        'magenta-zuhause-app-pro': {
          title: 'Magenta Zuhause App Pro',
          icon: 'magenta-zuhause_graphical.svg'
        },
        'meinmagenta-app': {
          title: 'MeinMagenta App',
          icon: 'meinmagenta_graphical.svg'
        }
      }
    }
  },
  methods: {
    onSlideChange () {
      this.handleSlideDetails(this.$refs.customSliderRef['$swiper'].realIndex)
    },
    handleSlideDetails (index) {
      if (this.item.stickerSlides[index]) this.slideDetailsObj = this.item.stickerSlides[index].listDescription
    }
  },
  mounted () {
    this.handleSlideDetails(0)
  }
}
</script>
