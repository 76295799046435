<template>
  <div class="details-modal-wrapper" :class="{ 'active': showModal }" @click.self="showModal = false">
    <div class="details-modal d-flex justify-content-center" :class="{ 'active': showModal }">
      <div class="details-modal__content" >
        <div class="details-modal__content-wrapper">
          <div class="details-modal__content-wrapper-top">
            <button class="btn" :class="{ active: type === 0 }" @click="type = 0">Download</button>
            <button class="btn" :class="{ active: type === 1 }" @click="type = 1">Upload</button>
          </div>
          <div class="details-modal__content-wrapper-main">
            <div class="modal-leftPart">
              <ul>
                <li v-for="(item, i) in data" :key="'lc-key-' + i">
                  <button class="btn" @click="activeIndex = i" :class="{ active: i === activeIndex }" v-text="item.title"></button>
                </li>
              </ul>
            </div>
            <div class="modal-rightPart">
              <ul v-if="data.length > 0 && data[activeIndex] && data[activeIndex].plans">
                <li class="d-flex justify-content-spacebetween" v-for="(item, i) in data[activeIndex].plans" :key="'rc-key-' + i">
                  <div class="packInfo">
                    <span class="packInfo-title" v-text="item.title"></span>
                    <span class="packInfo-desc" v-text="item.details"></span>
                  </div>
                  <div class="packTiming" v-text="type === 0 ? item.download : item.upload"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="details-modal__arrow d-flex flex-column align-items-center" @click="showModal = !showModal">
        <span class="details-modal__arrow-icon">
          <img src="@/assets/images/intro12/icons/icon-arrow.svg" alt=""/>
        </span>
        <span class="details-modal__arrow-text">Geschwindigkeitsvergleich</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      activeIndex: 0,
      type: 0
    }
  }
}
</script>
