<template>
  <div>
    <div class="custom-popup-backdrop"></div>
    <div class="custom-popup pb-md-8 pt-md-6 fade-in-bottom">
     <div class="position-relative z-i-5"> <button @click="close()" class="close btn btn-link btn-sm"><img src="@/assets/images/cancel.svg" alt=""></button></div>
      <div class="inner">
        <swiper class="swiper" :options="{ slidesPerView:  (this.$window.innerWidth < 769) ? 1 : 2, spaceBetween: 30 }">
          <swiper-slide v-for="(item, index) in videos" :key="'slide' + index">
            <div class="a-video mx-md-4 text-center" @click="openVideo(item.url)">
              <div class="outer-circle mx-auto">
                <div class="inner-circle">
                  <div class="img" :style="{ backgroundImage: 'url(' + $store.state.slides.baseUrl + item.thumb + ')' }">
                    <img src="@/assets/images/play.svg" class="play-icon" alt="">
                  </div>
                </div>
              </div>
              <h3 class="mt-7 font-weight-light">{{ item.title }}</h3>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div v-if="video" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click="video = false" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="video"></video>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['videos'],
  data () {
    return {
      video: false
    }
  },
  methods: {
    close () {
      this.$emit('closePopup')
    },
    openVideo (url) {
      this.video = (url === undefined) ? '://0' : this.$store.state.slides.baseUrl + url
    }
  }
}
</script>
