<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner" v-if="!slideWithPassword">
      <div class="slides-wrapper slides-wrapper-intro3 clip" @click.stop="changeSlides" :class="{
        [current && current.splashPosition] : current && current.splashPosition,
        [current && current.align] : current && current.align
      }">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" v-if="currentSlide == index" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index
          }" :style="{ backgroundImage : 'url(' + baseUrl + item.img + ')' }">
          </div>
          <div v-if="currentSlide == index" :class="{ 'animate' : (!showLoader && !animate && currentSlide == index) }"
               :key="'a-slide-content' + index" class="h1">
            <span class="text-uppercase" v-html="item.title"></span>
          </div>
        </template>
        <div id="i3-splash">
          <div class="inner"
               :class="{ 'center' : showLoader, [current && current.splashPosition] : current && current.splashPosition }">
            <img v-for="index in 5" :key="'splash-' + index" :class="['splash-' + index]"
                 :src="require('@/assets/images/splash.svg')" alt="">
          </div>
        </div>
        <!-- ./src because didn't work somehow with built files -->
      </div>
    </div>
    <div id="intro" class="intro3-video" v-if="showIntro && intro" :class="{ 'visible' : !isAnimating  }">
      <video :poster="baseUrl + intro.video_cover" muted ref="$video" id="intro-video" @click="handleIntroVideo" playsinline
             :src="baseUrl + intro.video"></video>
      <button v-if="!isVideoPlaying" id="play-intro"><img :src="require('@/assets/images/play.svg')" alt=""></button>
      <button @click="hideIntro()" class="btn text-white">Skip Intro <img src="@/assets/images/intro-arrows.svg" alt="">
      </button>
    </div>

    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>

    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>

    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import backToHomeButton from '@/components/backToHomeButton'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    videoWrapper
  },
  data () {
    return {
      slideWithPassword: null,
      currentSlide: 0,
      animate: true,
      showLoader: false,
      backToHomeButtonText: 'Title',
      current: null,
      showIntro: true,
      showHome: true,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: false,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      showInfoBox: false,
      infoBoxData: null
    }
  },
  watch: {
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    },
    current (nv, ov) {
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    }
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      this.showIntro = true
      this.currentSlide = 0
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
      setTimeout(() => {
        this.showIntro = false
      }, 1300)
    },
    changeSlides (id) {
      if (this.isAnimating) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      this.isAnimating = true
      this.showLoader = true
      this.animate = true
      this.showIntro = false

      setTimeout(() => {
        this.showLoader = false

        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length)) this.handleIntroVideo()
        this.current = this.slides[this.currentSlide]

        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
        }, 1000)
      }, 1500)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    }
  },
  created () {
  },
  mounted () {
    let $this = this
    this.searchSlides(3).then((resp) => {
      if (resp) $this.handleInfoBox($this.introInfoPage)

      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = this.slides[key].img
          $this.imageCache.push(b)
        })
        var routeID = null
        if ($this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }

        if (routeID && routeID <= Object.keys($this.slides).length) { $this.showIntro = false; $this.changeSlides(routeID) } else { $this.showIntro = true }

        document.querySelector('.slides-wrapper').addEventListener('touchstart', handleTouchStart, false)
        document.querySelector('.slides-wrapper').addEventListener('touchmove', handleTouchMove, false)

        var xDown = null
        var yDown = null

        function getTouches (evt) {
          return evt.touches || evt.originalEvent.touches
        }

        function handleTouchStart (evt) {
          const firstTouch = getTouches(evt)[0]
          xDown = firstTouch.clientX
          yDown = firstTouch.clientY
        }

        function handleTouchMove (evt) {
          if (!xDown || !yDown || $this.isAnimating) {
            return
          }

          var xUp = evt.touches[0].clientX
          var yUp = evt.touches[0].clientY

          var xDiff = xDown - xUp
          var yDiff = yDown - yUp

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
              $this.changeSlides('l')
            } else {
              $this.changeSlides('r')
            }
          } else {
            if (yDiff > 0) {
              $this.changeSlides('u')
            } else {
              $this.changeSlides('d')
            }
          }
          /* reset values */
          xDown = null
          yDown = null
        }

        document.querySelector('.slides-wrapper').addEventListener('wheel', (e) => {
          var dir = Math.sign(e.deltaY)

          if (!$this.isAnimating) {
            if (dir === -1) {
              $this.changeSlides('r')
            } else {
              $this.changeSlides('l')
            }
          }
          e.preventDefault()
          e.stopPropagation()
          return false
        })
      })
    })
    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>

//
<style lang="scss">
  //   @import "assets/scss/style"
  // </style>
