<template>
  <div class="slide-popup-wrapper">
    <div class="slide-popup-wrapper-actions" @click="closePopup">
      <span class="text">Schließen</span>
      <span class="icon">
        <img :src="require('@/assets/images/cancel-white.svg')" alt="">
      </span>
    </div>
    <div class="slide-popup-wrapper-inner">
      <span>
        <img :src="$store.state.slides.baseUrl + item.image" alt="">
      </span>
      <h4 v-html="item.title"></h4>
      <p v-html="item.description"></p>
      <h5 v-html="item.moreText"></h5>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('handlePopup', false)
    }
  }
}
</script>
