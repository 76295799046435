<template>
 <div>
   <div id="video-wrapper" :class="{ 'opened' : isOpened, 'is-playing-video' : this.video }">
     <span class="overlay" @click.stop="toggleMenu()"></span>
     <button class="btn btn-light toggle btn-lg" @click="toggleMenu()">
       <img v-if="isOpened" class="rotate-45" src="@/assets/images/cancel.svg" alt="">
       <img v-else src="@/assets/images/video-icon.svg" style="height: 28px;width: auto;" alt="">
     </button>
     <div class="video-box-wrapper" v-if="isOpened">
       <button v-if="videos.length >= 3" class="btn btn-link v-b-btn v-b-swiper-button-next"></button>
       <swiper class="swiper w-100" ref="videoWrapper" :options="sldata">
         <template v-for="(item, index) in videos">
           <swiper-slide :key="'video-box-' + index" :class="{ 'one-item' : videos.length === 1 }">
             <div class="item" :key="'video-box-' + index">
               <h2 class="font-weight-light mb-4">{{ item.title }}</h2>
               <div @click="openVideo(item)" class="poster position-relative" :style="{
                backgroundImage : 'url(' + $store.state.slides.baseUrl + item.poster + ')',
                backgroundColor : '#000'
              }">
                 <button class="btn play-btn"><img :src="require('@/assets/images/play.svg')" alt=""></button>
               </div>
             </div>
           </swiper-slide>
         </template>
       </swiper>
       <button v-if="videos.length >= 3" class="btn btn-link v-b-btn v-b-swiper-button-prev"></button>
     </div>
     <div v-if="video" class="singleVideoContent" :class="{ 'fakeFade': true, 'active' : showPopup }">
       <div class="custom-popup video-popup">
         <div class="inner bg-black position-relative">
           <button @click.stop="video = showPopup = null" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
           <video autoplay class="pop-up-video" controls :src="video"></video>
         </div>
       </div>
     </div>
   </div>
   <div class="videoWrapperWrap" :class="{ 'opened' : isOpened }"></div>
 </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  computed: {
    swiper () {
      return this.$refs.videoWrapper.$swiper
    }
  },
  watch: {
    isOpened (v) {
      setTimeout(() => {
        this.swiper.update()
      }, 650)
    }
  },
  updated () {
    this.swiper && this.swiper.update()
  },
  props: ['isOpened', 'videos'],
  data () {
    return {
      video: null,
      showPopup: false,
      sldata: {
        // Optional parameters
        slidesPerView: 2,
        loop: false,
        watchOverflow: true,
        scrollbar: false,
        // observer: true,
        // observeParents: true,
        navigation: {
          nextEl: '.v-b-swiper-button-next',
          prevEl: '.v-b-swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    checkVideoSingleVideo (videoState) {
      if (!videoState) this.video = null
    },
    openVideo (item) {
      this.video = this.$store.state.slides.baseUrl + item.video
      setTimeout(() => {
        this.showPopup = true
      }, 370)
    },
    toggleMenu () {
      this.$emit('togglePanel', 'videoWrapper', this.isOpened)
    }
  }
}
</script>
