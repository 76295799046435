<template>
  <div class="phone-template-wrapper__container phone-bg-shadow">
    <div class="phone-list">
      <div class="phone-list__header">
        <h2 v-text="title"></h2>
      </div>
      <div class="phone-list-wrapper">
        <template v-for="(phone, i) in categoryItems">
          <SmartphoneSmall
            v-if="phone"
            :ref="'phone-ct' + i"
            :key="'phone-' + i"
            :phone="phone"
            :cat="true"
            @handleClick="handleClick" />
        </template>
      </div>
    </div>
    <!-- <footer class="phone-template-wrapper__container__footer">
      <button>back</button>
      <button>Mehr information</button>
      <button>print</button>
    </footer> -->
  </div>
</template>

<script>
import SmartphoneSmall from '@/components/smartphone-comparison/smartphone-small'

export default {
  components: {
    SmartphoneSmall
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    categoryItems: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    handleClick (phone) {
      // console.log(this.$refs['phone-ct' + phone.id])
      this.$emit('setPhone', phone)
    }
  }
}
</script>
