<template>
    <div class="telekomPlans">
        <div class="telekomPlans-tabs">
            <ul>
                <li v-for="(item, i) in data" :key="'tl-key-' + i">
                    <button class="btn" :class="{ active: i === activePlan }" @click.stop.prevent="handlePlan(i)" v-text="item.title"></button>
                </li>
            </ul>
        </div>
        <div class="details-modal-wrapper">
            <div class="details-modal d-flex justify-content-center active">
                <div class="details-modal__content" >
                <div class="details-modal__content-wrapper">
                    <div class="details-modal__content-wrapper-top">
                    <button class="btn" :class="{ active: type === 0 }" @click.stop.prevent="type = 0">Download</button>
                    <button class="btn" :class="{ active: type === 1 }" @click.stop.prevent="type = 1">Upload</button>
                    </div>
                    <div class="details-modal__content-wrapper-main">
                    <div class="modal-leftPart" v-if="data.length > 0 && data[activePlan] && data[activePlan].options">
                        <ul>
                            <li v-for="(plan, i) in data[activePlan].options" :key="'lc-key-' + i">
                                <button class="btn" @click.stop.prevent="activeOption = i" :class="{ active: i === activeOption }" v-text="plan.title"></button>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-rightPart">
                        <ul v-if="data.length > 0 && data[activePlan].options && data[activePlan].options[activeOption]">
                            <li class="d-flex justify-content-spacebetween" v-for="(opt, i) in data[activePlan].options[activeOption].option_info" :key="'rc-key-' + i">
                                <div class="packInfo">
                                    <span class="packInfo-title" v-text="opt.title"></span>
                                    <span class="packInfo-desc" v-text="opt.details"></span>
                                </div>
                                <div class="packTiming" v-text="type === 0 ? opt.download : opt.upload"></div>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      activePlan: 0,
      activeOption: 0,
      activeIndex: 0,
      type: 0
    }
  },
  methods: {
    handlePlan (i) {
      this.activePlan = i
      this.activeOption = 0
      // this.type = 0
    }
  }
}
</script>
