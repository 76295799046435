<template>
  <div class="w-100">
    <div class="hybridVideos">
      <ul>
        <li
          v-for="video in videos" :key="video.id"
          :class="{ 'boxStyle': videos.length === 4 }"
          class="hybridVideos-video">
          <h3 class="hybridVideos-video-title" v-text="video.title"></h3>
          <div class="hybridVideos-video-box">
            <div class="play"
            :style="{
              backgroundImage: 'url(' + baseUrl + video.poster + ')',
            }"
            @click.stop="$emit('toggleVideo', video)"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videos: {
      type: Array,
      default: () => ([])
    },
    baseUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
