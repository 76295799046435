<template>
  <div class="a-slide__headers">
    <div class="a-slide__headers--left">
      <div class="a-slide__headers--left_tipp primary" v-if="tipp" v-html="tipp"></div>
      <!-- <button class="btn" @click="$emit('handleSportOffers', sportOffers ? false : true)" v-text="sportOffers ? 'Zurück zur Auswahl' : 'Sportangebot'"></button> -->
    </div>
    <div class="a-slide__headers--center" v-if="!sportOffers && tariffs">
      <ul>
        <template v-for="(tariff, i) in filteredTariffs">
          <li
            :key="'tff-' + i"
            :class="{'active': state && state.tariff && state.tariff.id === tariff.id}"
            @click="$emit('handleTariff', tariff, true)">
            <span v-text="tariff.name" class="name"></span>
            <div class="tooltip-placeholder" v-if="tariff.tooltip && tariff.tooltip.length > 5">
              <button class="btn icon" :class="'tariff-' + tariff.id" @click.stop="$emit('handleTooltip', 'tariff', tariff.id)" @blur="$emit('handleTooltip', null, null)">
                <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
              </button>
            </div>
            <div v-if="tooltip && tooltip === 'tariff-' + tariff.id" class="tooltip-holder" :class="tariff.tooltip_position" v-html="tariff.tooltip"></div>
          </li>
        </template>
        <!-- <li v-if="state && state.tariff" class="active">
          <span v-text="state.tariff.name" class="name"></span>
          <div class="tooltip-placeholder">
            <button class="btn icon" :class="'tariff-' + state.tariff.id" @click.stop="$emit('handleTooltip', 'tariff', state.tariff.id)" @blur="$emit('handleTooltip', null, null)">
              <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
            </button>
          </div>
          <div v-if="tooltip && tooltip === 'tariff-' + state.tariff.id" class="tooltip-holder" :class="state.tariff.tooltip_position" v-html="state.tariff.tooltip"></div>
        </li> -->
      </ul>
    </div>
    <div class="a-slide__headers--right" :class="{'align-items-end': sportOffers}">
      <button class="btn resetButton" :class="{'back': sportOffers}" @click="$emit('handleSportOffers', sportOffers ? false : true)" v-text="sportOffers ? 'Zurück zur Auswahl' : 'Sportangebot'"></button>
      <div class="actionResetWrapper">
        <div class="tvActions" v-if="!sportOffers">
          <div class="tvActions__tools">
            <div class="tvActions__tools--switch">
              <span
                @click="$emit('handleTvAction', 1)"
                :class="{'active': state && state.tv_action === 1}">NK</span>
              <span
                @click="$emit('handleTvAction', null)"
                class="clear"
                :class="{'active': state && !state.tv_action}">X</span>
              <span
                @click="$emit('handleTvAction', 2)"
                :class="{'active': state && state.tv_action === 2}">BK</span>
            </div>
          </div>
          <span class="title">TV Aktion</span>
        </div>
        <button class="btn" @click="$emit('reset', '')">
          <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="27" viewBox="0 0 31.5 27">
            <path id="Icon_material-settings-backup-restore" data-name="Icon material-settings-backup-restore" d="M18,4.5A13.5,13.5,0,0,0,4.5,18H0l6,6,6-6H7.5a10.507,10.507,0,1,1,4.41,8.55L9.78,28.71A13.5,13.5,0,1,0,18,4.5Z" transform="translate(0 -4.5)" fill="#e20074"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tariffs: {
      type: Array,
      default: null,
      required: true
    },
    state: {
      type: Object,
      default: null
    },
    sportOffers: {
      type: Boolean,
      default: false,
      required: true
    },
    baseUrl: {
      type: String
    },
    tooltip: {
      type: String
    },
    tipp: {
      default: null
    }
  },
  computed: {
    filteredTariffs () {
      return this.tariffs.filter(item => {
        return item
      })
    }
  },
  data () {
    return {
      activeTariff: null
    }
  },
  methods: {
    handleActiveTariff (item) {
      this.activeTariff = item
    }
  },
  created () {
  }
}
</script>
