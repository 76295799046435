<template>
  <div @click.stop.prevent="handleClick" id="sliderHolderId2" class="simpleWrapperClass">
    <swiper ref="comSlider"
        :options="sliderOptions"
        @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(subItem, i) in item.slideItems" :key="'swiper-i-' + i">
        <div class="simple-slider-content-holder">
          <div>
            <h1 class="i6-content-title" v-if="subItem && subItem.title" v-html="subItem.title"></h1>
            <img v-if="subItem.sticker" :src="$store.state.slides.baseUrl + subItem.sticker" />
          </div>
          <div class="slider-image-holder" :style="{ backgroundImage : 'url(' + $store.state.slides.baseUrl + subItem.image + ')' }">
            <!-- <img :src="require('@/assets/images/intro6/geraete/' + subItem.image)" alt=""> -->
          </div>
        </div>
      </swiper-slide>
      <div :class="'swiper-pagination swiper-pagination-top'" slot="pagination"></div>
      <div class="swiper-scrollbar js-swiper-scrollbar custom" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['item'],
  data () {
    let self = this
    return {
      activeItem: null,
      activeIndex: null,
      innerAnimating: false,
      sliderOptions: {
        navigation: false,
        slidesToShow: 1,
        freeMode: false,
        // observer: true,
        scrollbar: {
          el: '.swiper-scrollbar.js-swiper-scrollbar.custom',
          draggable: false,
          freeModeSticky: true,
          freeModeMinimumVelocity: 0.4,
          freeModeMomentumRatio: 0.28,
          snapOnRelease: true
        },
        pagination: {
          el: '.swiper-pagination.swiper-pagination-top',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + self.item.slideItems[index].category + '</span>'
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    onSlideChange (item) {
      const arrayIndex = this.$refs.comSlider.$swiper.realIndex
      this.activeItem = this.item.slideItems[arrayIndex]
      this.activeIndex = arrayIndex
      this.returnInfoBox(this.activeItem.info_page || null)
      this.returnVideoBox(this.activeItem.video_box || null)
    },
    handleClick (e) {
      const myTarget = e.target
      if (myTarget.className.includes('swiper-pagination-bullet') ||
      myTarget.className.includes('swiper-scrollbar')) {
        e.preventDefault()
        e.stopPropagation()
      } else this.$emit('changeSlides', 'l')
    },
    returnInfoBox (data) {
      this.$emit('handleInfoBox', data)
    },
    returnVideoBox (data) {
      this.$emit('handleVideoBox', data)
    }
  },
  computed: {
    scrollOptions () {
      return this.item.slideItems.map((el, i) => {
        // console.log(el)
        return {
          direction: 'vertical',
          slidesPerView: 'auto',
          freeMode: (el.childSlides === undefined),
          scrollbar: {
            el: '.swiper-scrollbar.scrollbarInner-' + i,
            draggable: true,
            dragSize: 20,
            freeModeSticky: true,
            snapOnRelease: (el.childSlides !== undefined)
          },
          pagination: el.childSlides ? {
            el: '.swiper-pagination.swiper-pagination-' + i,
            clickable: true,
            renderBullet: function (index, className) {
              return el.childSlides[index] ? '<span class="' + className + '">' + el.childSlides[index].name + '</span>' : ''
            }
          } : false,
          observer: true,
          observeParents: true,
          mousewheel: false
        }
      })
    }
  },
  mounted () {
    let $this = this
    this.$nextTick(() => {
      $this.activeItem = $this.item.slideItems[0]
      $this.activeIndex = 0
      $this.returnInfoBox($this.activeItem.info_page || null)
      $this.returnVideoBox($this.activeItem.video_box || null)
    })
  }
}
</script>
