<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path id="Path_780" data-name="Path 780"
        d="M16.464-58.92H-4.248V-79.632h-6.575V-58.92H-31.536v6.575h20.712v20.712h6.575V-52.344H16.464Z" transform="translate(31.536 79.632)" :fill="color"/>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
<style scoped>
.icon{
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
}
</style>
