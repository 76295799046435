<template>
  <div id="side-menu" v-if="slides && Object.keys(slides).length > 1" :class="{ 'opened' : isOpened }">
    <span class="overlay" @click.stop="toggleMenu()"></span>
    <button class="btn btn-light toggle btn-lg" @click="toggleMenu()">
      <img v-if="isOpened" class="rotate-45" src="@/assets/images/arrow-gray.svg" alt="">
      <img v-else src="@/assets/images/menu-icon.svg" alt="">
    </button>
    <button class="btn menu-arrow-up" @click="scrollMenu('up')"><img src="@/assets/images/arrow.svg" alt=""></button>
    <ul id="scroll-list" class="mb-0">
      <li v-if="hasIntro && intro" :class="{'active-parent': 0 == currentSlide}">
        <a href="#" @click="showIntroVideo()" :class="{'active': 0 == currentSlide}" v-html="intro.menu_title ? intro.menu_title : 'Start'"></a>
      </li>
      <template v-for="(item, index) in slides">
        <li :class="{'active-parent':  ((currentSlideInnerSlide) ? index == currentSlide && currentSlideInnerSlide === 1 : index == currentSlide) || forceActive === index }" :key="index" v-if="item.menu_title || item.title">
          <a href="#" @click="updateSlide(index)" :class="{'active': ((currentSlideInnerSlide) ? index == currentSlide && currentSlideInnerSlide === 1 : index == currentSlide) || forceActive === index }" v-html="item.menu_title ? item.menu_title : item.title"></a>
        </li>
        <template v-if="item.innerSlides">
          <li v-for="(innerItem, innerIndex) in item.innerSlides" :key="index + '-' + innerIndex" :class="{'active-parent': index == currentSlide && innerIndex == currentSlideInnerSlide - 1 }" >
            <a href="#" @click="updateSlide(index, innerIndex)" :class="{'active': index == currentSlide &&  innerIndex == currentSlideInnerSlide - 1 }" v-html="innerItem.menu_title ? innerItem.menu_title : innerItem.title"></a>
          </li>
        </template>
      </template>
    </ul>
    <button class="btn menu-arrow-down" @click="scrollMenu('down')"><img src="@/assets/images/arrow.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  props: ['isOpened', 'slides', 'currentSlide', 'hasIntro', 'currentSlideInnerSlide', 'intro'],
  updated () {
    if (document.querySelector('#scroll-list .active-parent')) {
      document.getElementById('scroll-list').scrollTop = document.querySelector('#scroll-list .active-parent').offsetTop - 120
    }

    if (this.currentSlide && this.slides[this.currentSlide] && this.slides[this.currentSlide].title === '' && this.slides[this.currentSlide].advantages) this.forceActive = parseInt(this.currentSlide) - 1
    else this.forceActive = false
  },
  data () {
    return {
      forceActive: false
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('togglePanel', 'sideMenu', this.isOpened)
    },
    showIntroVideo () {
      this.$emit('handleIntroVideo')
      this.$emit('togglePanel', 'sideMenu', this.isOpened)
    },
    updateSlide (id, subId = null) {
      this.toggleMenu()

      // this.$emit('changeSlides', this.slides[this.currentSlide], id)
      if (!subId) this.$emit('changeSlides', parseInt(id), null, true)
      else this.$emit('changeSlides', parseInt(id), parseInt(subId), true)
    },
    scrollMenu (operator) {
      let el = document.getElementById('scroll-list')
      let currentScroll = el.scrollTop
      if (operator === 'down') el.scrollTop = currentScroll + 120
      else el.scrollTop = currentScroll - 120
    }
  }
}
</script>
