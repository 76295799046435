<template>
  <div class="broadbands default-shadow bg-primary default-radius text-center">
    <h5 class="secondary-title">Verfügbares Breitband</h5>
    <ul class="d-flex flex-wrap justify-content-center">
      <li v-for="(item, i) in broadbands" :key="'boradbands-key-' + i">
        <button
          class="btn"
          :class="{ active: results.broadband && (results.broadband.id === item.id) }"
          v-text="item.text"
          @click="$emit('handleBroadband', item)"
        ></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    broadbands: {
      type: Array,
      required: true
    },
    results: {
      type: Object,
      required: true
    }
  }
}
</script>
