<template>
  <div>
    <div v-if="isFullscreen" class="fullScreen-wrapper px-2" @click.stop.prevent="closeFullscreen()">
      <img :src="require('@/assets/images/fullscreen-smaller.svg')" alt="">
    </div>
    <div v-else class="fullScreen-wrapper px-2" @click.stop.prevent="openFullscreen()">
      <img :src="require('@/assets/images/fullscreen-bigger.svg')" alt="">
    </div>
    <router-view />
    <div id="main-loader" :class="{ 'animate' : $store.state.slides && !$store.state.slides.loading }" v-if="$route.params.id"></div>
    <div class="ie-message" v-if="ieMessage">
      <h2>Wechseln Sie den Browser</h2>
      <p class="font-weight-light">Um das Tool optimal zu nutzen, wechseln Sie bitte auf einen der beiden Browser Google Chrome oder Microsoft Edge.</p>
      <button type="button" @click="ieMessage = false" class="btn btn-secondary font-weight-bold mt-4">WEITER</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
export default {
  name: 'App',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  data () {
    return {
      ieMessage: false,
      isFullscreen: false
    }
  },
  methods: {
    ...mapActions('slides', ['searchMainSlides']),
    openFullscreen () {
      let elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
        this.isFullscreen = true
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
        this.isFullscreen = true
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
        this.isFullscreen = true
      }
    },
    /* Close fullscreen */
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
        this.isFullscreen = false
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
        this.isFullscreen = false
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
        this.isFullscreen = false
      }
    }
  },
  created () {
    if (/Trident\/|MSIE/.test(window.navigator.userAgent)) { this.ieMessage = true }
    this.searchMainSlides()
  }
}
</script>
