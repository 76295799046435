<template>
  <div class="top-content text-white">
    <h5>Tarifempfehlung:</h5>
    <h2 v-html="data.title"></h2>
    <h6 v-text="data.details"></h6>
    <a v-if="customData && customData.header_button" v-text="customData.header_button" :href="customData.header_button_url" :target="!!(parseInt(customData.header_button_target)) && '_blank'" class="btn customButton"></a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    customData: {
      type: Object,
      default: null
    }
  }
}
</script>
