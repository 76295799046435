<template>
  <div class="vorteile-child-slide">
    <div
      class="a-slide-up-cont"
      :class="{
        'main': item.contentPosition === 'center',
        'halfTopSpace': item.contentPosition !== 'center'
      }"
    >
      <h1 v-if="item.title" v-html="item.title"></h1>
      <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
    </div>
    <div
      class="a-slide-down-cont"
      :class="[{
        'halfSpace': item.contentPosition !== 'center' && item.subTitle,
      }, 'child-type-'+item.customType]"
    >
      <ul class="listImage" v-if="item.sponsImg && item.sponsImg.length > 0 && item.customType !== 'full-box'">
        <template v-for="(itm, i) in item.sponsImg">
          <li :key="'splus'+ i" v-if="i > 0 && item.customType === 'three-box'" class="sepPlus">
            <plus></plus>
          </li>
          <li :key="'spimg' + i">
            <div>
              <img v-if="itm.img" class="sponsBy" :src="$store.state.slides.baseUrl + itm.img" alt="">
            </div>
            <span v-if="itm.title" v-html="itm.title"></span>
            <span v-if="itm.subHelper" v-html="itm.subHelper" class="secondary-title"></span>
          </li>
        </template>
      </ul>
      <div v-if="item.customType === 'full-box'" class="speed-bar-info">
        <ul >
          <li>
            <span v-html="item.net_speed.fiber.title"></span>
            <span v-html="item.net_speed.fiber.speed"></span>
          </li>
          <li>
            <span v-html="item.net_speed.copper.title"></span>
            <span v-html="item.net_speed.copper.speed"></span>
          </li>
        </ul>
        <div class="speed-bar-info-helpers">
          <span v-text="item.net_speed.content.download"></span>
          <span v-text="item.net_speed.content.upload"></span>
        </div>
        <div class="speed-bar-info-helpers bottom-helper">
          <span v-text="item.net_speed.content.teaser"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from '@/components/icons/plus'
export default {
  components: { plus },
  data () {
    return {}
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>
