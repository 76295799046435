<template>
  <button v-if="slideTitle" class="close main-close-btn btn btn-link btn-sm btn-new" @click="$router.push({ name: 'Start' })">
    <img src="@/assets/images/newLogo.svg" alt=""><span v-text="slideTitle"></span>
  </button>
</template>

<script>
/* eslint-disable */
import {mapActions, mapState} from "vuex";
import dynamicModule from "@/store/utils/dynamicModule";
import slidesStore from "@/store/modules/slides";

export default {
  props: ['title'],
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['page', 'mainSlides']),
  },
  data () {
    return {
      slideTitle: this.title
    }
  },
  methods: {
    ...mapActions('slides', ['searchMainSlides'])
  },
  mounted () {
    this.searchMainSlides().then(() => {
      let myPath = this.$route.path.split('/')
      for(let i in this.mainSlides){
        let item = this.mainSlides[i];
        if(item.name !== "" && myPath.includes(item.name)){
          this.slideTitle = item.title

          if(this.slideTitle.includes('-'))
            this.slideTitle = this.slideTitle.replace(/(<([^>]+)>)/gi, "").replace(/(?:\\[rn]|[\r\n]+)+/g, "##").replace("-##", "").replace('\t', '')
        }
      }
    })
  }
}
</script>
