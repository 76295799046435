var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.panels.infoWrapper && _vm.showHome)?_c('backToHomeButton',{attrs:{"title":_vm.backToHomeButtonText}}):_vm._e(),_c('div',{staticClass:"top-menu hybrid"},[_c('ul',[_vm._l((_vm.slides),function(item,i){return [(item.menuTitle && (item.showOnTopMenu || item.parent))?_c('li',{key:'key-menu-' + i,class:{['text-' + (item.menuColor ? item.menuColor[0] : '')]: item.menuColor && item.menuColor[0]}},[(item.showOnTopMenu)?_c('a',{class:{
              'active' : _vm.slides[_vm.currentSlide] && _vm.slides[_vm.currentSlide].menuTitle == item.menuTitle
            },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleMenu(item, i)}}},[_vm._v(_vm._s(item.menuTitle))]):_vm._e(),(_vm.dropdownMenu.show && _vm.dropdownMenu.list.length > 0 && _vm.dropdownMenu.index == i)?_c('div',{staticClass:"top-menu-sub-list"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('span',{staticClass:"menu-close-icon",on:{"click":function($event){return _vm.closeMenu()}}},[_c('img',{attrs:{"src":require('@/assets/images/cancel-white.svg'),"alt":""}})])]),_c('ul',_vm._l((_vm.dropdownMenu.list),function(ditem,index){return _c('li',{key:'sub-menu-' + index},[_c('a',{class:{'active': _vm.slides[_vm.currentSlide] && parseInt(_vm.slides[_vm.currentSlide].id) === parseInt(ditem.id)},domProps:{"textContent":_vm._s(ditem.menu_title)},on:{"click":function($event){return _vm.handleSubMenu(ditem.realIndex)}}})])}),0)]):_vm._e()]):_vm._e()]})],2)]),_c('div',{staticClass:"app-inner"},[_c('div',{staticClass:"slides-wrapper slides-wrapper-intro9 intro9 intro10 intro16-hybrid clip",class:{},on:{"click":function($event){$event.stopPropagation();return _vm.changeSlides.apply(null, arguments)}}},[_vm._l((_vm.slides),function(item,index){return [_c('div',{key:'a-slide' + index,staticClass:"a-slide",class:{
          'animate' : (_vm.animate && _vm.currentSlide == index),
          'active': _vm.currentSlide == index,
          ['a-slide-type-' + item.type]: item.type !== undefined,
          'preSlide': index < _vm.currentSlide
        },style:({ backgroundImage : 'url(' + _vm.baseUrl + item.img + ')' })},[(item && item.type != 20 && item.title)?_c('div',{staticClass:"a-slide-up-cont",class:{
              'main': item.contentPosition === 'center'
            }},[(item.title)?_c('h1',{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),(item.subTitle)?_c('h2',{domProps:{"innerHTML":_vm._s(item.subTitle)}}):_vm._e()]):_vm._e(),([7, 9].includes(item.type))?_c('div',{staticClass:"singleImageContent a-slide-down-cont",class:[{
            'halfSpace': item.contentPosition !== 'center' && item.subTitle
            }]},[(item.type === 7)?_c('ul',{staticClass:"listImage"},[_c('li',[(item.innerImg)?_c('img',{attrs:{"src":(_vm.baseUrl + item.innerImg),"alt":""}}):_vm._e()])]):_vm._e(),(item.type === 9 && item.slide_video_box.length)?_c('SlideVideos',{attrs:{"videos":item.slide_video_box,"baseUrl":_vm.baseUrl},on:{"toggleVideo":_vm.openVideo}}):_vm._e()],1):_vm._e(),(item.type === 8)?_c('div',{staticClass:"iframe-view"},[_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.currentSlide && item.iframe_url),expression:"index == currentSlide && item.iframe_url"}],attrs:{"sandbox":"allow-same-origin allow-scripts","src":item.iframe_url,"frameborder":"0"}})]):_vm._e(),(item.type === 20)?_c('marketingSlide',{attrs:{"baseUrl":_vm.baseUrl,"item":item},on:{"changeSlides":_vm.changeSlides}}):_vm._e(),_c('a',{staticClass:"continue-button text-primary continue-button-intro-10",class:{ 'visible' : index == _vm.currentSlide && item.nextButton },on:{"click":function($event){$event.preventDefault();return _vm.changeSlides.apply(null, arguments)}}},[_vm._v("weiter")])],1)]})],2)]),_c('SlideVideosPlayer',{attrs:{"slideVideo":_vm.slideVideo,"baseUrl":_vm.baseUrl},on:{"toggleVideo":_vm.openVideo}}),(_vm.current && _vm.current.video_box && _vm.current.video_box.length >= 1)?_c('videoWrapper',{attrs:{"isOpened":_vm.panels.videoWrapper,"videos":_vm.current.video_box || null},on:{"togglePanel":_vm.togglePanel}}):_vm._e(),_c('sideMenu',{attrs:{"isOpened":_vm.panels.sideMenu,"slides":_vm.slides,"currentSlide":_vm.currentSlide,"hasIntro":false,"intro":_vm.intro},on:{"togglePanel":_vm.togglePanel,"handleIntroVideo":_vm.handleIntroVideo,"changeSlides":_vm.changeSlides}}),(_vm.showInfoBox)?_c('infoWrapper',{attrs:{"isOpened":_vm.panels.infoWrapper,"tabs":_vm.infoBoxData},on:{"togglePanel":_vm.togglePanel}}):_vm._e(),_c('bottomVideos',{attrs:{"isOpened":_vm.panels.bottomVideos},on:{"togglePanel":_vm.togglePanel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }