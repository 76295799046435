<template>
    <div class="phone-template-wrapper__container phone-bg-shadow" :class="{ expanded: showDetails }">
        <div class="phone-details">
            <div class="phone-details__header">
                <h2 v-text="phone.name"></h2>
                <p v-html="`<b>Speicher:</b> ${phone.storage}`"></p>
                <p><b>Farbe:</b> {{ renderColor() }}</p>
            </div>
            <div class="phone-details__wrapper" :class="{'phone-details__wrapper--start': showDetails}">
                <SmartphoneSmall ref="phoneRef" :phone="phone" :inSlider="true" :showDots="showDetails" />
                <div class="phone-wrapper__details" v-if="showDetails">
                    <ul v-if="phone.properties && phone.properties.length">
                      <li v-for="(prop, i) in phone.properties" :key="i" v-html="`<b>${prop.key}:</b> ${prop.value}`"></li>
                    </ul>
                </div>
            </div>
        </div>
        <footer class="phone-template-wrapper__container__footer">
            <button class="back-btn" @click.stop="handleBack" v-if="!showDetails"><img :src="require('@/assets/images/intro13/back-arrow-white.svg')" /></button>
            <div class="phone-template-wrapper__container__footer__extended">
                <a v-if="showDetails && phone.download_document" :href="$store.state.slides.baseUrl + phone.download_document" target="_blank" download>
                  <img :src="require('@/assets/images/intro13/download-icon-white.svg')" />
                </a>
                <button v-if="showDetails" @click="handlePrint"><img :src="require('@/assets/images/intro13/print-icon-white.svg')" /></button>
                <button v-if="showDetails" @click="handleDetails">Zurück</button>
                <button @click="() => {!showDetails ? handleDetails() : handleCompare()}" v-text="showDetails ? 'Vergleichen' : 'Produktdetails'"></button>
            </div>
        </footer>
    </div>
</template>

<script>
import SmartphoneSmall from '@/components/smartphone-comparison/smartphone-small.vue'
export default {
  components: {
    SmartphoneSmall
  },
  props: {
    phone: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showDetails: false
    }
  },
  methods: {
    handleDetails () {
      this.showDetails = !this.showDetails
    },
    handleCompare () {
      this.$store.commit('smartphoneComparison/setActivePhone', { ...this.phone, activeColor: this.$refs?.phoneRef?.activeColor || undefined })
      this.$store.commit('smartphoneComparison/setPhonesCompare', { key: 'firstPhone', phone: { ...this.phone, activeColor: this.$refs?.phoneRef?.activeColor || undefined } })
      this.$store.commit('smartphoneComparison/setActiveView', 'comparison')
    },
    handleBack () {
      this.$store.commit('smartphoneComparison/setActiveView', this.$store.state?.smartphoneComparison?.lastView || 'category')
    },
    handlePrint () {
      this.$store.commit('smartphoneComparison/setActivePhone', this.phone)
      this.$store.commit('smartphoneComparison/setPrint', { show: true, expanded: this.showDetails })
    },
    renderColor () {
      return this.phone.images_and_colors[this.$refs?.phoneRef?.activeColor || 0]?.color_name
    }
  }
}
</script>
