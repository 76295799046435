<template>
  <div class="speedometer d-flex flex-column justify-content-center align-items-center text-white bg-primary">
    <div class="speedometer-firstInner d-flex flex-column justify-content-center align-items-center ">
      <span class="speedometer-counter-0 sc-span">0</span>
      <span class="speedometer-counter-1 sc-span">25</span>
      <span class="speedometer-counter-2 sc-span">50</span>
      <span class="speedometer-counter-3 sc-span">100</span>
      <span class="speedometer-counter-4 sc-span">250</span>
      <span class="speedometer-counter-5 sc-span">500</span>
      <span class="speedometer-counter-6 sc-span">1.000</span>
      <div class="speedometer-lastInner d-flex flex-column justify-content-center align-items-center"
      :style="{
        transform: 'rotate(' + rotate + 'deg)'
      }"></div>
    </div>
    <div class="speedometer-text">
      <div class="speedometer-text__up">
        <h1>
          <span
          id="data-download"
          :data-last="results.topics.d >= results.progress.download ? lastVals.t.d : lastVals.p.d"
          data-counters
          :data-count-target="download">0</span> MBit/s
        </h1>
        <span>download</span>
      </div>
      <div class="speedometer-text__down">
        <h2>
          <span
          id="data-upload"
          :data-last="results.topics.u >= results.progress.upload ? lastVals.t.u : lastVals.p.u"
          data-counters
          :data-count-target="upload">0</span> MBit/s
        </h2>
        <span>upload</span>
      </div>
    </div>
    <div class="radialProgress">
       <radial-progress-bar
          strokeLinecap="butt"
          :diameter="456"
          :completed-steps="progressSync"
          :total-steps="720"
          :startColor="'#fff'"
          :stopColor="'#E20074'"
          :innerStrokeColor="'#000'"
          :strokeWidth="45"
          :innerStrokeWidth="45"
          :animateSpeed="1000">
        </radial-progress-bar>
    </div>
    <div class="downCover"></div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
export default {
  data () {
    return {
      progressSync: 0,
      rotate: 0
    }
  },
  components: {
    RadialProgressBar
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    lastVals: {
      type: Object,
      required: true
    },
    upload: {
      type: Number
    },
    download: {
      type: Number
    }
  },
  computed: {},
  watch: {
    upload (ov, nv) {
      ov !== nv && setTimeout(() => {
        this.toggleUpload(1000)
      }, 100)
    },
    download (ov, nv) {
      ov !== nv && setTimeout(() => {
        this.handleRotate()
      }, 100)
    }
  },
  methods: {
    handleRotate () {
      let calcDeg = this.download
      this.progressSync = calcDeg < 1001
        ? (calcDeg < 51 && calcDeg > 0
          ? calcDeg * 3.7
          : (calcDeg > 50 && calcDeg < 101)
            ? 185 + ((calcDeg - 50) * 1.8)
            : (calcDeg > 100 && calcDeg < 251)
              ? 275 + ((calcDeg - 100) * 0.6)
              : (calcDeg > 250 && calcDeg < 501)
                ? 365 + ((calcDeg - 250) * 0.36)
                : (calcDeg > 500 && calcDeg < 1001)
                  ? 455 + ((calcDeg - 500) * 0.181)
                  : 0)
        : 720
      this.rotate = calcDeg < 1000
        ? (calcDeg < 51
          ? calcDeg * 1.8
          : (calcDeg > 50 && calcDeg < 101)
            ? 90 + ((calcDeg - 50) * 0.9)
            : (calcDeg > 100 && calcDeg < 251)
              ? 135 + ((calcDeg - 100) * 0.29)
              : (calcDeg > 250 && calcDeg < 501)
                ? 178 + ((calcDeg - 250) * 0.18)
                : (calcDeg > 500 && calcDeg < 1001)
                  ? 223 + ((calcDeg - 500) * 0.1)
                  : 270)
        : 270
      this.toggleDownload(1000)
    },
    toggleDownload (speed) {
      let item = document.getElementById('data-download')
      if (item) {
        const currentVal = parseInt(item.getAttribute('data-count-target'))
        const lastVal = parseInt(item.getAttribute('data-last'))
        this.animateCounters(item, lastVal, currentVal, speed, null)
      }
    },
    toggleUpload (speed) {
      let item = document.getElementById('data-upload')
      if (item) {
        const currentVal = parseInt(item.getAttribute('data-count-target'))
        const lastVal = parseInt(item.getAttribute('data-last'))
        this.animateCounters(item, lastVal, currentVal <= this.results.limit.upload ? currentVal : this.results.limit.upload, speed, null)
      }
    },
    animateCounters (obj, start, end, duration, append = null) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        // if (append) obj.innerHTML += '%'
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    }
  },
  mounted () {}
}
</script>
