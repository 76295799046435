<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner app-inner-intro8" v-if="!slideWithPassword">
        <div class="slides-wrapper slides-wrapper-intro8 clip" @click.stop="changeSlides" :class="{}">
            <template v-for="(item, index) in slides">
            <div :key="'a-slide' + index" class="a-slide" :class="{
                'animate' : (animate && currentSlide == index),
                'active': currentSlide == index,
                'pl-0': item.type == 20
            }" :style="{ backgroundImage : 'url(' + baseUrl + item.bgImage + ')' }">
                <div class="pl-7 ml-7 ml-xl-0 pl-xl-0 pt-8 mt-8" v-if="item.type != 20">
                  <div class="intro8-container">
                    <div class="row pb-8 align-items-center justify-content-between">
                      <div class="col-8">
                          <div class="h3" v-html="item.subtitle"></div>
                          <h1 v-html="item.title"></h1>
                      </div>
                      <div class="col-4 text-center">
                          <img class="img-fluid magenta-icon" :src="baseUrl + item.icon" alt="">
                      </div>
                      </div>
                      <div class="row">
                      <div class="col-6" v-for="(list, j) in item.items" :key="'a-slide-items-' + j">
                          <div class="h3 mb-6 text-uppercase font-weight-bold">{{ list.title }}</div>
                          <ul class="p-0 m-0">
                            <li class="mb-5" v-for="(innerList, k) in list.innerItems" :key="'a-slide-items-inner' + k">
                                <span class="a-icon">
                                  <img :src="baseUrl + innerList.icon" alt="">
                                </span>
                                <span class="a-text-inner">
                                  {{ innerList.text }}
                                </span>
                            </li>
                          </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <marketingSlide @changeSlides="changeSlides" v-if="item.type === 20" :baseUrl="baseUrl" :item="item"></marketingSlide>
              </div>
            </template>

            <!-- ./src because didn't work somehow with built files -->
        </div>
    </div>

    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="false"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import backToHomeButton from '@/components/backToHomeButton'
import PasswordModal from '@/components/passwordModal'
import videoWrapper from '@/components/videoWrapper'
import marketingSlide from '../../components/marketingSlide'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  watch: {
    current (nv, val) {
      this.initEvents(nv && nv.innerEvents === undefined)
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        this.handleInfoBox(this.current?.info_page || null)
        this.handleVideoBox(this.current?.video_box || null)
        // switch (nv) {
        //   case 0:
        //     this.handleInfoBox(this.introInfoPage)
        //     break
        //   default:
        //     break
        // }
      }
    }
  },
  data () {
    return {
      slideWithPassword: null,
      currentSlide: 1,
      animate: true,
      showLoader: false,
      backToHomeButtonText: 'Title',
      current: null,
      showHome: true,
      showIntro: false,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: false,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      videoBoxData: null,
      showVideoBox: false
    }
  },
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    videoWrapper,
    marketingSlide
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      // this.showIntro = true
      this.currentSlide = 0
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      if (this.isAnimating) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      this.isAnimating = true
      this.showLoader = true
      this.animate = true
      setTimeout(() => {
        this.showLoader = false
        // debugger
        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length)) this.currentSlide = 1
        else if (this.currentSlide === 0) this.currentSlide = Object.keys(this.slides).length

        this.current = this.slides[this.currentSlide]

        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
        }, 700)
      }, 700)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleVideoBox (data) {
      this.videoBoxData = data || null
      this.showVideoBox = data?.length > 0
    }
  },
  created () {
    this.showIntro = false
  },
  mounted () {
    var routeID = this.$route.params?.id
    const slideID = this.$route.params?.slideId
    this.searchSlides(7).then(() => {
      // this.currentSlide = this.intro ? 0 : 1
      //   this.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        // Object.keys(this.slides).forEach((key) => {
        //   let b = new Image()
        //   b.src = this.slides[key].bgImage
        //   this.imageCache.push(b)
        // })

        this.initEvents(true)
        this.hideIntro()
      })
      if (routeID) {
        let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
        if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
      }
      if (routeID && this.slides[routeID]) {
        this.showLoader = true
        this.animate = this.isAnimating = false
        setTimeout(() => {
          this.changeSlides(parseInt(routeID))
          if (slideID) {
            setTimeout(() => {
              if (document.querySelector('.swiper-container')) { document.querySelector('.swiper-container').swiper.slideTo(slideID) }
            }, 1500)
          }
        }, 300)
      }
    })

    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
