<template>
    <div class="phones-print-wrapper">
        <button class="phones-print-wrapper__back noPrint" @click="handleBack">
            <img :src="require('@/assets/images/intro13/back-arrow-black.svg')" />
        </button>
        <div class="printLogo">
            <img :src="require('@/assets/images/telekom.jpg')" />
        </div>
        <button class="phones-print-wrapper__print noPrint" @click.prevent="handlePrint">
            <img :src="require('@/assets/images/intro13/print-icon-black.svg')" />
        </button>
        <div class="phones-print-wrapper__printable phone-detail">
            <table v-if="activeView === 'comparison' && (firstPhone || secondPhone)">
                <tr>
                    <th></th>
                    <th>
                        <div class="phone-box" v-if="firstPhone">
                            <div class="phone-wrapper">
                                <div class="phone-wrapper__image" :class="{'phone-wrapper__image--small': print.expanded}">
                                    <img :src="fullImage(firstPhone.images_and_colors[firstPhone.activeColor !== undefined && firstPhone.activeColor > -1 ? firstPhone.activeColor : 0])" />
                                </div>
                                <div class="phone-wrapper__title">
                                <h4 v-text="firstPhone ? firstPhone.name : ''"></h4>
                                <p>Speicher {{ firstPhone ? firstPhone.storage : '' }}</p>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div v-if="secondPhone" class="phone-box">
                            <div class="phone-wrapper">
                                <div class="phone-wrapper__image" :class="{'phone-wrapper__image--small': print.expanded}">
                                    <img :src="fullImage(secondPhone.images_and_colors[secondPhone.activeColor !== undefined && secondPhone.activeColor > -1 ? secondPhone.activeColor : 0])" />
                                </div>
                                <div class="phone-wrapper__title">
                                    <h4 v-text="secondPhone.name"></h4>
                                    <p>Speicher {{ secondPhone.storage }}</p>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr v-for="(prop, i) in phoneKeys" :key="'phone-pr-' + i">
                    <td class="pr-1"><b>{{prop.key}}:</b></td>
                    <td v-text="firstPhone && firstPhone.properties && firstPhone.properties[i] ? firstPhone.properties[i].value : ''" class="px-1"></td>
                    <td v-text="secondPhone && secondPhone.properties && secondPhone.properties[i] ? secondPhone.properties[i].value : ''" class="px-1"></td>
                </tr>
            </table>
            <div v-if="activeView === 'slider'" class="phone">
                <div class="phone__header">
                    <h2 v-text="activePhone.name"></h2>
                    <p>Speicher {{ activePhone.storage }}</p>
                </div>
                <img v-if="activePhone" :src="fullImage(activePhone.images_and_colors[activePhone.activeColor !== undefined && activePhone.activeColor > -1 ? activePhone.activeColor : 0])" />
            </div>
            <div v-if="activeView === 'slider'" class="details">
                <ul v-if="activePhone.properties && activePhone.properties.length">
                    <li v-for="(prop, i) in activePhone.properties" :key="'prop-acph-' + i">
                        <b>{{ prop.key }}:</b> {{ prop.value }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('smartphoneComparison', ['firstPhone', 'secondPhone', 'activePhone', 'activeView', 'print', 'phones']),
    phoneKeys () {
    //   return []
      return this.phones?.length ? (this.print.expanded ? this.phones[0].properties : this.phones[0].properties?.slice(0, 4)) : []
    }
  },
  methods: {
    handleBack () {
      this.$store.commit('smartphoneComparison/setPrint', { show: false, expaned: false })
    },
    fullImage (data) {
      return this.$store.state?.slides?.baseUrl && data?.image ? this.$store.state.slides.baseUrl + data.image : require('@/assets/images/intro13/xioami-14-ultra.png')
    },
    handlePrint () {
      window.print()
    }
  }
}
</script>
