<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner">
      <div class="slides-wrapper slides-wrapper-intro9 clip" @click.stop="changeSlides" :class="{}">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index,
            ['a-slide-type-' + item.type] : !item.hasBg,
            'downBackground': item.type === 1 && item.switch,
            'has-inner-icons': item.innerIcons,
            'show-arrows-of-slider': isSliderActive,
            'preSlide': index < currentSlide
          }" :style="{ backgroundImage : 'url(' + require('@/assets/images/intro9/' + item.img) + ')' }">
            <div v-if="(item.type === 2 || item.type === 4) && !item.hasMenu && !item.hasBg && !item.innerSlider">
              <img class="img-center" :src="require('@/assets/images/intro9/' + item.img)" alt="">
            </div>
            <div v-if="item.innerSlider" :class="{ 'last-slide-slider-active' : isSliderActive }" class="last-slide-slider">
              <div class="swiper-wrapper" :class="{ 'last-slide-slider-active' : isSliderActive }">
                <swiper :options="sliderOptions">
                  <swiper-slide v-for="(innerSliderItem, isIndex) in item.innerSlider"
                                :key="'swiper-image-item' + isIndex">
                    <div class="last-slide-slider-inner">
                      <span class="title mb-5">{{ innerSliderItem.title }}</span>
                      <img class="i9-digital-checkout__image" :src="require('@/assets/images/intro9/' + innerSliderItem.img)"/>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="swiper-button-prev-u btn"><img :src="require('@/assets/images/intro9/arrow-left-sl.svg')" alt=""></div>
              <div class="swiper-button-next-u btn"><img :src="require('@/assets/images/intro9/arrow-right-sl.svg')" alt=""></div>
            </div>
            <div class="i9-navigation__type-3" v-if="item.type === 2 && item.hasMenu">
              <template v-for="(item, index) in slides">
                <div @click.stop="changeSlides(index)" class="item" v-if="item.type === 3" :key="'a-inner-slide' + index">
                  <div class="img-rounded" :style="{ backgroundImage: 'url(' + require('@/assets/images/intro9/' + item.img) + ')' }"></div>
                  <h4>{{ item.menuTitle }}</h4>
                </div>
              </template>
            </div>
            <div class="i9-navigation__type-4" v-if="item.type === 4 && item.hasMenu">
              <template v-for="(item, index) in slides">
                <div @click.stop="changeSlides(index)" class="item" v-if="item.type === 5" :key="'a-inner-slide' + index">
                  <h4 v-if="!item.menuTextDown">{{ item.menuTitle }}</h4>
                  <div class="icon-rounded">
                    <img :src="require('@/assets/images/intro9/icon-' + item.icon)" alt="">
                  </div>
                  <h4 v-if="item.menuTextDown">{{ item.menuTitle }}</h4>
                </div>
              </template>
            </div>
            <div v-if="item.type === 2 || item.type === 4"
                 :class="[
                    item.contentPosition,
                    {
                      'animate' : (!showLoader && !animate && currentSlide == index),
                      'splashType-2': item.type === 2 || item.type === 4,
                      'has-inner-icons': item.innerIcons,
                      'is-inactive' : isSliderActive
                    }
                  ]"
                 :key="'a-slide-content' + index" class="h1"
            >
              <div class="disruptor" v-if="item.disruptor">
                {{ item.disruptor }}
              </div>
              <span v-if="item.title && !item.innerIcons" v-html="item.title"></span>
              <button class="btn" v-if="item.hasShowMoreButton" @click.prevent.stop="digitalCheckout = true">
                Mehr erfahren
              </button>
              <div class="inner-icons" v-if="item.innerIcons">
                <template v-for="(icon, ind) in item.innerIcons">
                  <div class="in-icon" :key="'inner-icon' + ind">
                    <img :src="require('@/assets/images/intro9/' + icon.icon)" alt="">
                    <span v-html="icon.title">
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div id="i9-splash" v-if="current && current.splashPosition">
          <div class="inner"
               :class="{
                          [current && current.splashPosition] : current && current.splashPosition,
                          ['is-visible'] : showSplash
                        }">
            <div class="i9-circle h1" v-html="current.title">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i9-navigation" v-if="current && current.type === 3" :class="{ 'minimized' : currentSlide > 0 }">
      <ul>
        <li class="active-bg" v-if="current.type === 3" :style="{ left: (currentSlide - 2) * 220 + 'px' }"></li>
        <template v-for="(item, index) in slides">
          <li v-if="item.showOnMenu && item.type === 3" @click.stop="changeSlides(index, 0)"
              :class="{ 'active' : currentSlide == index }" :key="'slide-' + index">
            {{ item.menuTitle }}
          </li>
        </template>
      </ul>
    </div>
    <div class="i9-navigation i9-navigation__type-5" v-if="current && current.type === 5"
         :class="{ 'minimized' : currentSlide > 0 }">
      <ul>
        <template v-for="(item, index) in slides">
          <li v-if="item.showOnMenu && item.type === 5" @click.stop="changeSlides(index, 0)"
              :class="{ 'bg-primary' : currentSlide == index }" :key="'slide-' + index">
            <img :src="require('@/assets/images/intro9/icon-' + item.icon)" alt="">
          </li>
        </template>
      </ul>
    </div>
    <div v-if="slideVideo" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="slideVideo = false" class="close btn btn-link btn-sm z-i-1"><img
          src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="slideVideo"></video>
      </div>
    </div>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <div class="i9-custom-popup">
      <i9-digital-checkout
        v-on:togglePanel="digitalCheckout = false"
        v-if="current && current.popupContent && digitalCheckout"
        :slides="current && current.popupContent"
      >
      </i9-digital-checkout>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import backToHomeButton from '@/components/backToHomeButton'
import I9DigitalCheckout from '@/components/i9-digital-checkout'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['baseUrl', 'intro', 'introInfoPage'])
  },
  watch: {
    current (nv, val) {
      this.initEvents(nv && nv.innerEvents === undefined)
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage || null)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    }
  },
  data () {
    return {
      currentSlide: 0,
      animate: true,
      showLoader: false,
      digitalCheckout: false,
      backToHomeButtonText: 'Title',
      slides: [
        {
          type: 2,
          title: 'Mehr als eine Kasse <br> MagentaBusiness POS',
          contentPosition: 'bottom',
          img: '1.png'
        },
        {
          type: 2,
          hasMenu: true,
          title: 'Maßgeschneidert <br> für Ihr Geschäft',
          contentPosition: 'top',
          img: '1.png'
        },
        {
          type: 3,
          title: 'Ideal für jede <br> Verkaufssituation',
          img: '3.jpg',
          showOnMenu: true,
          menuTitle: 'vielseitig',
          splashPosition: 'top-left'
        },
        {
          type: 3,
          title: 'Sofort startklar – <br> mit vorinstallierter Kassensoftware',
          img: '4.jpg',
          showOnMenu: true,
          menuTitle: 'einfach',
          splashPosition: 'bottom-right'
        },
        {
          type: 3,
          title: 'Geschäftszahlen <br> immer im Blick',
          img: '5.jpg',
          showOnMenu: true,
          menuTitle: 'flexibel',
          splashPosition: 'bottom-left'
        },
        {
          type: 3,
          title: 'Automatische Updates und perfekter Service',
          img: '6.jpg',
          showOnMenu: true,
          menuTitle: 'sicher',
          splashPosition: 'bottom-right'
        },
        {
          type: 4,
          title: 'Eine Kasse, <br> viele Möglichkeiten',
          hasMenu: true,
          img: '2.jpg',
          contentPosition: 'top'
        },
        // newwwwwwwwwwwwwwwwwwwwwwwwwwwwwww
        {
          type: 5,
          title: 'Reservierungen <br> mühelos verwalten',
          img: '8.jpg',
          showOnMenu: true,
          menuTitle: 'Reservierungen',
          splashPosition: 'bottom-right',
          icon: '1.svg'
        },
        {
          type: 5,
          title: 'Verstehen, was <br> Kunden wünschen',
          img: '9.jpg',
          showOnMenu: true,
          menuTitle: 'Kundenverwaltung',
          splashPosition: 'top-right',
          icon: '2.svg'
        },
        {
          type: 5,
          title: 'So einfach war Personalplanung noch nie',
          img: '10.jpg',
          showOnMenu: true,
          menuTitle: 'Personalplanung',
          splashPosition: 'top-left',
          icon: '3.svg'
        },
        {
          type: 5,
          title: 'Volle Kontrolle über <br> alle Produkte',
          img: '11.jpg',
          showOnMenu: true,
          menuTitle: 'sicher',
          splashPosition: 'bottom-left',
          icon: '4.svg',
          menuTextDown: true
        },
        {
          type: 5,
          title: 'Produkte online verkaufen',
          img: '12.jpg',
          showOnMenu: true,
          menuTitle: 'Inventarverwaltung',
          splashPosition: 'bottom-right',
          icon: '5.svg',
          menuTextDown: true
        },
        {
          type: 5,
          title: 'Flexibel abrechnen – in bar oder kontaktlos ',
          img: '13.jpg',
          showOnMenu: true,
          menuTitle: 'Online-Store',
          splashPosition: 'bottom-left',
          icon: '6.svg',
          menuTextDown: true
        },
        {
          type: 2,
          hasBg: true,
          contentPosition: 'bottom',
          img: '14.jpg',
          title: 'Die digitale Kasse <br> macht alles einfacher ',
          hasShowMoreButton: true,
          disruptor: 'Beispiel \n' +
            'Bistro',
          popupContent: [
            {
              title: 'So geht \n' +
                'toller Service',
              img: 'dc-1.jpg',
              splashPosition: 'top-right'
            },
            {
              title: 'Eine funktionierende \n' +
                'Küche schmeckt \n' +
                'noch besser',
              img: 'dc-2.jpg',
              splashPosition: 'bottom-right'
            },
            {
              title: ' Abrechnung vom Feinsten \n' +
                '– einfach und flexibel',
              img: 'dc-3.jpg',
              splashPosition: 'bottom-left'
            },
            {
              title: 'Buchhaltung war \n' +
                'noch nie so einfach',
              img: 'dc-4.jpg',
              splashPosition: 'top-left'
            }
          ]
        },
        {
          type: 2,
          title: 'Mehr als eine Kasse <br> MagentaBusiness POS',
          contentPosition: 'bottom',
          img: '1.png',
          innerSlider: [
            {
              title: 'Dasher-Bundle',
              img: 'inner-slide-1.png'
            },
            {
              title: 'Comet-Bundle',
              img: 'inner-slide-2.png'
            },
            {
              title: 'Donner',
              img: 'inner-slide-3.png'
            }
          ],
          innerIcons: [
            {
              icon: 'sl-1.svg',
              title: 'Cloud und <br/> Software'
            },
            {
              icon: 'sl-2.svg',
              title: 'Hard- und Software-\n' +
                'Support'
            },
            {
              icon: 'sl-3.svg',
              title: 'Mobilfunknetz\n' +
                'und WLAN'
            }
          ]
        }
      ],
      current: null,
      showIntro: false,
      showHome: true,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: true,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      isSliderActive: false,
      showSplash: false,
      sliderOptions: {
        effect: 'slide',
        grabCursor: true,
        slidesPerView: 1,
        centerSlides: true,
        centeredSlidesBounds: true,
        infinite: true,
        navigation: {
          nextEl: '.swiper-button-next-u',
          prevEl: '.swiper-button-prev-u'
        },
        loop: true
      }
    }
  },
  components: {
    I9DigitalCheckout,
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    Swiper,
    SwiperSlide
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      this.showIntro = true
      this.currentSlide = 0
      // this.current = null
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      if (this.isAnimating || this.isSliderActive) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      if (this.current && this.current.innerSlider && !this.isSliderActive) {
        this.isSliderActive = true
        return
      }

      this.isAnimating = true
      this.showLoader = true
      this.animate = true

      if (this.slides[this.currentSlide + 1].splashPosition) {
        setTimeout(() => { this.showSplash = true }, 1000)
      } else { this.showSplash = false }

      setTimeout(() => {
        this.showLoader = false
        this.showIntro = false
        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
        this.current = this.currentSlide === 0 ? null : this.slides[this.currentSlide]

        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
          if (this.$route.params?.id) {
            this.addLoading(false)
          }
        }, 700)
      }, 700)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    }
  },
  mounted () {
    let self = this
    let routeID = this.$route.params?.id && parseInt(this.$route.params.id)
    const slideID = this.$route.params?.slideId
    this.searchSlides(6).then(() => {
      self.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = self.slides[key].img
          self.imageCache.push(b)
        })
        if (self.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showLoader = true
          this.animate = this.isAnimating = false
          setTimeout(() => {
            this.changeSlides(routeID)
            if (slideID) {
              setTimeout(() => {
                if (document.querySelector('.swiper-container')) {
                  document.querySelector('.swiper-container').swiper.slideTo(slideID)
                }
              }, 1500)
            }
          }, 300)
        }
        self.initEvents(true)
      })
    })
    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
