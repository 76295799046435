<template>
  <div class="onlineActivities bg-primary default-shadow default-radius text-center">
    <h5 class="secondary-title">Welche und wie viele<br/>
      Online-Aktivitäten werden bei Ihnen<br/>
      zuhause gleichzeitig durchgeführt?</h5>
    <ul class="d-flex flex-column">
      <li v-for="(item, i) in activities" :key="'oa-key-' + i">
        <span class="itemIcon">
          <img :src="baseUrl + item.icon" alt="">
        </span>
        <div class="tools">
          <span class="toolTitle" v-text="item.text"></span>
          <button
            class="btn btn-tool minus"
            :disabled="item.qty < 1"
            @click="handleActivity(i, '-')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="5.441" height="2.2" viewBox="0 0 5.441 2.2">
              <rect id="Rectangle_989" data-name="Rectangle 989" width="5.441" height="2.2"
                    :fill="'#' + (item.qty < 1 ? 'ffffff' : 'e20074')"/>
            </svg>
          </button>
          <span class="counter" v-text="item.qty"></span>
          <button
            class="btn btn-tool minus"
            :disabled="item.qty > 98"
            @click="handleActivity(i, '+')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="9.162" height="9.161" viewBox="0 0 9.162 9.161">
              <path id="Path_773" data-name="Path 773"
                    d="M264.776,170.994H261.3v3.48h-2.2v-3.48h-3.481v-2.2H259.1v-3.48h2.2v3.48h3.481Z"
                    transform="translate(-255.614 -165.313)"
                    :fill="'#' + (item.qty > 98 ? 'ffffff' : 'e20074')"/>
            </svg>
          </button>
        </div>
        <div class="tooltip-placeholder">
          <button class="btn icon" @click.stop="toggleTooltip('oactivities-' + i)" @blur="toggleTooltip('')">
            <svg id="i_38_1" data-name="i 38 – 1" xmlns="http://www.w3.org/2000/svg" width="46" height="46"
                 viewBox="0 0 46 46">
              <path id="Path_945" data-name="Path 945"
                    d="M331.491,618.758a21.85,21.85,0,1,1-21.85,21.85,21.874,21.874,0,0,1,21.85-21.85m0-1.15a23,23,0,1,0,23,23,23,23,0,0,0-23-23"
                    transform="translate(-308.491 -617.608)" fill="#fff"/>
              <path id="Path_946" data-name="Path 946"
                    d="M330.358,627.251H328.11v3h2.248Zm2.577,19.484h-2.623V633.246h-4.871v2.248h2.623v11.241h-2.623v2.248h7.494Z"
                    transform="translate(-306.363 -616.117)" fill="#fff"/>
            </svg>
          </button>
        </div>
        <div v-if="tooltip === 'oactivities-' + i" v-html="item.tooltip" class="tooltip-holder" :class="[item.tooltip_position]"></div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    activities: {
      type: Array,
      required: true
    },
    results: {
      type: Object,
      required: true
    },
    tooltip: {
      type: String,
      default: ''
    },
    baseUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tooltips: []
    }
  },
  methods: {
    handleActivity (i, op) {
      this.$emit('handleActivity', i, op)
    },
    toggleTooltip (item) {
      this.$emit('handleTooltip', item)
      // const tIndex = this.tooltips.indexOf(item)
      // tIndex === -1 ? this.tooltips.push(item) : this.tooltips.splice(tIndex, 1)
    }
  }
}
</script>
