<template>
  <div class="infoWrapperWrap outerLinkWrapper">
    <div id="info-wrapper">
      <a :href="data.button_action" target="_blank" class="btn btn-light btn-lg toggle">
        <img :src="baseUrl + data.button_image" alt="">
        <span class="title" v-text="data.button_text"></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: null
    },
    baseUrl: {
      default: ''
    }
  },
  data () {
    return {}
  },
  components: {
  },
  watch: {
  },
  methods: {
  },
  mounted () {}
}
</script>
