<template>
    <div class="a-slide-content">
      <!-- <div class="a-slide-content-wrapp" @click.self="$emit('handleChange')"> -->
      <div class="a-slide-content-wrapp" @click.stop="triggerChange">
        <div class="a-slide-content-wrapp-title" v-text="item.title"></div>
        <div class="a-slide-content-wrapp-container">
          <div class="firstSlider halfSlides tariffe-slider">
            <div class="tariffe-badge" v-if="showBadge">
              <span v-text="item.badge_text"></span>
            </div>
            <swiper ref="tariffeSlider" :options="sliderOptions">
              <template v-for="(subItem, i) in item.packages">
                <template v-if="subItem[activeKey] && (!activeDiscount || (activeDiscount && i !== 'xl'))">
                  <swiper-slide :key="'swiper-i-4-' + i">
                    <div class="tariffe-inner-slide">
                      <div class="tariffe-slider--badge" v-if="subItem[activeKey].show_badge == 1">Empfehlung</div>
                      <div class="slide-title" :class="{ 'small': subItem[activeKey] && subItem[activeKey].title && subItem[activeKey].title.length > 5 }" v-text="subItem[activeKey].title || i"></div>
                      <div class="slide-subtitle mb-0 font-weight-bold" v-text="subItem[activeKey].donwload_speed || '-'"></div>
                      <div v-if="subItem[activeKey].upload_speed" class="slide-subtitle small" v-text="subItem[activeKey].upload_speed || '-'"></div>
                      <ul class="slide-list-cont" v-if="subItem[activeKey] && subItem[activeKey].content_list && subItem[activeKey].content_list.length">
                        <li v-for="(ssItem, ind) in subItem[activeKey].content_list" :key="'li-key-'+ind" v-text="ssItem.title" :class="{
                        'text-primary font-weight-bold' : ssItem.text_purple == 1,
                        'show--tick' : ssItem.show_tick == 1
                      }"></li>
                      </ul>
                      <div class="slide-info" v-if="subItem[activeKey].info" v-text="subItem[activeKey].info"></div>
                      <div class="slide-price-wrapper">
                        <div class="slide-price" :class="{ 'helper': subItem[activeKey].price_helper }">
                          {{ filterPrice(subItem[activeKey].price) + ' €' }}<br/>
                          <small v-if="subItem[activeKey].price_helper" v-text="subItem[activeKey].price_helper"></small>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </template>
              </template>
              <div class="swiper-pagination custom-pag-6" id="pagination-type-four" slot="pagination"></div>
              <div class="swiper-button-prev custom-pag-6" id="type-four-prev" slot="button-prev"></div>
              <div class="swiper-button-next custom-pag-6" id="type-four-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="a-slide-content-wrapp-filters">
            <!-- <div class="a-slide-content-wrapp-badge">
              Router Gutschrift <br/> 70 €
            </div> -->
            <ul>
              <template v-for="(itm, i) in filters">
                <li :key="'slide-4-filters-' + i" v-if="item[itm.code] && item[itm.code] != '\r\n'">
                  <div class="checkpanel">
                    <div class="rowInput" :class="{ active: itm.active }">
                      <label class="toggleCheckbox medium">
                        <input v-if="!itm.discount" :checked="itm.active" :name="itm.code" @input.stop="handleInput(itm, i)" type="checkbox" class="hiddenInput"/>
                        <input v-else :checked="itm.active" :name="itm.code" @input.stop="handleDiscountInput(itm, i)" type="checkbox" class="hiddenInput"/>
                        <span>
                          <Toggle :checked="itm.active" />
                        </span>
                      </label>
                      <p class="active" v-text="itm.title"></p>
                      <div class="tooltip-placeholder">
                        <button class="btn icon" :class="'button-m-' + item.id + '-' + itm.id" @click.stop="handleTooltip('button-m-'+ item.id + '-' + itm.id)" @blur="handleTooltip(null)">
                          <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                        </button>
                      </div>
                      <div v-if="tooltip && tooltip === ('button-m-' + item.id + '-' + itm.id)" class="tooltip-holder right" v-html="item[itm.code]"></div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div class="a-slide-content-wrapp-links" v-if="item.slide_links && item.slide_links.length">
              <div class="d-flex flex-column" v-for="(link, i) in item.slide_links" :key="'sl_lnk-' + i">
                <a :href="link.link" v-text="link.title" target="_blank" class="btn"></a>
              </div>
              <div class="sticky_note_wrapper" v-html="item.slide_sticky_note"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Toggle from '@/components/icons/toggle'
export default {
  components: {
    Swiper,
    SwiperSlide,
    Toggle
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    filterId: {
      default: null
    }
    // filters: {
    //   type: [Array],
    //   default: () => ([])
    // },
    // combinations: {
    //   type: [Array],
    //   default: () => ([])
    // }
  },
  watch: {
    activeItem: {
      handler (val) {
        this.filteredPackages()
      },
      immediate: true
    }
  },
  computed: {
    swiper () {
      return this.$refs?.tariffeSlider?.$swiper
    },
    activeKey () {
      return this.activeItem.includes('_') ? this.activeItem.split('_').join('') : this.activeItem
    }
  },
  data () {
    return {
      showBadge: false,
      activeItem: '0',
      defSlides: [],
      activeCategory: 1,
      filters: [
        {
          id: 1,
          title: 'Vario',
          code: 'vario',
          active: false,
          dependencies: null,
          combo: null
        },
        {
          id: 2,
          title: 'Preisvorteil 15%',
          code: 'preisvorteil_15',
          active: false,
          dependencies: null,
          combo: ['0_2'],
          // not_unique: false,
          discount: 15
        },
        {
          id: 3,
          title: 'Preisvorteil 20%',
          code: 'preisvorteil_20',
          active: false,
          dependencies: null,
          combo: ['0_3'],
          // not_unique: false,
          discount: 20
        }
      ],
      activeFilter: null,
      activeFilters: [],
      combinations: [
        '0_2',
        '0_3'
      ],
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next#type-four-next',
          prevEl: '.swiper-button-prev#type-four-prev'
        },
        pagination: {
          el: '.swiper-pagination#pagination-type-four',
          clickable: true
        }
      },
      tooltip: null,
      activeDiscount: false
    }
  },
  methods: {
    checkForBadge (active) {
      if (this.item) this.showBadge = this.item.show_badge && this.item.show_badge.indexOf(active) > -1
    },
    handleActiveSlides (id) {
      this.activeCategory = id
      this.swiper.slideTo(0)
      this.swiper.update()
    },
    handleDiscountInput (filter, index) {
      if (filter.active) {
        filter.active = false
        this.activeDiscount = false
        return
      }
      if (this.activeItem === '0' || !this.activeDiscount) {
        this.activeItem = '0'
        this.activeDiscount = filter.discount
      }

      this.filters.forEach(flt => {
        if (flt.discount !== undefined) {
          flt.active = filter.id === flt.id
        } else {
          flt.active = false
        }
      })
    },
    handleInput (filter, index) {
      this.filters[index].active = !filter.active
      this.activeDiscount = filter.discount || false
      if (filter.active) {
      //   // Checkbox is checked
        this.activeFilters.push(filter.id)
        this.activeFilters.sort()
        // case combo NULL
        if (!filter.combo || (this.activeItem.includes('0') && !filter.not_unique)) {
          this.activeItem = filter.id.toString()
          this.activeFilters = [filter.id]
          this.resetFilters()
          return
        }
        this.checkItem(filter)
      } else {
        // Checkbox is unchecked
        const index = this.activeFilters.indexOf(filter.id)
        if (index !== -1) {
          this.$delete(this.activeFilters, index)
          if (!this.activeFilters.length) {
            this.activeItem = '0'
          } else {
            this.checkItem(this.filters.find(flt => flt.id === this.activeFilters[0]))
          }
        }
      }
    },
    checkItem (filter) {
      const comboExists = this.combinations.find(combo => combo === this.activeFilters.join('_'))
      if (comboExists) {
        const innerComboExists = filter.combo.includes(comboExists)
        if (innerComboExists) {
          this.setActiveItemAndFilters(comboExists)
        } else {
          this.setActiveItemAndFilters(filter.combo[0])
        }
      } else {
        if (!filter.not_unique) {
          this.setActiveItemAndFilters(filter.id.toString())
        } else {
          this.setActiveItemAndFilters(filter.combo[0])
        }
      }
      this.resetFilters()
    },
    setActiveItemAndFilters (combo) {
      this.activeItem = combo
      this.activeFilters = combo.split('_').map(combo => parseInt(combo))
    },
    handleTooltip (type = null) {
      this.tooltip = type
      if (this.tooltip) {
        let itemClass = document.querySelector('.' + this.tooltip)
        itemClass.focus()
      }
    },
    filteredPackages () {
      let startIndex = null
      let items = -1
      for (let itm in this.item.packages) {
        let item = this.item.packages[itm][this.activeKey]
        if (item) {
          items++
          if (startIndex === null && item.show_first === '1') {
            startIndex = items
          }
        }
      }
      this.$nextTick(() => {
        this.swiper && this.swiper.slideTo(startIndex || 0)
        this.swiper && setTimeout(() => {
          this.swiper.update()
        }, 800)
      })
    },
    resetFilters () {
      this.activeDiscount = false
      for (let i = 0; i < this.filters.length; i++) {
        this.$set(this.filters[i], 'active', this.activeFilters.includes(this.filters[i].id))
      }
    },
    triggerChange (e) {
      if (e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'SPAN' && (!e.target.id || !['type-four-next', 'type-four-prev'].includes(e.target.id))) {
        return false
      }
    },
    filterPrice (val) {
      const newVal = parseFloat(val.replace(',', '.'))
      let result = newVal.toFixed(2).replace('.', ',')
      if (this.activeDiscount !== false) {
        result = newVal - (newVal * (this.activeDiscount / 100))
        result = result?.toFixed(2).toString().replace('.', ',')
      }
      return result
    }
  },
  mounted () {
    this.filteredPackages()
  }
}
</script>
