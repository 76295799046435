<template>
  <div>
    <div class="custom-popup-backdrop"></div>
    <div class="custom-popup pb-md-8 pt-md-6 fade-in-bottom">
      <div class="position-relative z-i-5">
        <button @click="close()" class="close btn btn-link btn-sm"><img :src="require('@/assets/images/cancel.svg')"
                                                                        alt=""></button>
      </div>
      <div class="i9-digital-checkout" @click="changeInnerSlide(currentSlide + 1)">
        <div class="i9-digital-checkout__inner">
          <swiper :options="sliderOptions">
            <swiper-slide v-for="(item, index) in slides"
                          :key="'swiper-image-item' + index">
              <div class="swiper-slide-inner-i9" :class="[item.splashPosition]">
                <img class="i9-digital-checkout__image" :src="$store.state.slides.baseUrl + item.img"/>
                <div id="i9-splash" >
                  <div class="inner"
                       :class="{ [item && item.splashPosition] : item && item.splashPosition }">
                    <div class="i9-circle h1">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination-i9"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['isOpened', 'slides'],
  mounted () {
    this.current = this.slides[1]
  },
  data () {
    return {
      currentSlide: 0,
      current: null,
      sliderOptions: {
        effect: 'slide',
        grabCursor: true,
        slidesPerView: 1,
        centerSlides: true,
        centeredSlidesBounds: true,
        infinite: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination-i9',
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="dot btn btn-link swiper-pagination-bullet"><span class="inner-dot">${index + 1}</span></span>`
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    changeInnerSlide (id) {
      this.current = this.slides[id]
      this.currentSlide = id
    },
    close () {
      this.$emit('togglePanel', 'digitalCheckout', false)
    }
  }
}
</script>
