<template>
    <i>
      <svg v-if="active" xmlns="http://www.w3.org/2000/svg" width="11.339" height="8" viewBox="0 0 11.339 8">
          <g id="icon_action_success_default" data-name="icon/action/success/default" transform="translate(-1.825 -4.825)">
          <path id="Combined-Shape" d="M13.041,4.948a.4.4,0,0,0-.572,0L5.736,11.68,2.52,8.464a.4.4,0,0,0-.572.572l3.789,3.789,7.3-7.3A.4.4,0,0,0,13.041,4.948Z" fill="#e20074" fill-rule="evenodd"/>
          </g>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
          <g id="icon_action_close_default" data-name="icon/action/close/default" transform="translate(-5.975 -5.975)">
          <path id="Combined-Shape" d="M10.672,9.975l3.154-3.154a.493.493,0,0,0-.7-.7L9.975,9.278,6.821,6.124a.493.493,0,0,0-.7.7L9.278,9.975,6.124,13.129a.493.493,0,0,0,.7.7l3.154-3.154,3.154,3.154a.493.493,0,0,0,.7-.7Z" fill="#fff" fill-rule="evenodd"/>
          </g>
      </svg>
    </i>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
