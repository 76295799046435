<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner" v-if="!slideWithPassword">
      <div class="slides-wrapper slides-wrapper-intro5 clip" @click.stop="changeSlides" :class="{
        [current && current.splashPosition] : current && current.splashPosition,
        [current && current.align] : current && current.align,
        'start-page': current && current.intro !== undefined,
        'child-item': current && (current.innerSlides === undefined),
        'parent-item': current && current.innerSlides
      }">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" v-if="currentSlide == index" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index
          }" :style="{ backgroundImage : current && 'url(' + baseUrl + current.img + ')' }">
          </div>
          <div v-if="currentSlide == index && done"
               :class="{ 'animate' : (!isAnimating && !showLoader && !animate && currentSlide == index) }"
               :key="'a-slide-content' + index" class="h1">
            <div>
              <span v-html="current && current.title"></span>
              <span class="sub-text" v-html="current && current.subTitle"></span>
            </div>
          </div>
        </template>
        <div id="i5-splash">
          <div class="inner"
               :class="{ 'center' : showLoader, [current && current.splashPosition] : current && current.splashPosition }">
            <div class="i5-circle"></div>
          </div>
        </div>
      </div>
      <div class="i5-navigation" :class="{ 'minimized' : currentSlide > 0 }">
        <ul>
          <li class="active-bg" :style="{ left: (currentSlide - 1) * 80 + 'px' }"></li>
          <template v-for="(item, index) in slides">
            <li v-if="item.showOnMenu" @click.stop="changeSlides(index, 0)"
                :class="{ 'active' : currentSlide == index }" :key="'slide-' + index">
              {{ item.menuTitle }}
              <template v-if="item.innerSlides">
                <span @click.prevent="changeSlides(index, 0)"
                      :class="{ 'active' : currentSlide == index  && currentSlideInnerSlide == 1 }"></span>
                <span v-for="(item, innerIndex) in item.innerSlides" @click.stop="changeSlides(index, innerIndex)"
                      :class="{ 'active' : currentSlide == index && (currentSlideInnerSlide - 1) == innerIndex }"
                      :key="'slide-' + index + '-inner-slide-' + innerIndex"></span>
              </template>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      :slides="slides"
      :currentSlide="currentSlide"
      :currentSlideInnerSlide="currentSlideInnerSlide"
      :hasIntro="false"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide, null, true)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import backToHomeButton from '@/components/backToHomeButton'
import videoWrapper from '@/components/videoWrapper'
import bottomVideos from '@/components/bottomVideos'
import PasswordModal from '@/components/passwordModal'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  data () {
    return {
      slideWithPassword: null,
      currentSlide: 0,
      done: false,
      showHome: true,
      // introVisible: true,
      currentSlideInnerSlide: 1,
      backToHomeButtonText: 'Title',
      animate: false,
      showLoader: false,
      current: 0,
      isAnimating: false,
      imageCache: [],
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      shouldSwitchSlide: false,
      isReversing: false,
      showInfoBox: false,
      infoBoxData: null
    }
  },
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    backToHomeButton,
    bottomVideos,
    videoWrapper
  },
  watch: {
    done (val) {
      if (val) this.handleInitSlide()
    },
    current (nv, ov) {
      if (this.currentSlide > 0) {
        if (nv) {
          this.handleInfoBox(nv?.info_page || null)
        }
      }
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = this.slides[this.currentSlide]
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    }
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    calculateScrollPrevSlide () {
      switch (this.currentSlideInnerSlide) {
        case 3:
          return 1
        case 2:
          return 0
        default:
          return 0
      }
    },
    changeSlides (id, subId = null, isDirectFromMenu = false) {
      let innerSlidesCount = (this.slides[this.currentSlide] && this.slides[this.currentSlide].innerSlides) ? Object.keys(this.slides[this.currentSlide].innerSlides).length : false
      // let innerSlidesCountNext = (this.current && this.current.innerSlides) ? Object.keys(this.current.innerSlides).length : false
      if (this.isAnimating) {
        return false
      }

      if (id instanceof MouseEvent) id = null

      this.shouldSwitchSlide = false
      this.isAnimating = true

      if ((id && id !== this.currentSlide) || (!id && !subId && this.currentSlideInnerSlide > innerSlidesCount)) {
        this.showLoader = true
        this.animate = true
      }

      setTimeout(() => {
        this.showLoader = false
        if ((id && id !== this.currentSlide) || ((!id && !subId) && (id !== this.currentSlide && (!innerSlidesCount || this.currentSlideInnerSlide > innerSlidesCount)))) {
          if (id === undefined || id === null) {
            this.currentSlide++
          } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
            if (id === 'l' || id === 'u') {
              this.currentSlide++
            } else {
              this.currentSlide--
            }
          } else {
            this.currentSlide = parseInt(id)
          }

          if (this.currentSlide > Object.keys(this.slides).length || this.currentSlide === 0) {
            this.currentSlide = 0
          }
          this.shouldSwitchSlide = true
          this.currentSlideInnerSlide = 1
        }

        if (subId) this.currentSlideInnerSlide = subId

        if (subId === 0) {
          this.shouldSwitchSlide = true
        }

        if (this.shouldSwitchSlide && !subId) {
          this.current = this.slides[this.currentSlide]

          if (isDirectFromMenu && subId === null) {
            this.handleDirectMenuClick(id)
          }

          if (this.isReversing && this.currentSlideInnerSlide === 1) {
            this.currentSlideInnerSlide = 3
            this.current = this.slides[this.currentSlide].innerSlides[this.currentSlideInnerSlide - 1]
          } else this.currentSlideInnerSlide = 1
        } else {
          this.current = this.slides[this.currentSlide].innerSlides[this.currentSlideInnerSlide]
          if (isDirectFromMenu && subId === null) {
            this.handleDirectMenuClick(id)
          }
          this.currentSlideInnerSlide++
        }
        this.isReversing = false
        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
        }, 200)
      }, this.currentSlide === 0 ? 300 : 400)
    },
    handleDirectMenuClick (id) {
      this.currentSlide = id
      this.current = this.slides[id]; this.currentSlideInnerSlide = 0
    },
    shouldSwitchingSlide (id, subId = null) {
      // if (subId) return false
      return (this.currentSlide !== id || (this.slides[parseInt(this.currentSlide + 1)] && this.slides[parseInt(this.currentSlide + 1)].innerSlides.length))
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    handleInitSlide () {
      let $this = this
      $this.done = true
      Object.keys($this.slides).forEach((key) => {
        let b = new Image()
        b.src = $this.slides[key].img
        $this.imageCache.push(b)
      })

      document.querySelector('.slides-wrapper').addEventListener('touchstart', handleTouchStart, false)
      document.querySelector('.slides-wrapper').addEventListener('touchmove', handleTouchMove, false)

      var xDown = null
      var yDown = null

      function getTouches (evt) {
        return evt.touches || evt.originalEvent.touches
      }

      function handleTouchStart (evt) {
        const firstTouch = getTouches(evt)[0]
        xDown = firstTouch.clientX
        yDown = firstTouch.clientY
      }

      function handleTouchMove (evt) {
        if (!xDown || !yDown || $this.isAnimating) {
          return
        }

        var xUp = evt.touches[0].clientX
        var yUp = evt.touches[0].clientY

        var xDiff = xDown - xUp
        var yDiff = yDown - yUp

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff > 0) {
            $this.changeSlides('l')
          } else {
            $this.changeSlides('r')
          }
        } else {
          if (yDiff > 0) {
            $this.changeSlides('u')
          } else {
            $this.changeSlides('d')
          }
        }
        /* reset values */
        xDown = null
        yDown = null
      }

      document.querySelector('.slides-wrapper').addEventListener('wheel', (e) => {
        var dir = Math.sign(e.deltaY)
        // let innerSlidesCount = (this.slides[this.currentSlide] && this.slides[this.currentSlide].innerSlides) ? Object.keys(this.slides[this.currentSlide].innerSlides).length : false

        if (!$this.isAnimating) {
          if (dir === -1) {
            if ($this.currentSlide === 0) { return false }
            if ($this.currentSlide === 1 && $this.currentSlideInnerSlide === 1) {
              this.shouldSwitchSlide = true
              this.showLoader = true
              this.animate = true
              $this.changeSlides(0, null, true)
            } else {
              $this.changeSlides(($this.currentSlideInnerSlide === 1) ? $this.currentSlide - 1 : $this.currentSlide, $this.calculateScrollPrevSlide())
            }
            $this.isReversing = true
          } else {
            $this.changeSlides(null, null)
          }
        }
        e.preventDefault()
        e.stopPropagation()
        return false
      })
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    }
  },
  created () {
  },
  mounted () {
    let self = this
    this.$nextTick(() => {
      this.searchSlides(5).then(resp => {
        if (resp && Object.keys(resp.data.slides).length > 1) {
          self.slides[0] = {
            img: self.intro.image,
            title: self.intro.title,
            subTitle: self.intro.subtitle,
            splashPosition: 'top-right',
            menuTitle: self.intro.menu_title,
            showOnMenu: false,
            innerSlides: false,
            intro: true
          }
          self.handleInfoBox(self.introInfoPage)
        }
        Object.keys(self.slides).forEach((key) => {
          let b = new Image()
          b.src = self.slides[key].imgF
          self.imageCache.push(b)
        })
        var routeID = null
        if (self.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.changeSlides(parseInt(routeID), (this.$route.params.subId) ? this.$route.params.subId : null, true)
        } else {
          self.current = self.slides[0]
        }

        self.done = true
      })
    })

    // this.showHome = (this.$route.query.hm === undefined && this.$route.params.id === undefined)
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
