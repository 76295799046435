<template>
  <div class="a-slide__sports">
    <div class="a-slide__sports--packages">
      <ul>
        <li
          v-for="(item, i) in packages"
          :key="'spk-' + i"
          class="packageBox"
          :class="{
            'selected': selectedPckg(item),
            'included': includedPckg(item)
          }">
          <div class="packageBox-inner" @click="handlePackages(item)">
            <!-- <span class="h1 text-white">{{i + 1}}</span> -->
            <img :src="baseUrl + item.image" alt="" />
            <!-- <span class="icon"><img :src="require('@/assets/images/intro14/icons/icon-i.png')" /></span> -->
            <div class="tooltip-placeholder">
              <button class="btn icon" :class="'package-' + item.id" @click.stop="$emit('handleTooltip', 'package', item.id)" @blur="$emit('handleTooltip', null, null)">
                <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
              </button>
            </div>
            <div v-if="tooltip && tooltip === 'package-' + item.id" class="tooltip-holder" :class="{'up': (packages.length - 1) === i || (packages.length - 2) === i}" v-html="item.tooltip"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="a-slide__sports--leagues">
      <div class="a-slide__sports--leagues-left">
        <div class="sportTypeSection">
          <h3><span v-text="sportTypes[0].name"></span>
            <div class="position-relative ml-3">
              <div class="tooltip-placeholder">
                <button class="btn icon" :class="'sports-' + sportTypes[0].id" @click.stop="$emit('handleTooltip', 'sports', sportTypes[0].id)" @blur="$emit('handleTooltip', null, null)">
                  <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                </button>
              </div>
              <div v-if="tooltip && tooltip === 'sports-' + sportTypes[0].id" class="tooltip-holder" :class="sportTypes[0].tooltip_position" v-html="sportTypes[0].tooltip"></div>
            </div>
          </h3>
          <ul>
            <li v-for="(item, i) in football" :key="'fsb-'+ i">
              <div class="sportBox"
              @click="handleSports(item)"
              :class="{
                'selected': selectedSport(item),
                'included': includedSport(item)
              }">
                <img :src="baseUrl + item.image" alt="">
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="a-slide__sports--leagues-right">
        <div class="sportTypeSection">
          <h3><span v-text="sportTypes[1].name"></span>
            <div class="position-relative ml-3">
              <div class="tooltip-placeholder">
                <button class="btn icon" :class="'sports-' + sportTypes[1].id" @click.stop="$emit('handleTooltip', 'sports', sportTypes[1].id)" @blur="$emit('handleTooltip', null, null)">
                  <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                </button>
              </div>
              <div v-if="tooltip && tooltip === 'sports-' + sportTypes[1].id" class="tooltip-holder" :class="sportTypes[1].tooltip_position" v-html="sportTypes[1].tooltip"></div>
            </div>
          </h3>
          <ul>
            <li v-for="(item, i) in basketballHockey" :key="'fsb-'+ i">
              <div class="sportBox"
              @click="handleSports(item)"
              :class="{
                'selected': selectedSport(item),
                'included': includedSport(item)
              }">
                <img :src="baseUrl + item.image" alt="">
              </div>
            </li>
          </ul>
        </div>
        <div class="sportTypeSection">
          <h3><span v-text="sportTypes[2].name"></span>
            <div class="position-relative ml-3">
              <div class="tooltip-placeholder">
                <button class="btn icon" :class="'sports-' + sportTypes[2].id" @click.stop="$emit('handleTooltip', 'sports', sportTypes[2].id)" @blur="$emit('handleTooltip', null, null)">
                  <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                </button>
              </div>
              <div v-if="tooltip && tooltip === 'sports-' + sportTypes[2].id" class="tooltip-holder" :class="sportTypes[2].tooltip_position" v-html="sportTypes[2].tooltip"></div>
            </div>
          </h3>
          <ul>
            <li v-for="(item, i) in other" :key="'fsb-'+ i">
              <div class="sportBox"
              @click="handleSports(item)"
              :class="{
                'selected': selectedSport(item),
                'included': includedSport(item)
              }">
                <img :src="baseUrl + item.image" alt="">
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    packages: {
      type: Array,
      default: null,
      required: true
    },
    sports: {
      type: Array,
      default: null,
      required: true
    },
    reset: {
      type: Boolean
    },
    baseUrl: {
      type: String
    },
    sportTypes: {
      type: Array,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    tooltip: {
      type: String
    }
  },
  watch: {
    reset (val) {
      if (val) {
        // this.selectedPackages = []
        this.selectedSports = []
      }
    }
  },
  data () {
    return {
      selectedPackages: [],
      selectedSports: []
    }
  },
  computed: {
    football () {
      return this.sports.filter((item, i) => {
        return item.sportId === 1
      })
    },
    basketballHockey () {
      return this.sports.filter((item, i) => {
        return item.sportId === 2
      })
    },
    other () {
      return this.sports.filter((item, i) => {
        return item.sportId === 3
      })
    }
  },
  methods: {
    selectedPckg (item) {
      return this.state &&
          (this.state?.selectedPackages?.includes(item.id) ||
          (this.state?.addons?.includes(item.id)) ||
          (item.tariffs && item.tariffs.includes(this.state?.tariff?.id) && this.state?.tariffActivated) ||
          (this.state?.package?.id === item.id))
    },
    includedPckg (item) {
      return this.state && this.state.tariff && ((item.tariffs && item.tariffs.includes(this.state.tariff.id)) || (this.state.package && this.state.package.id === item.parent_id))
    },
    selectedSport (item) {
      return this.selectedSports.includes(item.id)
    },
    includedSport (item) {
      let hasVal = false
      for (let i = 0; i < item.packages.length; i++) {
        const packageItem = this.packages.find(itm => itm.id === item.packages[i])
        if (packageItem) {
          hasVal = (this.state?.selectedPackages?.includes(packageItem.id)) ||
            (this.state?.package?.id === packageItem.id) ||
            (this.state?.stateAddons?.includes(packageItem.id)) ||
            (packageItem?.parent_id === packageItem.id) ||
            (this.state?.package?.id === packageItem.parent_id) ||
            this.state?.stateAddons?.includes(packageItem.parent_id) ||
            (packageItem?.tariffs?.includes(this.state?.tariff?.id))
          if (hasVal) break
        }
      }
      return hasVal
    },
    handleSports (item) {
      const sportIndex = this.selectedSports.indexOf(item.id)
      if (sportIndex !== -1) this.selectedSports.splice(sportIndex, 1)
      else this.selectedSports.push(item.id)

      for (let i = 0; i < item.packages.length; i++) {
        !this.state.selectedPackages.includes(item.packages[i]) && this.$emit('handlePackages', this.packages.find(itm => itm.id === item.packages[i]))
      }
    },
    handlePackages (item) {
      (this.state?.selectedPackages?.includes(item.id) || this.state?.package?.id === item.parent_id || this.state?.package?.id === item.id || this.state?.addons?.includes(item.id)) && this.clearSports(item)
      this.$emit('handlePackages', item)
    },
    clearSports (itemPackage) {
      if (itemPackage.is_parent) {
        this.selectedSports = []
      } else {
        this.sports.forEach((item, i) => {
          if (item.packages.includes(itemPackage.id) || item.packages.includes(itemPackage.parent_id)) {
            let sportIndex = this.selectedSports.indexOf(item.id)
            sportIndex !== -1 && this.selectedSports.splice(sportIndex, 1)
          }
        })
      }
    }
  }
}
</script>
