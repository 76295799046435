<template>
    <div class="hybridVideos-single" v-if="slideVideo">
      <div class="singleVideoContent active fakeFade">
        <h3 v-text="slideVideo.title"></h3>
        <div class="custom-popup video-popup">
          <div class="inner bg-black position-relative">
            <button @click.stop="openVideo(false)" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
            <video v-if="slideVideo.isYoutube != 1" autoplay class="pop-up-video" controls :src="baseUrl + slideVideo.video"></video>
            <div v-else class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" :src="`https://www.youtube.com/embed/${getYoutubeId(slideVideo.youtube_id)}?&amp;controls=0&rel=0&autoplay=1`"
                title="YouTube video player" frameborder="0" controls="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    slideVideo: {
      type: Object
    },
    baseUrl: {
      type: String
    }
  },
  methods: {
    openVideo (video) {
      this.$emit('toggleVideo', video)
    },
    getYoutubeId (youtubeId) {
      return youtubeId.includes('.be/') ? youtubeId.split('.be/')[1] : youtubeId
    }
  }
}
</script>
