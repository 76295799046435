<template>
  <div @click.stop.prevent="triggerChange" id="sliderHolderId" class="sliderWrapperClass">
    <h1 class="i6-title-main" v-if="activeItem && activeItem.title" v-html="activeItem.title"></h1>
    <swiper ref="comSlider" class="inhaltSlider"
        :options="sliderOptions"
        @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(subItem, i) in item.slideItems" :key="'swiper-i-' + i">
        <div>
          <img :src="require('@/assets/images/intro6/inhalt/Television_16_9_front.png')" />
          <div class="scrollableContent">
            <swiper class="swiper" ref="scrollCont" :options="scrollOptions[i]">
              <template v-if="subItem.childSlides && subItem.childSlides.length > 1">
                <swiper-slide v-for="(chItem, c) in subItem.childSlides" :key="'childSlide-' + c">
                  <img :src="$store.state.slides.baseUrl + chItem.image" alt="">
                </swiper-slide>
              </template>
              <template v-else>
                <swiper-slide>
                  <img :src="$store.state.slides.baseUrl + subItem.image" alt="">
                </swiper-slide>
              </template>
              <div :class="'swiper-pagination swiper-pagination-' + i" slot="pagination"></div>
              <div :class="'swiper-scrollbar scrollbarInner-' + i" slot="scrollbar"></div>
            </swiper>
          </div>
          <button class="customZoom" @click.stop="handlePopup(subItem)">
            <img :src="require('@/assets/images/intro6/inhalt/zoombutton.svg')" />
          </button>
        </div>
      </swiper-slide>
      <div :class="'swiper-pagination swiper-pagination-top'" slot="pagination"></div>
      <div class="swiper-scrollbar js-swiper-scrollbar custom" slot="scrollbar"></div>
    </swiper>
    <div v-if="modalDialog" class="custom-popup pl-md-4 pb-md-8 pt-md-5 fade-in-bottom" id="dialogModal">
      <div class="inner bg-black position-relative">
        <button @click.stop="modalDialog = false" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
        <div class="scrollingPopup scrollableContent">
          <swiper class="swiper" ref="scrollContentModal" :options="scrollOptionsD[activeIndex]">
            <template v-if="activeItem.childSlides && activeItem.childSlides.length > 1">
              <swiper-slide v-for="(chlItem, j) in activeItem.childSlides" :key="'childSliders-' + j">
                <img :src="$store.state.slides.baseUrl + chlItem.image" alt="">
              </swiper-slide>
            </template>
            <template v-else>
              <swiper-slide>
                <img :src="$store.state.slides.baseUrl + activeItem.image" alt="">
              </swiper-slide>
            </template>
            <div :class="'swiper-pagination swiper-pagination-modal-' + activeIndex" slot="pagination"></div>
            <div :class="'swiper-scrollbar scrollbarInner-modal-' + activeIndex" slot="scrollbar"></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['item'],
  data () {
    let self = this
    return {
      modalDialog: false,
      activeItem: null,
      activeIndex: null,
      innerAnimating: false,
      sliderOptions: {
        navigation: false,
        slidesToShow: 1,
        freeMode: false,
        observer: true,
        scrollbar: {
          el: '.swiper-scrollbar.js-swiper-scrollbar.custom',
          draggable: false,
          freeModeSticky: true,
          freeModeMinimumVelocity: 0.4,
          freeModeMomentumRatio: 0.28,
          snapOnRelease: true
        },
        pagination: {
          el: '.swiper-pagination.swiper-pagination-top',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + self.item.slideItems[index].category + '</span>'
          }
        }
      }
    }
  },
  watch: {
    modalDialog (nv, ov) {
      this.$emit('removeWheelEvent', !nv)
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    preventScroll (e) {
      e.preventDefault()
      e.stopPropagation()
      // e.stopP
    },
    onSlideChange () {
      const arrayIndex = this.$refs.comSlider.$swiper.realIndex
      this.activeItem = this.item.slideItems[arrayIndex]
      this.activeIndex = arrayIndex
      this.returnInfoBox(this.activeItem.info_page || null)
      this.returnVideoBox(this.activeItem.video_box || null)
    },
    triggerChange (e) {
      let myTarget = e.target
      if ((myTarget.nodeName === 'IMG' && myTarget.parentNode.className.toLowerCase().includes('swiper-slide-active')) ||
      myTarget.className.includes('i6-title')) {
        this.$emit('changeSlides', 'l')
      }
    },
    handlePopup (data) {
      this.modalDialog = true
    },
    returnInfoBox (data) {
      this.$emit('handleInfoBox', data)
    },
    returnVideoBox (data) {
      this.$emit('handleVideoBox', data)
    }
  },
  computed: {
    scrollOptions () {
      return this.item.slideItems.map((el, i) => {
        // console.log(el)
        return {
          direction: 'vertical',
          slidesPerView: 1,
          freeMode: (el.childSlides && el.childSlides.length < 2),
          scrollbar: {
            el: '.swiper-scrollbar.scrollbarInner-' + i,
            draggable: true,
            dragSize: 24,
            freeModeSticky: true,
            snapOnRelease: (el.childSlides && el.childSlides.length > 1)
          },
          pagination: (el.childSlides && el.childSlides.length > 1) ? {
            el: '.swiper-pagination.swiper-pagination-' + i,
            clickable: true,
            renderBullet: function (index, className) {
              if (el.childSlides[index]) {
                return '<span class="' + className + '">' + el.childSlides[index].name + '</span>'
              } else {
                return ''
              }
            }
          } : false,
          observer: true,
          observeParents: true,
          mousewheel: false
        }
      })
    },
    scrollOptionsD () {
      return this.item.slideItems.map((el, i) => {
        // console.log(el)
        return {
          direction: 'vertical',
          slidesPerView: 'auto',
          freeMode: (el.childSlides && el.childSlides.length < 2),
          scrollbar: {
            el: '.swiper-scrollbar.scrollbarInner-modal-' + i,
            draggable: true,
            dragSize: 24,
            freeModeSticky: true,
            snapOnRelease: (el.childSlides && el.childSlides.length > 1)
          },
          pagination: false,
          // observer: true,
          // observeParents: true,
          mousewheel: true
        }
      })
    }
  },
  mounted () {
    let $this = this
    this.$nextTick(() => {
      $this.activeItem = $this.item.slideItems[0]
      $this.activeIndex = 0
      $this.returnInfoBox($this.activeItem.info_page || null)
      $this.returnVideoBox($this.activeItem.video_box || null)
    })
  },
  beforeDestroy () {
    this.modalDialog = false
  }
}
</script>
