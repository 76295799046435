<template>
  <div v-if="item" class="singleVideoContent" :class="{ 'fakeFade': fade }">
    <div>
      <img :src="$store.state.slides.baseUrl + item.img">
      <span @click.stop="openVideo(item.video)">
        <img :src="require('@/assets/images/play.svg')" alt="">
      </span>
    </div>
    <span v-if="item.title" v-html="item.title"></span>
    <div v-if="video" class="custom-popup video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="video = false" class="close btn btn-link btn-sm z-i-1"><img src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="video"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      video: false,
      fade: false
    }
  },
  watch: {
    item (val) {
      // console.log(val)
      this.fade = true
      setTimeout(() => {
        this.fade = false
      }, 300)
    },
    video (val) {
      if (!val) this.$emit('isPlaying', val)
    }
  },
  methods: {
    openVideo (url) {
      this.video = (url === undefined) ? '://0' : this.$store.state.slides.baseUrl + url
      this.$emit('isPlaying', true)
    }
  }
}
</script>
