<template>
  <div class="smartphoneSlider-wrapper">
    <swiper ref="smartphoneSlider"
      :options="sliderOptions"
      >
        <swiper-slide>
          <Smartphone :phone="phone" />
        </swiper-slide>
        <!-- <div class="swiper-pagination smartphone" id="pagination-smartphone" slot="pagination"></div>
        <div class="swiper-button-prev smartphone" id="smartphone-prev" slot="button-prev"></div>
        <div class="swiper-button-next smartphone" id="smartphone-next" slot="button-next"></div> -->
    </swiper>
  </div>
</template>

<script>
import Smartphone from '@/components/smartphone-comparison/smartphone.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    phone: {
      type: Object,
      default: () => ({})
    },
    categoryItems: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Smartphone
  },
  computed: {
    swiper () {
      return this.$refs?.smartphoneSlider?.$swiper
    }
  },
  data () {
    return {
      sliderOptions: {
        // enabled: false,
        centeredSlides: false,
        slidesPerView: 1,
        spaceBetween: 0,
        preventClicks: false,
        preventClicksPropagation: false,
        // pagination: {
        //   el: '.swiper-pagination.smartphone',
        //   clickable: true
        // },
        // navigation: {
        //   nextEl: '.swiper-button-next.smartphone',
        //   prevEl: '.swiper-button-prev.smartphone'
        // }
        navigation: false,
        pagination: false
      }
    }
  },
  methods: {
    onSlideChange () {
      // console.log('slide change')
    }
  },
  mounted () {
    // let phoneIndex = this.categoryItems.findIndex(item => item.id === this.phone.id)
    // this.swiper.slideTo(phoneIndex, 0, false)
  }
}
</script>
