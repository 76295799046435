<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>
    <div class="top-menu" :class="{'light' : current && ((!current.menuColor || !current.menuColor.length) || (current.menuColor.length && current.menuColor[0] != 'indigo'))}" v-if="!showIntro">
      <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
            <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
               :class="{ 'active' : slides[currentSlide] && slides[currentSlide].menuTitle == item.menuTitle }">{{ item.menuTitle }}</a>
            <div class="top-menu-sub-list"
                 v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
              <div class="d-flex justify-content-end">
                <span class="menu-close-icon" @click="closeMenu()">
                  <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                </span>
              </div>
              <ul>
                <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                  <a @click="handleSubMenu(ditem.realIndex)" v-text="ditem.menu_title"
                     :class="{'active': slides[currentSlide] && parseInt(slides[currentSlide].id) === parseInt(ditem.id)}"></a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="app-inner father-slide" v-if="!slideWithPassword">
      <div class="slides clip">
        <template v-for="(item, index) in slides">
          <img v-if="currentSlide == index" onload="this.style.visibility = 'visible'" :src="baseUrl + item.img"
               :key="'slides-' + index" alt=""/>
        </template>
        <!-- ./src because didn't work somehow with built files -->
      </div>
      <div id="circle-wrapper" v-if="current && current.type != 5 && current.type != 20">
        <div
          :class="{
            'circle': true,
            'animate': animate,
            'w-shadows': circleShadows
          }"
          :style="{
            height: circleDiameter + 'px',
            width: circleDiameter + 'px',
            left: (circleDiameter !== 0 && $window.innerWidth < 576 ? '-' + parseInt($window.innerWidth * 0.2) : 0) + 'px'
          }"
        >
        </div>
      </div>

      <marketingSlide @changeSlides="changeSlides" v-if="current && current.type === 20" :baseUrl="baseUrl" :item="current"></marketingSlide>

      <div
        v-if="current && (current.type != 5 || current.type != 20)"
        :class="{ 'slides-colored': true, 'animate': animate, 'iframe-background': current && current.type == 4 }"
        :style="{backgroundImage: (current && current.type == 4) ? 'url(' + baseUrl + current.img + ')' : ''}"
      >
        <template v-for="(item, index) in this.slides">
          <img v-if="currentSlide == index" :src="baseUrl + item.overlay"
            :class="{ 'ml-0' : item.align && item.align === 'left', 'mr-0' : item.align && item.align === 'right' }"
            alt="" :key="'slidesC-' + index">
        </template>
      </div>

      <div v-if="current && current.type != 20" :class="{ 'slides-content': true, 'animate': animate }" @click="changeSlides()">
        <div class="ml-md-7 pl-md-7">
          <div v-for="(item, index) in slides" :class="['slide-' + index, currentSlide == index && 'active', 'type-single-image-slide']" :key="'slidesA-' + index">
            <div class="d-flex flex-column" v-if="currentSlide == index && item.type != 4 && item.type != 5">
              <div class="d-block">
                <div class="h1"><span>{{ item.title }}</span></div>
                <div class="h1"><span>{{ item.subtitle }}</span></div>
              </div>
            </div>
            <div v-if="item.type === 4" class="iframe-view">
              <iframe sandbox="allow-same-origin allow-scripts" v-show="index == currentSlide && item.iframe_url"  :src="item.iframe_url" frameborder="0"></iframe>
              <a href="#" :class="{ 'visible' : index == currentSlide && item.iframe_url }" @click="changeSlides" class="continue-button text-primary continue-button-intro-10">weiter</a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column a-slide active" v-if="current && current.type === 5">
          <div
            class="a-slide-up-cont"
            :class="{
              'main': current.contentPosition === 'center'
            }"
          >
            <h1 v-if="current.title" v-html="current.title"></h1>
            <h2 v-if="current.subTitle" v-html="current.subTitle"></h2>
          </div>
          <div
            class="singleImageContent a-slide-down-cont"
            :class="[{
            'halfSpace': current.contentPosition !== 'center' && current.subTitle,
            'hasSlides': current.hasSlides
            }]"
          >
            <ul class="listImage" v-if="current.sponsImg && current.sponsImg.length > 0">
              <template v-for="(itm, i) in current.sponsImg">
                <li :key="'spimg' + i">
                  <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                  <span v-if="itm.title" v-text="itm.title"></span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-lg btn-xl fade-in-bottom" @click="showVideos = !showVideos"
              v-if="current && current.videos">
        Videos ansehen
      </button>
      <button class="btn btn-primary btn-lg btn-xl fade-in-bottom" @click="moreInfo = !moreInfo"
              v-if="current && current.moreInfo">
        Mehr erfahren
      </button>
      <div id="five-g-logo" :class="{ 'visible': showLoader }">
        <div class="inner"><img :src="require('@/assets/images/5G_BG.png')" alt=""></div>
      </div>

      <div id="intro" v-if="showIntro && intro" :class="{ 'visible' : showIntro }">
        <video :poster="baseUrl + intro.video_cover" id="intro-video" v-if="intro.video" ref="$video" @ended="this.showIntro - false; changeSlides(1)" autoplay muted playsinline :src="baseUrl + intro.video"></video>
        <button @click="hideIntro()" class="btn text-white">
          Skip Intro
          <img src="@/assets/images/intro-arrows.svg" alt="">
        </button>
      </div>

      <videosPopup v-on:closePopup="closePopup" v-if="showVideos" :videos="current && current.videos"></videosPopup>
      <moreInfo v-on:closePopup="closePopup" v-if="moreInfo"></moreInfo>
    </div>
    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :hasIntro="true"
      :intro="intro"
      :currentSlide="currentSlide"
      v-on:changeSlides="changeSlides"></sideMenu>
    <!-- <infoWrapper
      v-if="slides[currentSlide] && slides[currentSlide].info_page.content_elements.length > 0"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="slides[currentSlide] && slides[currentSlide].info_page.content_elements"></infoWrapper>

    <infoWrapper
      v-if="currentSlide === 0 && introInfoPage && introInfoPage.content_elements.length > 0"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="introInfoPage.content_elements"></infoWrapper> -->
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import videosPopup from '@/components/videosPopup'
import backToHomeButton from '@/components/backToHomeButton'
import moreInfo from '@/components/moreInfo'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'
import marketingSlide from '@/components/marketingSlide'

export default {
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  name: 'app',
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    videosPopup,
    moreInfo,
    videoWrapper,
    marketingSlide
  },
  watch: {
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    },
    current (nv, ov) {
      if (nv && nv.has_password) {
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
      }
    }
  },
  data () {
    return {
      slideWithPassword: null,
      backToHomeButtonText: 'Title',
      msg: 'Telekom 5G',
      currentSlide: 0,
      animate: false,
      showHome: true,
      circleDiameter: 730,
      circleShadows: false,
      showLoader: false,
      current: null,
      showVideos: false,
      moreInfo: false,
      showIntro: true,
      isAnimating: false,
      showInfoWrapper: true,
      imageCache: [],
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      showInfoBox: false,
      infoBoxData: null,
      dropdownMenu: {
        index: null,
        list: [],
        show: false
      }
    }
  },
  created () {
    window.addEventListener('resize', this.resizeEventHandler)
    window.dispatchEvent(new Event('resize'))
    if (!this.$route.params?.id) { this.addLoading(false) }
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeEventHandler)
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    getCircleDiameter () {
      return (window.innerWidth * 0.8 > 576) ? 730 : window.innerWidth * 1.4
    },
    resizeEventHandler () {
      this.circleDiameter = this.getCircleDiameter()
    },
    changeSlides (id) {
      if (this.isAnimating) {
        return false
      }

      if (this.showIntro) this.hideIntro(false)

      this.isAnimating = true
      this.loader()
      setTimeout(() => {
        if (id === undefined) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.slides[this.currentSlide] === undefined) {
          this.currentSlide = 0
          this.handleIntroVideo()
          return
        }

        this.circleDiameter = this.getCircleDiameter()
        this.animate = true
        this.showLoader = false
        this.circleShadows = true

        this.current = this.slides[this.currentSlide]
        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.isAnimating = false
        }, 2000)
      }, 1000)
    },
    loader () {
      this.animate = false
      this.current = null
      this.circleDiameter = 0
      this.circleShadows = false
      this.showLoader = true
    },
    closePopup () {
      this.showVideos = false
      this.moreInfo = false
    },
    handleIntroVideo (isFirst = false) {
      this.showIntro = true
      if (!isFirst) this.changeSlides(0)

      this.$nextTick(() => {
        let vid = document.getElementById('intro-video')

        if (vid) {
          vid.play()
          vid.addEventListener('ended', () => {
            document.getElementById('intro').classList.add('fadeUpVideo')
            this.hideIntro()
          })
        } else {
          this.hideIntro()
        }
      })
    },
    hideIntro (changeSlide = true) {
      let vid = document.getElementById('intro-video')

      if (vid) vid.removeEventListener('ended', () => {})

      setTimeout(() => {
        this.showIntro = false
        this.showLoader = true
        this.isAnimating = false
        if (changeSlide) this.changeSlides()
      }, 300)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleMenu (navItem, index) {
      if (this.dropdownMenu.show) {
        this.dropdownMenu.show = false
        this.changeSlides(index)
      } else {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        this.dropdownMenu.index = index
        for (let key in this.slides) {
          // console.log(parseInt(this.slides[key].parentId) + '-' + parseInt(navItem.id), this.slides[key]);
          if (this.slides[key] && parseInt(this.slides[key].parentId) === parseInt(navItem.id) && parseInt(this.slides[key].parentId) !== parseInt(this.slides[key].id)) {
            let item = this.slides[key]
            item.realIndex = parseInt(key)
            this.dropdownMenu.list.push(item)
          }
        }
        if (this.dropdownMenu.list.length > 0) {
          this.dropdownMenu.show = true
        } else {
          this.changeSlides(index)
        }
      }
    },
    closeMenu () {
      this.dropdownMenu = {
        index: null,
        list: [],
        show: false
      }
    },
    handleSubMenu (index) {
      this.changeSlides(index)
      this.closeMenu()
    }
  },
  mounted () {
    let $this = this
    this.searchSlides(1).then(() => {
      $this.handleInfoBox($this.introInfoPage)
      this.$nextTick(() => {
        let slideContent = document.querySelector('.slides-content') &&
        Object.keys(this.slides).forEach((key) => {
          let a = new Image()
          a.src = this.slides[key].overlay
          let b = new Image()
          b.src = this.slides[key].img
          this.imageCache.push(a)
          this.imageCache.push(b)
        })

        var routeID = null
        if (this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showIntro = false
          this.changeSlides(parseInt(routeID))
        } else {
          this.handleIntroVideo(true)
          this.currentSlide = 0
          this.resizeEventHandler()
        }

        slideContent && slideContent.addEventListener('touchstart', handleTouchStart, false)
        slideContent && slideContent.addEventListener('touchmove', handleTouchMove, false)

        var xDown = null
        var yDown = null

        function getTouches (evt) {
          return evt.touches || evt.originalEvent.touches
        }

        function handleTouchStart (evt) {
          const firstTouch = getTouches(evt)[0]
          xDown = firstTouch.clientX
          yDown = firstTouch.clientY
        }

        function handleTouchMove (evt) {
          if (!xDown || !yDown || $this.isAnimating) {
            return
          }

          var xUp = evt.touches[0].clientX
          var yUp = evt.touches[0].clientY

          var xDiff = xDown - xUp
          var yDiff = yDown - yUp

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
              $this.changeSlides('l')
            } else {
              $this.changeSlides('r')
            }
          } else {
            if (yDiff > 0) {
              $this.changeSlides('u')
            } else {
              $this.changeSlides('d')
            }
          }
          /* reset values */
          xDown = null
          yDown = null
        }

        slideContent && slideContent.addEventListener('wheel', (e) => {
          var dir = Math.sign(e.deltaY)

          if (!this.isAnimating) {
            if (dir === -1) {
              $this.changeSlides('r')
            } else {
              $this.changeSlides('l')
            }
          }
          e.preventDefault()
          e.stopPropagation()
          return false
        })
      })
    })

    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
