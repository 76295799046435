<template>
  <div v-if="false" id="bottom-videos" :class="{ 'opened' : isOpened, 'transition-linear' : 1 }">
    <button class="btn btn-dark btn-lg" :class="{'active' : isOpened}" @click="toggleVideo()" id="toggle"><img src="@/assets/images/arrow.svg" alt=""></button>
    <div class="inner">
      <div class="container-fluid">
        <div class="videos-wrapper row">
          <div class="col-6 col-md-3" v-for="index in 8" :key="index">
            <div class="video">
              <div class="inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isOpened'],
  mounted () {
  },
  methods: {
    toggleVideo () {
      this.$emit('togglePanel', 'bottomVideos', this.isOpened)
    }
  }
}
</script>
