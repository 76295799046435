
const state = {
  phones: [],
  activeView: 'category',
  activeCategory: 1,
  activePhone: null,
  // comparedPhones: [],
  sliderItems: [],
  firstPhone: null,
  secondPhone: null,
  thirdPhone: null,
  lastView: '',
  print: {
    show: false,
    expanded: false
  }
}

const getters = {
  phones: state => state.phones,
  firstPhone: state => state.firstPhone || null,
  secondPhone: state => state.secondPhone || null,
  thirdPhone: state => state.thirdPhone || null,
  activeView: state => state.activeView,
  activeCategory: state => state.activeCategory,
  activePhone: state => state.activePhone,
  // comparedPhones: state => state.comparedPhones,
  sliderItems: state => state.sliderItems,
  print: state => state.print
}

const mutations = {
  setPhones (state, phones) {
    state.phones = phones
  },
  setActiveView (state, view) {
    state.lastView = state.activeView
    state.activeView = view
  },
  setActiveCategory (state, category) {
    state.activeCategory = category
  },
  setActivePhone (state, phone) {
    state.activePhone = phone
  },
  setComparedPhones (state, phones) {
    state.comparedPhones = phones
  },
  setPhonesCompare (state, { key, phone }) {
    state[key] = null
    state[key] = phone
  },
  setSliderItems (state, items) {
    state.sliderItems = items
  },
  setPrint (state, print) {
    state.print = print
  }
}

const actions = {
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  namespaced: true
}
