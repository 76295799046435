<template>
  <div @click.stop="handleInnerSteps('l')" id="simpleId"
  :class="{
    'animateVideoLast': threestepsdata.currentInnerStep === 4,
    'videoUpRev': threestepsdata.currentInnerStep < 4,
    'videoOut': threestepsdata.currentInnerStep > 4
    }">
    <div class="simpleStepWrapperClickableArea" @click.stop="handleInnerSteps('l')"></div>
    <span v-if="threestepsdata.currentInnerStep === 0">In nur 3 Schritten</span>
    <div :class="{ ['animate-slide-' + threestepsdata.currentInnerStep] : threestepsdata.currentInnerStep > 0, 'animate': threestepsdata.currentInnerStep > 0 }" v-if="threestepsdata.currentInnerStep < 4">
      <div class="fistSimpleStep" :class="{ 'animate': animate && threestepsdata.currentInnerStep === 0, 'pl-7' : threestepsdata.currentInnerStep === 3 }">
        <div class="baseImageHolder">
          <div class="primaryImage" :class="{ 'isScaled': !threestepsdata.baseDynamic }">
            <img :src="$store.state.slides.baseUrl + threestepsdata.baseImage[threestepsdata.baseDynamic ? 1 : 3].img" />
            <div class="stikcy" v-if="threestepsdata.currentInnerStep > 0">
              <ul>
                <li @click.stop="threestepsdata.baseDynamic = true" :class="{ 'active' : threestepsdata.baseDynamic === true }">
                  <svg id="Icons" xmlns="http://www.w3.org/2000/svg" width="67.5" height="49.5" viewBox="0 0 67.5 49.5">
                    <path id="Path_776" data-name="Path 776" d="M63.011,10H8.491A6.508,6.508,0,0,0,2,16.491V53.009A6.428,6.428,0,0,0,8.491,59.5h54.52a6.428,6.428,0,0,0,6.49-6.491V16.491A6.428,6.428,0,0,0,63.011,10Zm3.115,43.009a3.046,3.046,0,0,1-3.115,3.116H8.491a3.046,3.046,0,0,1-3.115-3.116V16.491a3.119,3.119,0,0,1,3.115-3.116h54.52a3.046,3.046,0,0,1,3.115,3.116ZM43.626,16.75H27.876a1.128,1.128,0,0,0-1.125,1.125v33.75a1.128,1.128,0,0,0,1.125,1.125h15.75a1.128,1.128,0,0,0,1.125-1.125V17.875A1.128,1.128,0,0,0,43.626,16.75ZM42.5,50.5H29V41.478H42.5Zm0-11.25H29V19H42.5Zm-9-5.626a2.25,2.25,0,1,1,2.25,2.251A2.255,2.255,0,0,1,33.5,33.624ZM33.5,46a2.25,2.25,0,1,1,2.25,2.25A2.257,2.257,0,0,1,33.5,46Zm0-21.375a2.25,2.25,0,1,1,2.25,2.25A2.257,2.257,0,0,1,33.5,24.625Z" transform="translate(-2.001 -10)" fill="#a10053"/>
                  </svg>
                </li>

                <li>oder</li>
                <li @click.stop="threestepsdata.baseDynamic = false" :class="{ 'active' : threestepsdata.baseDynamic === false }">
                  <svg id="Icons" xmlns="http://www.w3.org/2000/svg" width="51.75" height="67.508" viewBox="0 0 51.75 67.508">
                    <path id="Path_775" data-name="Path 775" d="M43.883,2h-18A16.925,16.925,0,0,0,9.008,18.875v29.25A16.925,16.925,0,0,0,25.883,65h18A16.925,16.925,0,0,0,60.758,48.125V18.875A16.925,16.925,0,0,0,43.883,2Zm13.5,46.125a13.515,13.515,0,0,1-13.5,13.5h-18a13.515,13.515,0,0,1-13.5-13.5V18.875a13.515,13.515,0,0,1,13.5-13.5h18a13.515,13.515,0,0,1,13.5,13.5ZM38.258,27.875h-6.75a2.252,2.252,0,0,0-2.25,2.25v6.75a2.252,2.252,0,0,0,2.25,2.25h6.75a2.252,2.252,0,0,0,2.25-2.25v-6.75A2.252,2.252,0,0,0,38.258,27.875Zm0,9h-6.75v-6.75h6.75ZM45.19,67.258H25.033a1.125,1.125,0,1,0,0,2.25H45.19a1.125,1.125,0,0,0,0-2.25Z" transform="translate(-9.008 -2)" fill="#fff"/>
                  </svg>
                </li>
              </ul>
            </div>
          </div>
          <div class="innerSecImg" v-if="threestepsdata.currentInnerStep < 3">
            <img :src="$store.state.slides.baseUrl + threestepsdata.baseImage[2].img" />
          </div>
          <div class="innerSecImgThird">
            <ul>
              <li><img width="230" :src="require('@/assets/images/intro4/Apple.svg')" /></li>
              <li><img width="230" :src="require('@/assets/images/intro4/google_play.svg')" /></li>
            </ul>
            <img :src="require('@/assets/images/intro4/abwesend.png')" />
          </div>
        </div>
        <div class="rightSimpleContent">
          <span class="schritt">Schritt</span>
          <div class="inner-item" :class="{ 'animate': threestepsdata.currentInnerStep == index, 'animate-out' : threestepsdata.currentInnerStep > index }" v-for="(item, index) in threestepsdata.threeSimpleSteps" :key="'threeSimpleSteps-' + index">
            <span class="bigNr">{{ index }}</span>
            <span class="stepTitle">{{ item.title }}</span>
          </div>
        </div>
        <div class="equipmentHolder">
          <template v-for="(item, i) in threestepsdata.images">
            <div :class="[ 'eq' + i ]" :key="'eqImg' + i">
              <img :src="$store.state.slides.baseUrl + threestepsdata.images[i].img" alt="">
            </div>
          </template>
        </div>
      </div>
    </div>
    <single-video v-on:isPlaying="checkVideoTK" :item="threestepsdata.videoData"></single-video>
  </div>
</template>

<script>
import singleVideo from '@/components/singleVideo'
export default {
  props: ['animate', 'currentSlide', 'threestepsdata'],
  // data () {},
  watch: {
    'threestepsdata.currentInnerStep' (val) {
      if (val !== 1) {
        setTimeout(() => {
          this.threestepsdata.baseDynamic = true
        }, 300)
      }
    }
  },
  components: {
    singleVideo
  },
  methods: {
    checkVideoTK (value) {
      this.$emit('isPlaying', value)
    },
    handleInnerSteps (val) {
      this.threestepsdata.isAnimating = true
      if (val) {
        if (this.threestepsdata.currentInnerStep === 0 && val === 'r') {
          this.$emit('changeSlides', 'r')
        } else if (this.threestepsdata.currentInnerStep === 4 && val === 'l') {
          this.threestepsdata.currentInnerStep += 1
          this.$emit('changeSlides', 'l')
        } else {
          switch (val) {
            case 'r':
              this.threestepsdata.currentInnerStep -= 1
              break
            default:
              this.threestepsdata.currentInnerStep += 1
              break
          }
          setTimeout(() => {
            this.threestepsdata.isAnimating = false
          }, 300)
        }
      }
    }
  },
  mounted () {
    let $this = this
    this.threestepsdata.currentInnerStep = 0
    document.querySelector('#simpleId').addEventListener('wheel', (e) => {
      var dir = Math.sign(e.deltaY)

      if (!this.threestepsdata.isAnimating) {
        if (dir === -1) {
          $this.handleInnerSteps('r')
        } else {
          $this.handleInnerSteps('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    })
  },
  beforeDestroy () {
  // document.querySelector('#simpleId').removeEventListener('wheel')
  }
}
</script>
