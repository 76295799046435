<template>
  <div v-if="phone" class="phone-wrapper" @click="handleClick">
    <div class="phone-wrapper__image">
      <transition name="fade-in">
        <img v-if="baseUrl && phone.images_and_colors[activeColor]"
        :src="fullImage(phone.images_and_colors[activeColor])" />
      </transition>
      <div v-if="showDots"
      class="phone-wrapper__image__colors"
      :class="{ 'phone-wrapper__image__colors--bottom': inSlider }">
      <template v-for="(color, i) in phone.images_and_colors">
        <span v-if="color.image" :key="'color-' + i"
          :style="{
            backgroundColor: color.color
          }"
          @click.stop="handleColor(i)"></span>
      </template>
      </div>
    </div>
    <div v-if="!inSlider" class="phone-wrapper__title">
      <h4 v-text="phone.name"></h4>
      <p><b>Speicher</b> {{ phone.storage }}</p>
      <p><b>Farbe</b> {{ phone.images_and_colors[activeColor] ? phone.images_and_colors[activeColor].color_name : '' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: Object,
      default: null
    },
    inSlider: {
      type: Boolean,
      default: false
    },
    showDots: {
      type: Boolean,
      default: true
    },
    cat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeColor: this.inSlider
        ? this.phone.activeColor !== undefined && this.phone.activeColor > -1 ? this.phone.activeColor : 0
        : 0
    }
  },
  computed: {
    baseUrl () {
      return this.$store.state?.slides?.baseUrl
    }
  },
  methods: {
    fullImage (data) {
      return this.baseUrl && data.image ? this.baseUrl + data.image : require('@/assets/images/intro13/xioami-14-ultra.png')
    },
    handleColor (i) {
      if (i === this.activeColor) return
      this.activeColor = parseInt(i)
    },
    handleClick () {
      this.$emit('handleClick', this.cat ? { phone: this.phone, activeColor: this.activeColor } : this.phone)
    }
  }
}
</script>
