<template>

  <div>
    <div class="custom-popup-backdrop"></div>
    <div class="custom-popup pl-md-4 pb-md-8 fade-in-bottom">
      <div class="position-relative z-i-5"> <button @click.stop.prevent="close()" class="close btn btn-link btn-sm"><img src="@/assets/images/cancel.svg" alt=""></button></div>
      <div class="inner">
        <div class="d-flex percentage-wrapper justify-content-between" :class="{ 'animate' : animate }">
          <div class="percentage-counters text-center" :class="'progress-' + item" v-for="(item, index) in [5, 25, 100]" :key="index">
            <div class="display-3 font-weight-bold mb-2">{{ index + 3 }}G</div>
            <div class="percentage my-6" :class="'progress-' + item">
              <div class="img filter-blur-30"></div>
              <div class="circle-progress" :class="'circle-progress-' + item">
<!--                <svg class="circle-svg circle-svg&#45&#45stroked circle-svg&#45&#452x" viewBox="0 0 100 100"-->
<!--                     xmlns="http://www.w3.org/2000/svg">-->
<!--                  <circle class="default circle-svg__circle circle-svg__circle&#45&#45stroked" cx="50" cy="50"-->
<!--                          r="45"></circle>-->
<!--                  <circle class="circle-svg__circle circle-svg__circle&#45&#45stroked" cx="50" cy="50" r="45"></circle>-->
<!--                </svg>-->
                <vue-circle
                  :progress="item"
                  :size="300"
                  :reverse="false"
                  line-cap="square"
                  :fill="{ color: (item === 100) ? '#E20074' : '#529AD6' }"
                  empty-fill="#fff"
                  :animation="{ duration: 3000 }"
                  :animation-start-value="0.0"
                  :start-angle="0"
                  insert-mode="append"
                  :thickness="12"
                  :show-percent="false">
                </vue-circle>
              </div>
            </div>
            <div class="display-3 percentage-number font-weight-bold" data-counters :data-count-target="item" data-append="%"></div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import VueCircle from 'vue2-circle-progress/src/index.vue'
export default {
  components: {
    VueCircle
  },
  data () {
    return {
      animate: false
    }
  },
  mounted () {
    setTimeout(() => {
      let counters = document.querySelectorAll('[data-counters]')
      counters.forEach((item, index) => {
        this.animateCounters(item, 0, item.getAttribute('data-count-target'), 2000, '%')
      })
    }, 600)
    this.animate = true
  },
  methods: {
    close () {
      this.$emit('closePopup')
    },
    animateCounters (obj, start, end, duration, append = null) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (append) obj.innerHTML += '%'
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    }
  }
}
</script>
