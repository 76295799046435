<template>
  <div class="password-modal">
    <div class="password-modal__inner text-white">
      <h2 class="font-weight-bold">Login zur Seitenansicht</h2>
      <p class="font-weight-light">Dies ist ein geschützter Bereich. Um auf die Seiteninhalte zuzugreifen, geben Sie bitte das Passwort ein.</p>
      <input type="password" v-on:keyup.enter="checkPassword" placeholder="Password" v-model="password" class="form-control">
      <p v-if="hasError" class="text-white mt-3 font-weight-bold">Sie haben ein falsches Paßwort eingegeben</p>
      <button type="button" class="btn btn-outline-light mt-3 btn-sm btn-block" @click="checkPassword">Anmeldung
      </button>
    </div>
  </div>
</template>

<script>
import { AuthHttp } from '@/helpers/http-helper'

export default {
  props: ['isOpened', 'slide'],
  mounted () {
  },
  data () {
    return {
      password: null,
      hasError: false
    }
  },
  methods: {
    checkPassword () {
      this.hasError = false
      AuthHttp.post('/slide/' + this.slide.id, {
        password: this.password
      }).then(resp => {
        if (resp.data) {
          delete this.slide.has_password
          this.$emit('allowViewSlide', this.slide)
        } else {
          this.hasError = true
        }
      })
    },
    toggleModal () {
      this.$emit('togglePanel', 'passwordModal', this.isOpened)
    }
  }
}
</script>
