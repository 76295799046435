<template>
  <div class="tip-popup-wrapper">
    <div class="tip-popup-wrapper-inner">
      <div class="tip-popup-wrapper-inner-actions" @click="$emit('triggerTipp')">
        <span class="icon">
          <img :src="require('@/assets/images/cancel-white.svg')" alt="">
        </span>
      </div>
      <div class="tip-popup-wrapper-inner-content">
        <div class="image-holder">
          <div class="image">
            <img :src="$store.state.slides.baseUrl + tipp.image" alt="">
          </div>
        </div>
        <div class="content">
          <span>TIPP:</span>
          <p v-html="tipp.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    tipp: {
      type: Object,
      required: true,
      default: null
    }
  },
  data () {
    return {}
  }
}
</script>
