<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner" v-if="!slideWithPassword">
      <div class="slides-wrapper slides-wrapper-intro6 clip" @click.stop="changeSlides" :class="{}">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index,
            'previewSlide': index < currentSlide,
            'slideTypeOne': item.type === 1,
            'slideTypeTwo': item.type === 2,
            'downBackground': item.type === 1 && item.switch
          }" :style="{ backgroundImage : 'url(' + baseUrl + item.img + ')' }">
            <magenta-tv v-on:changeSlides="changeSlides" v-if="item.type === 1 && currentSlide == index"
                        :item="item"></magenta-tv>
            <inhalt-view v-on:removeWheelEvent="removeWheelEvent" v-on:handleInfoBox="handleInfoBox"
                         v-on:changeSlides="changeSlides" v-if="item.type === 3 && currentSlide == index"
                         :item="item"></inhalt-view>
            <magenta-simple-slider v-on:handleInfoBox="handleInfoBox" v-on:changeSlides="changeSlides"
                                   v-if="item.type === 10 && currentSlide == index"
                                   :item="item"></magenta-simple-slider>
            <magenta-sport-slider v-on:handleInfoBox="handleInfoBox" v-on:changeSlides="changeSlides"
                                  v-if="item.type === 4 && currentSlide == index" :item="item"></magenta-sport-slider>
            <magenta-marathon-slider v-on:changeSlides="changeSlides" v-if="item.type === 5 && currentSlide == index"
                                     :item="item"></magenta-marathon-slider>
            <tv-explanation v-on:handleInfoBox="handleInfoBox" v-on:changeSlides="changeSlides"
                            v-if="currentSlide == index && item.type === 7" :item="item"></tv-explanation>

            <div v-if="item.type === 6 && currentSlide == index">
              <h1 class="i6-title" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
              <!-- <h1 class="i6-title font-weight-light mt-0" v-if="!!item.subtitle" v-html="item.subtitle"></h1> -->
              <div class="single-image-slider">
                <img :src="baseUrl + item.innerImage"/>
              </div>
            </div>

            <div v-if="item && item.type === 8 && currentSlide == index" :key="'a-slide-content' + index">
              <div class="type-8__content">
                <h1 class="i6-title" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
                <h1 class="i6-title text-left font-weight-light mt-0" v-if="item.subtitle" v-html="item.subtitle"></h1>
              </div>
              <img v-if="item.topInnerImage" class="fadeDown-In type-8__top-image" :src="baseUrl + item.topInnerImage"
                   alt="">
              <img v-if="item.bottomInnerImage" class="fadeDown-In type-8__bottom-image"
                   :src="baseUrl + item.bottomInnerImage" alt="">
            </div>
            <div v-if="item && item.type === 9 && currentSlide == index" :key="'a-slide-content' + index">
              <div class="type-9__content">
                <h1 class="i6-title" :class="{ 'mb-0' : item.subtitle }" v-if="item.title" v-html="item.title"></h1>
                <h1 class="i6-title sub text-left font-weight-bold mt-0" v-if="item.subtitle"
                    v-html="item.subtitle"></h1>
              </div>
            </div>
            <div v-if="item && item.type === 11 && item.url && currentSlide == index" class="h-100 w-100 centerPlay">
              <button class="centerPlayButton" @click.stop.prevent="openVideo(item.url)">
                <img :src="require('@/assets/images/play.svg')" alt="">
              </button>
            </div>
          </div>
          <div v-if="item.type === 2"
               :class="{
              'animate' : (!showLoader && !animate && currentSlide == index),
              'splashType': item.type === 2,
              'splashActive': currentSlide == index,
              'prevSlideTitle': index < currentSlide
            }"
               :key="'a-slide-content' + index" class="h1"
          >
            <span v-if="item.title" v-html="item.title"></span>
            <!-- <span class="text-uppercase" v-if="item.subtitle" v-html="item.subtitle"></span> -->
          </div>
        </template>
        <div id="i6-splash"
             v-if="(current && current.type === 2) || (animate && current && current.type !== 2 && slides[currentSlide + 1] && slides[currentSlide + 1].type === 2)"
             :class="{
            'initialTypeOne': (animate && current.type !== 2 && slides[currentSlide + 1] && slides[currentSlide + 1].type === 2 )
            }">
          <div class="inner">
            <img :src="require('@/assets/images/intro6/type2bg.svg')" alt="">
          </div>
        </div>
        <!-- ./src because didn't work somehow with built files -->
      </div>
    </div>
    <div v-if="slideVideo" class="custom-popup pl-md-4 pb-md-8 pt-md-6 fade-in-bottom video-popup">
      <div class="inner bg-black position-relative">
        <button @click.stop="slideVideo = false" class="close btn btn-link btn-sm z-i-1"><img
          src="@/assets/images/cancel-white.svg" alt=""></button>
        <video autoplay class="pop-up-video" controls :src="slideVideo"></video>
      </div>
    </div>
    <div id="intro" class="intro6-video" v-if="showIntro && intro" :class="{ 'notVisible': currentSlide > 0 }">
      <div class="introTitle">
        <span v-if="intro.title" v-text="intro.title"></span>
      </div>
      <video ref="$video" muted id="intro-video" @click="handleIntroVideo" playsinline :src="baseUrl + intro.video"></video>
      <button v-if="!isVideoPlaying" id="play-intro"><img :src="require('@/assets/images/play.svg')" alt=""></button>
      <button @click="hideIntro()" class="btn text-white">Skip Intro <img src="@/assets/images/intro-arrows.svg" alt="">
      </button>
    </div>

    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import MagentaTv from '@/components/magentaTv'
import InhaltView from '@/components/inhaltView'
import MagentaSimpleSlider from '@/components/magentaSimpleSlider'
import backToHomeButton from '@/components/backToHomeButton'
import TvExplanation from '@/components/tvExplanation'
import MagentaSportSlider from '@/components/magentaSportSlider'
import MagentaMarathonSlider from '@/components/magentaMarathonSlider'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  watch: {
    current (nv, val) {
      this.initEvents(nv && nv.innerEvents === undefined)
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    }
  },
  data () {
    return {
      slideWithPassword: null,
      currentSlide: 0,
      animate: true,
      showLoader: false,
      current: null,
      showHome: true,
      showIntro: true,
      backToHomeButtonText: 'Title',
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: false,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null
    }
  },
  components: {
    PasswordModal,
    TvExplanation,
    infoWrapper,
    sideMenu,
    bottomVideos,
    MagentaTv,
    InhaltView,
    MagentaSimpleSlider,
    backToHomeButton,
    MagentaSportSlider,
    MagentaMarathonSlider,
    videoWrapper
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      this.showIntro = true
      this.currentSlide = 0
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      if (this.isAnimating) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      this.isAnimating = true
      this.showLoader = true
      this.animate = true
      setTimeout(() => {
        this.showIntro = false
      }, 1500)
      setTimeout(() => {
        this.showLoader = false

        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length)) this.handleIntroVideo()
        this.current = this.slides[this.currentSlide]

        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
        }, 700)
      }, 700)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    }
  },
  // created () {
  // },
  mounted () {
    var routeID = this.$route.params?.id
    const slideID = this.$route.params?.slideId
    this.searchSlides(6).then(() => {
      this.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = this.slides[key].img
          this.imageCache.push(b)
        })

        this.initEvents(true)
      })

      if (routeID) {
        let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
        if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
      }
      if (routeID && this.slides[routeID]) {
        this.showLoader = true
        this.animate = this.isAnimating = false
        setTimeout(() => {
          this.changeSlides(parseInt(routeID))
          if (slideID) {
            setTimeout(() => {
              if (document.querySelector('.swiper-container')) { document.querySelector('.swiper-container').swiper.slideTo(slideID) }
            }, 1500)
          }
        }, 300)
      }
    })

    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
