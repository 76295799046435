<template>
  <div class="speedCircles" :class="{ 'active': active }">
    <ul>
      <li>
        <radial-progress-bar
          :diameter="300"
          :completed-steps="startDownload"
          :total-steps="4000"
          :startColor="'#A4A4A4'"
          :stopColor="'#A4A4A4'"
          :innerStrokeColor="'transparent'"
          :strokeWidth="20"
          :innerStrokeWidth="20"
          :animateSpeed="1500">
          <div class="circleContent">
            <p v-text="item.download.uptext"></p>
            <span data-counters :data-count-target="item.download.nr" v-text="'0000'"></span>
            <p v-text="item.download.downtext"></p>
            <span class="sticker" v-html="item.download.title"></span>
            <div class="innerRadial">
              <radial-progress-bar
                :diameter="260"
                :completed-steps="inner"
                :total-steps="100"
                :startColor="'#E20074'"
                :stopColor="'#E20074'"
                :innerStrokeColor="'transparent'"
                :strokeWidth="20"
                :innerStrokeWidth="20"
                :animateSpeed="1500">
                <div class="innerRadial-arrow">
                  <i>
                    <svg version="1.1" id="Capa_10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 357 357" style="enable-background:new 0 0 50px 50px;" xml:space="preserve">
                      <g>
                        <g id="play-arrow-1">
                          <polygon points="38.25,0 38.25,357 318.75,178.5"/>
                        </g>
                      </g>
                    </svg>
                  </i>
                </div>
              </radial-progress-bar>
            </div>
          </div>
        </radial-progress-bar>
        <div class="lines">
          <span class="first"><span>DSL</span></span>
          <span class="last"><span>VDSL</span></span>
        </div>
      </li>
      <li>
        <radial-progress-bar
          :diameter="300"
          :completed-steps="startUpload"
          :total-steps="400"
          :startColor="'#A4A4A4'"
          :stopColor="'#A4A4A4'"
          :innerStrokeColor="'transparent'"
          :strokeWidth="20"
          :innerStrokeWidth="20"
          :animateSpeed="1500"
          :isClockwise="false">
          <div class="circleContent">
            <p v-text="item.upload.uptext"></p>
            <span data-counters :data-count-target="item.upload.nr" v-text="'000'"></span>
            <p v-text="item.upload.downtext"></p>
            <span class="sticker" v-html="item.upload.title"></span>
            <div class="innerRadial">
              <radial-progress-bar
                :diameter="260"
                :completed-steps="inner"
                :total-steps="100"
                :startColor="'#E20074'"
                :stopColor="'#E20074'"
                :innerStrokeColor="'transparent'"
                :strokeWidth="20"
                :innerStrokeWidth="20"
                :animateSpeed="1500"
                :isClockwise="false">
                <div class="innerRadial-arrow">
                  <i>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 357 357" style="enable-background:new 0 0 50px 50px;" xml:space="preserve">
                      <g>
                        <g id="play-arrow">
                          <polygon points="38.25,0 38.25,357 318.75,178.5"/>
                        </g>
                      </g>
                    </svg>
                  </i>
                </div>
              </radial-progress-bar>
            </div>
          </div>
        </radial-progress-bar>
        <div class="lines">
          <span class="first"><span>DSL</span></span>
          <span class="last"><span>VDSL</span></span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import RadialProgressBar from 'vue-radial-progress'
export default {
  props: ['item'],
  data () {
    return {
      startDownload: 0,
      startUpload: 0,
      inner: 0,
      active: false,
      download: {
        nr: 1000,
        uptext: 'mit bis zu',
        downtext: 'MBit/s',
        title: ''
      },
      upload: {
        nr: 200,
        uptext: 'mit bis zu',
        downtext: 'MBit/s',
        title: ''
      }
    }
  },
  components: {
    RadialProgressBar
  },
  methods: {
    toggleAnim (speed) {
      let counters = document.querySelectorAll('[data-counters]')
      counters.forEach((item, index) => {
        this.animateCounters(item, 0, item.getAttribute('data-count-target'), speed, null)
      })
    },
    animateCounters (obj, start, end, duration, append = null) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start).toLocaleString().replace(',', '.')
        // if (append) obj.innerHTML += '%'
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    }
  },
  mounted () {
    setTimeout(() => {
      this.active = true
    }, 500)
    setTimeout(() => {
      this.startDownload = this.item.download.nr
      this.startUpload = this.item.upload.nr
      this.inner = 86
      this.toggleAnim(1500)
    }, 3000)
  },
  beforeDestroy () {
    this.startDownload = 0
    this.startUpload = 0
    this.active = false
    this.inner = 0
  }
}
</script>
