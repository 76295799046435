<template>
  <div class="mainSlide" id="mainSlideId" @click.stop.prevent="handleInnerSteps('l')">
    <div class="mainInfoSlide firstFull" :class="{ 'active': customPackage }" :style="{ backgroundImage: 'url(' + $store.state.slides.baseUrl + copyItem.custom_package.image + ')'}" v-if="copyItem.custom_package.visible">
      <span class="title" v-html="copyItem.custom_package.title"></span>
      <span class="blackSticker" v-if="`${copyItem.custom_package.sticker_title.trim()}`" v-html="copyItem.custom_package.sticker_title"></span>
    </div>
    <div class="mainInfoSlide" v-if="copyItem.visible && !copyItem.switch && !customPackage">
      <h1 v-if="copyItem.title" v-html="copyItem.title"></h1>
      <h4 v-if="copyItem.subtitle" v-html="copyItem.subtitle"></h4>
    </div>
    <div class="moreInfoSlide" v-if="copyItem.more.visible && copyItem.switch && !customPackage">
      <span class="misTitle" v-if="copyItem.more && copyItem.more.title" v-html="copyItem.more.title"></span>
      <ul v-if="copyItem.more && copyItem.more.stickers">
        <li v-for="(moreImg, n) in copyItem.more.stickers" :key="'moreI' + n">
          <img :src="$store.state.slides.baseUrl + moreImg" />
        </li>
      </ul>
      <span class="misPrice" v-if="copyItem.more && copyItem.more.price" v-html="copyItem.more.price"></span>
      <div v-if="(copyItem.more.rounded_sticker)" class="rounded-sticker">
        <p>{{copyItem.more.rounded_sticker}}</p>
      </div>
    </div>
    <ul>
      <li v-if="copyItem.custom_package.visible && (copyItem.visible || copyItem.more.visible)" :class="{ 'active': customPackage }" @click.stop="handleSwitch('f')">
        <span></span>
      </li>
      <li v-if="copyItem.visible && (copyItem.custom_package.visible || copyItem.more.visible)" :class="{ 'active': !copyItem.switch && !customPackage }" @click.stop="handleSwitch(false)">
        <span></span>
      </li>
      <li v-if="copyItem.more.visible && (copyItem.custom_package.visible || copyItem.visible)" :class="{ 'active': copyItem.switch }" @click.stop="handleSwitch(true)">
        <span></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      innerAnimating: false,
      copyItem: null,
      customPackage: false
    }
  },
  methods: {
    handleInnerSteps (val) {
      this.innerAnimating = true
      if (val) {
        if (val === 'r') {
          if (this.copyItem.switch) {
            if (!this.copyItem.visible && this.copyItem.custom_package.visible) {
              this.customPackage = true
              this.copyItem.switch = false
            } else if (this.copyItem.visible) {
              this.copyItem.switch = false
            } else if (!this.copyItem.more.visible) {
              this.$emit('changeSlides', 'r')
            }
          } else if (!this.copyItem.switch && this.copyItem.custom_package.visible && !this.customPackage) {
            this.copyItem.switch = false
            this.customPackage = true
          } else {
            this.$emit('changeSlides', 'r')
          }
        } else {
          if (this.copyItem.custom_package.visible && this.customPackage) {
            this.customPackage = false
            if (!this.copyItem.visible && this.copyItem.more.visible) {
              this.copyItem.switch = true
            } else if (this.copyItem.visible) {
              this.customPackage = false
            } else {
              this.$emit('changeSlides', 'l')
            }
          } else if (!this.copyItem.switch && this.copyItem.visible && this.copyItem.more.visible) {
            this.copyItem.switch = true
          } else {
            this.$emit('changeSlides', 'l')
          }
        }
      }
      this.innerAnimating = false
    },
    handleSwitch (val) {
      switch (val) {
        case 'f':
          this.customPackage = true
          this.copyItem.switch = false
          break
        default:
          this.copyItem.switch = val
          this.customPackage = false
          break
      }
    },
    handleWheel () {
      let $this = this
      document.querySelector('#mainSlideId').addEventListener('wheel', (e) => {
        var dir = Math.sign(e.deltaY)
        if (!this.innerAnimating) {
          if (dir === -1) {
            $this.handleInnerSteps('r')
          } else {
            $this.handleInnerSteps('l')
          }
        }
        e.preventDefault()
        e.stopPropagation()
        return false
      })
    }
  },
  created () {
    this.item.switch = !this.item.visible && this.item.more.visible && !this.item.custom_package.visible
    this.copyItem = this.item
    this.customPackage = this.item.custom_package.visible && !this.copyItem.switch
  },
  mounted () {
    this.$nextTick(() => {
      this.handleWheel()
    })
  },
  beforeDestroy () {}
}
</script>
