<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>

    <div class="app-inner" v-if="!slideWithPassword">
      <div class="slides-wrapper slides-wrapper-intro4 clip" @click.stop="changeSlides" :class="{
        [current && current.ovalPosition] : current && current.ovalPosition,
        [current && current.align] : current && current.align,
        fullOval: current && current.type === 3
      }">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" v-if="currentSlide == index" :class="{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index
          }" :style="{ backgroundImage : item.type === 2 || item.type === 5 ? 'url(' + baseUrl + item.img + ')' : 'none' }">
            <span class="whiteBg" v-if="current && current.type === 3"></span>
            <div class="introSlide" v-if="item.type === 1"
                 :class="{ 'animate' : (!showLoader && !animate && currentSlide == index), 'leaving': showLoader }">
              <div>
                <span v-html="item.title"
                      :class="[item.type === 1 && current.advantages ? 'font-weight-light' : '']"></span>
                <img v-if="item.img" :src="baseUrl + item.img" alt=""/>
              </div>
            </div>
            <div
              v-if="item.type === 5"
              class="a-slide-up-cont"
              :class="{
                'main': item.contentPosition === 'center'
              }"
            >
              <h1 v-if="item.title" v-html="item.title"></h1>
              <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
            </div>
            <div
              v-if="item.type === 5"
              class="singleImageContent a-slide-down-cont"
              :class="[{
              'halfSpace': item.contentPosition !== 'center' && item.subTitle
              }]"
            >
              <ul class="listImage" v-if="item.sponsImg && item.sponsImg.length > 0">
                <template v-for="(itm, i) in item.sponsImg">
                  <li :key="'spimg' + i">
                    <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                    <span v-if="itm.title" v-text="itm.title"></span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div v-if="currentSlide == index && item.type === 2"
               :class="{ 'animate' : (!showLoader && !animate && currentSlide == index) }"
               :key="'a-slide-content' + index" class="h1">
            <span class="font-weight-light" v-if="item.title" v-html="item.title"></span>
            <span v-if="item.subtitle" v-html="'<br/>' + item.subtitle"></span>
          </div>
          <div v-if="currentSlide == index && item.type === 3"
               :class="{
            'animate' : (!showLoader && !animate && currentSlide == index),
            'simpleStepWrapper' : item.threeSteps
          }"
               :key="'a-slide-content' + index" class="h1">
            <single-video v-on:isPlaying="checkVideoSingleVideo" v-if="!item.threeSteps"
                          :item="(current.advantages) ? advantages.rotatingSliderItems[advantages.rotatingSliderActive] : item"></single-video>
            <three-steps v-on:changeSlides="changeSlides" v-on:isPlaying="checkVideoSingleVideo" v-if="item.threeSteps && thsData" :threestepsdata="thsData"
                         :animate="(!showLoader && !animate && currentSlide == index)"></three-steps>
          </div>
        </template>
        <div id="i4-oval" class="fullOvalPosition" v-if="current && current.type === 3"
             :class="{'animate' : (!showLoader && !animate) }">
          <div class="inner">
            <img class="oval-1" :src="require('@/assets/images/intro4/oval.svg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <template v-if="current && current.advantages">
      <div id="advantages" @click.self="handleAdvantagesVideo(1)"
           :class="{ 'animate-first-view' : advantages.animateIntro, 'slider-active' : current.type === 3, 'video-is-playing' : current.type === 3 && isVideoPlaying }">
        <div id="rotating-slider"
             :class="{  'is-sliding' : current.type === 3, 'active-h-shadow' : advantages.activeHaveShadow, ['active-' + advantages.rotatingSliderActive] : current.type === 3 && advantages.rotatingSliderActive }">
          <div class="item" @click.prevent="handleAdvantagesVideo(index)"
               :class="{
            'active' : advantages.rotatingSliderActive == index,
            ['item-' + index] : true
          }" v-for="(item, index) in advantages.rotatingSliderItems" :key="index">
            <img :src="baseUrl + item.type_image" class="mb-5" alt=""/>
            <div class="item-title" v-text="item.type"></div>
          </div>
        </div>
      </div>
    </template>
    <div id="intro" class="intro4-video" v-if="showIntro && intro" :class="{ 'hidding' : introOut }">
      <div class="h1 d-none" :class="{ 'd-block' : !isVideoPlaying }" v-if="!isVideoPlaying">
        <span v-html="intro.title"></span>
      </div>
      <video
        :poster="baseUrl + intro.video_cover"
        muted
        ref="$video"
        autoplay id="intro-video"
        v-if="intro.video"
        @ended.stop="changeSlides()"
        @loadedmetadata="$route.params.id !== undefined && handleIntroVideo"
        @click="handleIntroVideo(true)"
        playsinline
        :src="baseUrl + intro.video"></video>
      <button class="btn volumeControl" v-if="$refs.$video" @click="handleVolume()">
        <span v-if="!muted">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
            <path d="M5 17h-5v-10h5v10zm2-10v10l9 5v-20l-9 5zm17 4h-5v2h5v-2zm-1.584-6.232l-4.332 2.5 1 1.732 4.332-2.5-1-1.732zm1 12.732l-4.332-2.5-1 1.732 4.332 2.5 1-1.732z" fill="#fff" />
          </svg>
          <!-- <img src="@/assets/images/icon-unmute.svg" v-if="!muted" alt="" />
          <img src="@/assets/images/icon-mute.svg" v-else alt="" /> -->
        </span>
        <span v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
            <path fill="#fff" d="M22 1.269l-18.455 22.731-1.545-1.269 3.841-4.731h-1.827v-10h4.986v6.091l2.014-2.463v-3.628l5.365-2.981 4.076-5.019 1.545 1.269zm-10.986 15.926v.805l8.986 5v-16.873l-8.986 11.068z"/>
          </svg>
        </span>
      </button>
      <button v-if="!isVideoPlaying" class="d-none" :class="{ 'd-block' : !isVideoPlaying }" id="play-intro"><img :src="require('@/assets/images/play.svg')" alt=""></button>
      <button @click="hideIntro()" class="btn text-white">Skip Intro <img src="@/assets/images/intro-arrows.svg" alt="">
      </button>
    </div>

    <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="true"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>

    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
    <password-modal
      :slide="slideWithPassword"
      v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
      v-if="slideWithPassword"
    ></password-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import singleVideo from '@/components/singleVideo'
import backToHomeButton from '@/components/backToHomeButton'
import threeSteps from '@/components/threeSteps'
import videoWrapper from '@/components/videoWrapper'
import PasswordModal from '@/components/passwordModal'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['slides', 'baseUrl', 'intro', 'introInfoPage'])
  },
  components: {
    PasswordModal,
    infoWrapper,
    sideMenu,
    bottomVideos,
    singleVideo,
    backToHomeButton,
    threeSteps,
    videoWrapper
  },
  data () {
    return {
      slideWithPassword: null,
      backToHomeButtonText: 'Title',
      advantages: {
        animateIntro: true,
        sliderActive: false,
        activeHaveShadow: true,
        rotatingSliderItems: {
          1: {
            type: 'Kamera',
            img: 'vlcsnap-2.jpg',
            title: 'Dein Zuhause immer im Blick',
            video: 'advantages/kamera-1920x1080px-desktop.mp4'
          },
          2: {
            type: 'LED-Lampe',
            img: 'vlcsnap-3.jpg',
            title: 'Smart in den Feierabend',
            video: 'advantages/licht-1920x1080px-desktop.mp4'
          },
          3: {
            type: 'Zwischenstecker',
            img: 'vlcsnap-4.jpg',
            title: 'Macht jedes Gerät smart',
            video: 'advantages/steckdose-1920x1080px-desktop.mp4'
          },
          4: {
            type: 'Thermostat',
            img: 'vlcsnap-5.jpg',
            title: 'Immer smart heizen',
            video: 'advantages/heizung-1920x1080px-desktop.mp4'
          }
        },
        rotatingSliderActive: 1
      },
      thsData: null,
      currentSlide: 0,
      animate: true,
      showHome: true,
      showLoader: false,
      current: null,
      showIntro: true,
      introOut: false,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: true,
      isPlayingSingleVideo: false,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      showInfoBox: false,
      infoBoxData: null,
      muted: true
    }
  },
  watch: {
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    },
    current (nv, ov) {
      if (nv && nv.has_password) {
        this.current = null
        this.slideWithPassword = nv
        this.showHome = true
        if (nv.info_page) { this.handleInfoBox(nv.info_page) }
      }
    }
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    checkVideoSingleVideo (videoState) {
      this.isVideoPlaying = videoState
    },
    handleAdvantagesVideo (index) {
      if (this.current.type !== 3) {
        this.changeSlides(this.currentSlide + 1)
        this.advantages.rotatingSliderActive = 1
        setTimeout(() => {
          this.advantages.activeHaveShadow = true
        }, 2000)
        return
      }
      this.advantages.rotatingSliderActive = index
    },
    handleIntroVideo (muted = true) {
      this.showIntro = true
      this.isVideoPlaying = false
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          if (!muted) { video.muted = false }
          this.isVideoPlaying = true
        }
      } else {
        this.changeSlides()
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.introOut = true
      setTimeout(() => {
        this.changeSlides()
        this.showIntro = false
      }, 300)
    },
    changeSlides (id) {
      // debugger
      if (this.isAnimating) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      this.showIntro = false
      this.isVideoPlaying = false
      this.isAnimating = true
      this.showLoader = true

      setTimeout(() => {
        this.introOut = false
        this.showLoader = false
        this.animate = true

        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else {
            this.currentSlide--
            if (this.currentSlide > 0 && this.slides[this.currentSlide].advantages && this.slides[this.currentSlide].type === 3) this.currentSlide--
          }
        } else this.currentSlide = id

        if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
        this.current = this.slides[this.currentSlide]

        if (this.slides[this.currentSlide] === undefined) {
          this.currentSlide = 0
          this.handleIntroVideo(true)
          this.animate = false
          this.isAnimating = false
          return
        }

        if (this.current && this.current.advantages) { this.advantages.rotatingSliderActive = 1; this.isVideoPlaying = false }

        if (this.$route.params?.id) { this.addLoading(false) }
        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
        }, 800)
      }, this.introOut || (this.current && this.current.advantages) ? 0 : 500)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleVolume () {
      this.$refs.$video.muted = !this.$refs.$video.muted
      this.muted = this.$refs.$video.muted
    }
  },
  mounted () {
    let $this = this
    this.searchSlides(4).then(resp => {
      if (resp.data.adventage_rotatingSliderItems) {
        $this.advantages.rotatingSliderItems = resp.data.adventage_rotatingSliderItems
      }
      if (resp.data.threesteps_data) {
        $this.thsData = resp.data.threesteps_data.threesteps
      }
      $this.handleInfoBox($this.introInfoPage)

      this.$nextTick(() => {
        Object.keys($this.slides).forEach((key) => {
          let b = new Image()
          b.src = $this.slides[key].img
          $this.imageCache.push(b)
        })
        var routeID = null
        if ($this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showIntro = false
          this.changeSlides(routeID)
        } else {
          this.showIntro = false
          this.handleIntroVideo(true)
        }

        document.querySelector('.slides-wrapper').addEventListener('touchstart', handleTouchStart, false)
        document.querySelector('.slides-wrapper').addEventListener('touchmove', handleTouchMove, false)

        var xDown = null
        var yDown = null

        function getTouches (evt) {
          return evt.touches || evt.originalEvent.touches
        }

        function handleTouchStart (evt) {
          const firstTouch = getTouches(evt)[0]
          xDown = firstTouch.clientX
          yDown = firstTouch.clientY
        }

        function handleTouchMove (evt) {
          if (!xDown || !yDown || $this.isAnimating) {
            return
          }

          var xUp = evt.touches[0].clientX
          var yUp = evt.touches[0].clientY

          var xDiff = xDown - xUp
          var yDiff = yDown - yUp

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
              $this.changeSlides('l')
            } else {
              $this.changeSlides('r')
            }
          } else {
            if (yDiff > 0) {
              $this.changeSlides('u')
            } else {
              $this.changeSlides('d')
            }
          }
          /* reset values */
          xDown = null
          yDown = null
        }

        document.querySelector('.slides-wrapper').addEventListener('wheel', (e) => {
          var dir = Math.sign(e.deltaY)

          if (!$this.isAnimating) {
            if (dir === -1) {
              $this.changeSlides('r')
            } else {
              $this.changeSlides('l')
            }
          }
          e.preventDefault()
          e.stopPropagation()
          return false
        })
      })
    })
    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
