<template>
  <div class="topics bg-primary default-shadow default-radius text-center">
    <h5 class="secondary-title">Welche Themen sind Ihnen wichtig?</h5>
    <ul class="d-flex flex-column">
      <li v-for="(item, i) in topics" :key="item + '-key-' + i">
        <button class="btn topic-title" :class="{ 'active': item.active }" @click="handleTopic(i)" v-text="item.text"></button>
        <div class="tooltip-placeholder">
          <button class="btn icon" @click.stop="toggleTooltip('topics-' + i)" @blur="toggleTooltip('')">
            <svg id="i_38_1" data-name="i 38 – 1" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
                <path id="Path_945" data-name="Path 945" d="M331.491,618.758a21.85,21.85,0,1,1-21.85,21.85,21.874,21.874,0,0,1,21.85-21.85m0-1.15a23,23,0,1,0,23,23,23,23,0,0,0-23-23" transform="translate(-308.491 -617.608)" fill="#fff"/>
                <path id="Path_946" data-name="Path 946" d="M330.358,627.251H328.11v3h2.248Zm2.577,19.484h-2.623V633.246h-4.871v2.248h2.623v11.241h-2.623v2.248h7.494Z" transform="translate(-306.363 -616.117)" fill="#fff"/>
            </svg>
          </button>
        </div>
        <div v-if="tooltip === 'topics-' + i" class="tooltip-holder" :class="[item.tooltip_position]" v-html="item.tooltip"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tooltips: []
    }
  },
  props: {
    topics: {
      type: Array,
      required: true
    },
    tooltip: {
      type: String,
      default: ''
    },
    results: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleTopic (key) {
      this.$emit('handleTopics', key)
    },
    toggleTooltip (item) {
      this.$emit('handleTooltip', item)
    }
  }
}
</script>
